import { useState } from 'react';

import { useForm, Controller } from "react-hook-form";
import validator from 'validator';
import { useAuth } from '../../../auth/AuthProvider';
import { Button, Form } from "react-bootstrap";
import TextInput from '../../../components/TextInput/TextInput';
import { submitPasswordUpdate } from '../../../api/actions';
import { useStateContext } from '../../../contexts/ContextProvider.js';

const UpdateUserPasswordForm = (props) => {

    const auth = useAuth();

    const { addNotification } = useStateContext();

    const {register, handleSubmit, getValues, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });    

    const [errorText, setErrorText] = useState(false);

    const formOptions = {
        password: {
            required: "Password is required",
            minLength: {
                value: 6,
                message: "Password is too short."
            }
        },
        password2: {
            required: "Repeating the password is required",
            minLength: {
                value: 6,
                message: "Password is too short."
            }
        },
    }
    const handleError = (errors) => {
        return true;
    };

    const handleSubmission = async () => {
        
        const formData = getValues();
        if(formData.password && formData.password2 && formData.password === formData.password2) {
            const tryUpdatingPassword = await submitPasswordUpdate({password: formData.password});
            if(tryUpdatingPassword.result && tryUpdatingPassword.result === "ok") {
                props.handleClose("changePassword");
                addNotification("Password was updated")
            } else {
                console.log("there was an error updating password")
            }
        } else {
            setErrorText("Passwords do not match");
        }
    };

    return (
        <>
            <div className="mb-3 mt-3 mt-lg-1" style={{maxWidth: "550px", margin: "0 auto"}}>
                <div className="mb-3">
                    <Form onSubmit={handleSubmit(handleSubmission, handleError)}>
                        <Controller
                            name="password"
                            control={control}
                            rules={formOptions.password}
                            render={({field}) => (
                                <TextInput 
                                    id="password" 
                                    type="password" 
                                    floatingLabel="New password" 
                                    placeholder="new password" 
                                    // defaultValue=""
                                    errorMessage={errors?.password?.message}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="password2"
                            control={control}
                            rules={formOptions.password}
                            render={({field}) => (
                                <TextInput 
                                    id="password2" 
                                    type="password" 
                                    floatingLabel="Repeat password" 
                                    placeholder="Type it again" 
                                    // defaultValue=""
                                    errorMessage={errors?.password2?.message}
                                    {...field}
                                />
                            )}
                        />

                        {errorText 
                        ? <p className="text-danger">{errorText.toString()}</p>
                        : ""}
                        <div className="d-grid">
                            <Button variant="primary" type="submit">Update password</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>

    );
}

export default UpdateUserPasswordForm;