import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { Box, TextInput, SelectList, ExpandableBox, Icon, Loader } from '../../../../../components/components';
import { Container, Row, Col, Stack, Badge, Button } from 'react-bootstrap';
import { getAdFormat, getActiveAdFormats, getTargetingOptionsByFormat, createNewCampaign } from '../../../../../api/actions';
import { extractScheduleDataFromForm, extractRatelimitDataFromForm, extractTargetingDataFromForm } from '../../../../../utils/formHelpers';
import { scheduleOptions, rateLimitOptions } from '../../../../../utils/options';
import { useAuth } from '../../../../../auth/AuthProvider';
import validator from 'validator';

const CampaignCreatePage = (props) => {

    const navigate = useNavigate();
    const params = useParams();

    const {profile} = useAuth();

    const {register, handleSubmit, trigger, getValues, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });    

    const [targetingOptions, setTargetingOptions] = useState(false);
    const [adFormat, setAdFormat] = useState(false);
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("Please fill out the form correctly.");

    const formOptions = {
        label: {},
        url: {
            required: "URL is required",
            validate: (v)=>(validator.isURL(v, {require_protocol: true, protocols: ['https']}) || "A valid URL must start with https:// and lead to a valid destination"),
        },
        targetingCategory: {
            required: "Required"
        },
        targetingCountry: {},
        targetingDevice: {},
        targetingVertical: {},
        scheduleDays: {},
        scheduleHourStart: {},
        scheduleHourEnd: {},
        rateLimitPeriod: {},
        rateLimitAmount: {
            max: {
                value: 100000,
                message: "Amount is too high"
            },
            validate: (v) => ((v === undefined || v === "") || validator.isInt(v) || "Must be a whole number")
        },
        bid: {
            required: "Required",
            min: {
                value: 1.5,
                message: "Minimum is $1.5"
            },
            max: {
                value: 10,
                message: "Maximum bid is $10"
            },            
        },
        dailyBudget: {
            min: {
                value: 1,
                message:"1 is the minimum"
            },
            max: {
                value: 1000,
                message:"1000 is the maximum"
            },
        },
        statusAfterApproval: {},        
    };

    const getDataForPage = async () => {
        setIsLoading(true);
        try {
            const adFormatResult = await getAdFormat(params.format);
            setAdFormat(adFormatResult);
            if(!adFormatResult.id) {
                navigate(`/campaigns/create`, {replace: true});
                return;
            }
        } catch (error) {
            console.log(error);
        }

        try {
            const targetingOptionsByFormat = await getTargetingOptionsByFormat(params.format);
            setTargetingOptions(targetingOptionsByFormat);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getDataForPage();
	},[]);

    const handleSubmission = async () => {

        const formData = getValues();
        let allowSubmit = true;
        let campaignData = {};

        const submitFields = ['label','bid','bidType', 'dailyBudget'];
        submitFields.forEach(field=>{
            if(formData[field]) campaignData[field] = typeof formData[field] === "string" ? formData[field].trim() : formData[field];
        });

        if(formData['statusAfterApproval']) campaignData['statusAfterApproval'] = formData['statusAfterApproval'].value;

        // campaignData.urls = Array.isArray(formData.url) ? formData.url : [formData.url];

        campaignData.format = adFormat.name;

        campaignData.bidType = typeof(adFormat.bidTypes) === "string" ? adFormat.bidTypes : adFormat.bidTypes[0];
        
        const targetingData = extractTargetingDataFromForm(formData);
        if(targetingData && Object.keys(targetingData).length > 0) campaignData.targeting = targetingData;

        const rateLimitData = extractRatelimitDataFromForm(formData);
        if(rateLimitData && Object.keys(rateLimitData).length > 0) {
            campaignData.rateLimitPeriod = rateLimitData.period;
            campaignData.rateLimitAmount = rateLimitData.amount;
        }

        const scheduleData = extractScheduleDataFromForm(formData);
        if(scheduleData && Object.keys(scheduleData).length > 0) campaignData.schedule = scheduleData;
        
        campaignData.advertiserId = profile?.accounts?.advertiser[0].id;

        if(allowSubmit) {
            if(isSubmitting) return;
            setIsSubmitting(true); // Prevent double submission of campaign
            const newCampaign = await createNewCampaign(campaignData);
            if(newCampaign?.id) {
               navigate(`/campaigns/view/${newCampaign.id}`, {replace: true});
            }
        }
    }

    const handleError = (errors) => {console.log("Errors in form", errors)};

    return (
        <>
        {isLoading 
            ? <div style={{paddingTop: "100px"}}><Loader position="center" label="Loading page..." /></div>
            :
        <form onSubmit={handleSubmit(handleSubmission, handleError)}>
            <Container fluid className="p-0">
                <Row className="py-2 pt-md-1">
                    <Col lg={7}>
                        <div className="text-start mb-3">
                            <div>
                                <h4>
                                    <span className="fw-bold" style={{color: "#387bd1"}}>Setup your new campaign</span><br/>
                                </h4>
                            </div> 
                            <Container fluid className="m-0 p-0">
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <Controller
                                            name="label"
                                            control={control}
                                            rules={formOptions.label}
                                            render={({field}) => (                       
                                                <TextInput 
                                                    id="label"
                                                    floatingLabel={<><Icon iconName="TagFill" size="28px" color="#c4c4c4" /> Type campaign name</>}
                                                    style={{maxWidth: "450px"}}
                                                    // label="Campaign name"
                                                    errorMessage={errors?.label?.message}
                                                    {...field}
                                                />
                                                )}
                                            />
                                    </Col>    
                                    <Col xs={12} lg={6} style={{marginTop: "6px"}}>
                                        <Controller
                                            name="targetingCategory"
                                            control={control}
                                            rules={formOptions.targetingCategory}
                                            render={({field}) => (
                                                <SelectList 
                                                    id="targetingCategory"
                                                    // label="Audience"
                                                    options={targetingOptions?.category ? targetingOptions.category : []} 
                                                    // description="The type of audience you wish to reach"
                                                    placeholder={<><Icon iconName="PeopleFill" size="28px" color="#c4c4c4" /> Select audience</>}
                                                    errorMessage={errors?.targetingCategory?.message}
                                                    
                                                    {...field}
                                                />
                                            )}
                                        />                                     
                                    </Col>
                                </Row>                    
                            </Container>
                           
                            <Container fluid className="m-0 p-0">
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <Controller
                                            name="bid"
                                            control={control}
                                            rules={formOptions.bid}
                                            render={({field}) => (
                                                <TextInput 
                                                    id="bid"
                                                    type="number"
                                                    floatingLabel="Set Max. bid"
                                                    description="The maximum amount you will pay per 1,000 impressions. Min bid is $1.5/CPM"
                                                    errorMessage={errors?.bid?.message}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Col>      
                                    <Col xs={12} lg={6}>
                                        <Controller
                                            name="dailyBudget"
                                            control={control}
                                            rules={formOptions.dailyBudget}
                                            render={({field}) => (
                                                <TextInput 
                                                    id="dailyBudget"
                                                    type="number"
                                                    // label="Limit your daily spend"
                                                    floatingLabel="Set daily budget"
                                                    description="Leave empty for unlimited spend"
                                                    errorMessage={errors?.dailyBudget?.message}
                                                    {...field}
                                                />
                                                )}
                                        />
                                    </Col>
                                </Row>                              
                            </Container>

                            <ExpandableBox 
                                header={
                                    <>
                                        <Icon iconName="Bullseye" size="28px" color="#c4c4c4" /> <b className="mx-2">Set targeting</b> 
                                        <div>
                                            {(watch("targetingCountry")?.length > 0 || watch("targetingCountry")?.length > 0 || watch("targetingCountry")?.length > 0) ? <small className="me-2">Selected</small> : ""}
                                            {watch("targetingVertical")?.value ? <Badge className="me-1">{watch("targetingVertical")?.label}</Badge> : ""}
                                            {watch("targetingCountry")?.length ? <Badge className="me-1">{watch("targetingCountry")?.length} Countries</Badge> : ""} 
                                            {watch("targetingDevice")?.length ? <Badge className="me-1">{watch("targetingDevice")?.length} Devices</Badge> : ""}                                        
                                        </div>
                                    </>
                                    }
                                className="mb-2"
                                >
                                <p>Leave empty for no filtering.</p>

                                <Controller
                                    name="targetingVertical"
                                    control={control}
                                    rules={formOptions.targetingVertical}
                                    render={({field}) => (                                
                                        <SelectList 
                                            id="targetingVertical"
                                            label="Verticals"
                                            options={targetingOptions?.vertical && watch("targetingCategory") ? targetingOptions.vertical.filter(v=>(!v.parentCategory || v.parentCategory === watch("targetingCategory").value)) : []} 
                                            description="Type of content your audience is interested in"
                                            placeholder="All verticals"
                                            clearable
                                            searchable
                                            // multi
                                            errorMessage={errors?.targetingVertical?.message}
                                            {...field}
                                        />
                                    )}
                                />

                                <Controller
                                    name="targetingCountry"
                                    control={control}
                                    rules={formOptions.targetingCountry}
                                    render={({field}) => (
                                        <SelectList 
                                            id="targetingCountry"
                                            label="Countries"
                                            options={targetingOptions?.country ? targetingOptions.country : []} 
                                            description="Countries that you wish to receive traffic from."
                                            placeholder="All countries"
                                            clearable
                                            searchable
                                            multi
                                            errorMessage={errors?.targetingCountry?.message}
                                            {...field}
                                        />
                                    )}
                                />

                                <Controller
                                    name="targetingDevice"
                                    control={control}
                                    rules={formOptions.targetingDevice}
                                    render={({field}) => (                                
                                        <SelectList 
                                            id="targetingDevice"
                                            label="Devices"
                                            options={targetingOptions?.device ? targetingOptions.device : []}
                                            placeholder="All devices"
                                            description="Type of device used by your audience"
                                            clearable
                                            searchable
                                            multi
                                            errorMessage={errors?.targetingDevice?.message}
                                            {...field}
                                        />
                                    )}
                                />               
                            </ExpandableBox>
                            <ExpandableBox header={
                                    <>
                                        <Icon iconName="CalendarRange" size="28px" color="#c4c4c4" /> <b className="mx-2">Schedule traffic flow</b> 
                                        <div>
                                            {/* {(formData?.scheduleDays?.length > 0 || formData?.scheduleHourStart?.length > 0 || formData?.scheduleHourEnd?.length > 0) ? <Badge className="me-1">Selected</Badge> : ""} */}
                                        </div>
                                    </>
                                    }
                                    className="mb-2"
                                > 
                                <p>Traffic will only be sent in selected timeframes.<br/> Leave empty to receive traffic 24/7.</p>
                                <Container fluid className="m-0 p-0">
                                    <Row>
                                        <Col md={6}>
                                            <Controller
                                                name="scheduleDays"
                                                control={control}
                                                rules={formOptions.scheduleDays}
                                                render={({field}) => (
                                                    <SelectList 
                                                        id="scheduleDays"
                                                        label="Active days"
                                                        options={scheduleOptions.days} 
                                                        clearable
                                                        searchable
                                                        placeholder="All days"
                                                        multi
                                                        errorMessage={errors?.scheduleDays?.message}
                                                        {...field}
                                                    /> 
                                                )}
                                            />                        
                                        </Col>
                                        <Col>
                                            <Controller
                                                name="scheduleHourStart"
                                                control={control}
                                                rules={formOptions.scheduleHourStart}
                                                render={({field}) => (
                                                    <SelectList 
                                                        id="scheduleHourStart"
                                                        label={<>Active Hours</>}
                                                        options={scheduleOptions.hours.map((option=>({...option, label: `From ${option.label}`})))} 
                                                        clearable
                                                        searchable
                                                        placeholder="From hour"
                                                        errorMessage={errors?.scheduleHourStart?.message}
                                                        {...field}
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name="scheduleHourEnd"
                                                control={control}
                                                rules={formOptions.scheduleHourEnd}
                                                render={({field}) => (                                            
                                                    <SelectList 
                                                        id="scheduleHourEnd"
                                                        options={scheduleOptions.hours.map((option=>({...option, label: `To ${option.label}`})))} 
                                                        clearable
                                                        searchable
                                                        placeholder="To hour"
                                                        errorMessage={errors?.scheduleHourEnd?.message}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </ExpandableBox>
                            <ExpandableBox header={
                                    <>
                                        <Icon iconName="Speedometer" size="28px" color="#c4c4c4" /> <b className="mx-2">Rate limiter</b> 
                                    </>
                                    }
                                > 
                                <p>Use this option to limit the amount of traffic provided during a selected period.</p>
                                <Container fluid className="m-0 p-0">
                                    <Row>
                                        <Col md={6}>
                                            <Controller
                                                name="rateLimitPeriod"
                                                control={control}
                                                rules={formOptions.rateLimitPeriod}
                                                render={({field}) => (                                            
                                                    <SelectList 
                                                        id="rateLimitPeriod"
                                                        label={<><small style={{fontWeight: 400}}>Choose time period </small></>}
                                                        options={rateLimitOptions.period} 
                                                        clearable
                                                        searchable
                                                        errorMessage={errors?.rateLimitPeriod?.message}
                                                        {...field}
                                                    />
                                                )}
                                            />                                
                                        </Col>
                                        <Col>
                                            <Controller
                                                name="rateLimitAmount"
                                                control={control}
                                                rules={formOptions.rateLimitAmount}
                                                defaultValue=""
                                                render={({field}) => (
                                                    <TextInput 
                                                        id="rateLimitAmount"
                                                        floatingLabel={<><Icon iconName="Numbers" size="28px" color="#c4c4c4" /> Type here</>}
                                                        label={<><small style={{fontWeight: 400}}>Type amount </small></>}
                                                        style={{maxWidth: "450px"}}
                                                        description="Limit amount of traffic within the selected period"
                                                        // label="Campaign name"
                                                        errorMessage={errors?.rateLimitAmount?.message}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </ExpandableBox>                        
                        </div>
                        <Box className="mobile-no-x-margin mb-3">
                            <h4 className="text-start blue">All set?</h4>
                            <Controller
                                name="statusAfterApproval"
                                control={control}
                                rules={formOptions.statusAfterApproval}
                                render={({field}) => (
                                    <SelectList 
                                        id="statusAfterApproval"
                                        label={<span className="fw-normal">Select campaign status after moderation approval</span>}
                                        options={[
                                            {value:"enabled", label: "Run campaign"},
                                            {value:"disabled", label: "Pause campaign"}
                                        ]}
                                        style={{maxWidth: "300px", width: "300px"}}
                                        errorMessage={errors?.statusAfterApproval?.message}
                                        {...field}
                                    />
                                )}
                            />

                            {errorMessage
                            ? <p><b className="small text-danger">{errorMessage}</b></p>
                            : ""}

                            {Object.keys(errors).length === 0
                            ?<Button type="submit">Start new campaign</Button>
                            :<Button type="button" variant="secondary" onClick={()=>trigger()}>Start new campaign</Button>}
                        </Box> 
                    </Col>
                    <Col className="mobile-no-x-padding">
                   
                    </Col>
                </Row>
            </Container>
        </form>
        }
        </>
    );
}

export default CampaignCreatePage;