import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useAuth } from '../../../auth/AuthProvider';
import * as Utils from '../../../utils/common';

import { Container, Row, Col, Button, Card, Stack, Table } from 'react-bootstrap';
import { Icon, Box, DataTable } from '../../../components/components';
import { QuickActions } from '../../../containers/containers';
import { getUserReferralsSummary, getUserReferralsBalanceHistory } from '../../../api/actions';

const referralProgramRewards = [
    {level: 1, fromRevenue: "5%", perNewBuyer: "$10"},
    {level: 2, fromRevenue: "3%", perNewBuyer: "$2"},
    {level: 3, fromRevenue: "1%", perNewBuyer: "$1"},
];

const ReferralsPage = () => {
    const [referralsSummary, setReferralsSummary] = useState();
    const [totalRewards, setTotalRewards] = useState();
    const [totalRewardsSum, setTotalRewardsSum] = useState(0);
    const [balanceHistory, setBalanceHistory] = useState();

    const auth = useAuth();
    const { addNotification } = useStateContext();

    const handleCopyReferralLink = (e) => {
        navigator.clipboard.writeText('https://traffics.io/?ref='+auth.profile.referralId); 
        addNotification("Referral link copied to clipboard!")
    }

    useEffect(()=>{
        getUserReferralsSummary()
            .then(result=>{
                setReferralsSummary(result);

                let totals = {};
                for(let level of Object.keys(result)) {
                    for(let key of Object.keys(result[level])) {
                        if(!totals[key]) totals[key] = 0;
                        totals[key] += result[level][key];
                    }
                }
                totals = Object.keys(totals).reduce((acc,cur)=>({...acc, [cur]: Utils.formatNumber(totals[cur],2)}),{})
                setTotalRewards(totals);
                const calcTotal = (Number(totals.buyersReward) + Number(totals.revenueReward)) || 0;
                setTotalRewardsSum(calcTotal);
                getUserReferralsBalanceHistory()
                    .then(result=>{
                        setBalanceHistory(result)
                    })
                    .catch(error=>{
                        console.log(error)
                    })
            })
            .catch(error=>{console.log(error)})
    },[]);

    return (
        <>
            <Container fluid className="p-0">
                <Row>
                    <Col md={12}>
                        <div className="my-0 my-md-2 mt-md-1" style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                            Your referral link: <span className="ms-1" style={{cursor: "pointer"}} onClick={() => handleCopyReferralLink()}><span className="text-primary fw-bold">https://traffics.io/?ref={auth.profile.referralId}</span> <Icon iconName="Copy"/></span>
                        </div>
                    </Col>
                    <Col md={12} className="mb-3 text-start">
                        Total rewards from program: <b className="green">${totalRewardsSum || 0}</b>
                    </Col>
                </Row>
                <Row className="gx-lg-3">
                    <Col lg={8}>
                        <Container fluid className="p-0">
                            <Row className="mb-3 g-3">
                                {[1,2,3].map((levelNum)=>(
                               <Col md={4}>
                                    <Box style={{borderColor: "#ff983c"}}>
                                        <Stack className="text-center">
                                            <h4 className="text-center fw-bold first-color">Level {levelNum}<br />
                                                <span style={{fontWeight: "400", fontSize: "0.9rem"}}>
                                                {levelNum===1 && <p>Your direct referrals</p>}
                                                {levelNum===2 && <p>Your referrals' referrals</p>}
                                                {levelNum===3 && <p>Their referrals</p>}    
                                                </span>
                                            </h4>
                                            <b className="black">Proposed reward:</b>
                                            <p className="text-center green"><small>{referralProgramRewards[levelNum-1].perNewBuyer} for every new buyer <br />+ {referralProgramRewards[levelNum-1].fromRevenue} of their spend</small></p>
                                            <div><span className="me-auto">Referrals</span> <b>{referralsSummary?.[levelNum]?.signups || 0}</b></div>
                                            <div>Buyers <b>{referralsSummary?.[levelNum]?.buyers || 0}</b></div>
                                            <div>Buyers reward <b>${Utils.formatNumber(referralsSummary?.[levelNum]?.buyersReward,2) || 0}</b></div>
                                            <div>Revenue reward <b>${Utils.formatNumber(referralsSummary?.[levelNum]?.revenueReward,2) || 0}</b></div>
                                            <h4 className="mt-3 text-center green">${Utils.formatNumber(referralsSummary?.[levelNum]?.revenueReward + referralsSummary?.[levelNum]?.buyersReward,2)}</h4>
                                        </Stack>
                                    </Box>
                                </Col>
                                ))}
                                
                            </Row>
                            {balanceHistory && balanceHistory.length > 0 && 
                            <Row className="mb-3 text-start">
                                <Col>
                                    <h5>Balance history</h5>
                                    <p>History of referral program related movements in your account balance.</p>
                                    <DataTable data={balanceHistory} showSearch={false} fixed={true} />
                                </Col>
                            </Row>                            
                            }

                            <Row className="mb-3">
                                <Col>
                                <Box className="text-start" style={{fontSize: "0.9rem"}}>
                                <h5 className="text-center">Program terms</h5>
                                <ul style={{paddingInlineStart: "8px"}}>
                                    <li>Referral purchases awards are provided for a life time.</li>
                                    <li>Referral cookie expiry time is 10 years.</li>
                                    <li>Spam traffic will cause the account to get blocked.</li>
                                    <li>Use this referral program only if you understand and agree with the Traffics.io terms of use and referral program policy.</li>
                                    <li>It is recommended to try our service before engaging with this program.</li>
                                </ul>
                                </Box>
                                </Col>
                            </Row>
                        </Container>

                    </Col>
                    <Col lg={4}>
                        <Box className="mb-3 text-start"  style={{fontSize: "0.9rem"}}>
                            <h5 className="text-center">How it works?</h5>
                            <p>Invite friends to join our service and get rewarded for their purchases for a life time.</p>
                            <p>Our referral program supports up to 3 levels of referrals, allowing you to exponentially increase your results from referrals.</p>
                            <p className="mb-0">All rewards are added to your account and can only be used for funding campaigns in our platform.</p>
                            <h5 className="text-center mt-3">Multi-level awards</h5>
                            <p>The following rewards are offered for each level of referrals:</p>
                            <table className="table">
                                <tr><th>Referral level</th><th>From revenue</th><th>Per new buyer</th></tr>
                                {referralProgramRewards.map(level=>(<tr><td>Level {level.level}</td><td>{level.fromRevenue}</td><td>{level.perNewBuyer}</td></tr>))}
                            </table>
                            <div className="text-center w-100">
                                <img className="w-100" src="/images/referral-program-levels.png" style={{maxWidth: "300px"}}/>
                            </div>
                                                        
                        </Box>

                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ReferralsPage;


