import './Box.css';

const Box = ({children, className, ...props}) => {

    return (
        <div className={`box ${className ? className : ""}`} {...props}>
            {props?.header &&
                <h4 className="text-start mb-2">
                    <b className="gradient-text">{props.header}</b>
                </h4>            
            }
            {children}
        </div>
    );
}

export default Box;