import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import './DropdownMenu.css';

const DropdownMenu = ({ label, children, className, style, ...props }) => {
    const [show, setShow] = useState(false);

    const handleToggle = (isOpen) => {
        setShow(isOpen);
    };

    return (
        <Dropdown show={show} onToggle={handleToggle} className={className ? className : ""} style={style ? style : {}}>
            <Dropdown.Toggle variant="span" id={props.id} className="dropdown-toggler">
                {label}
            </Dropdown.Toggle>

            {show && ReactDOM.createPortal(
                <Dropdown.Menu className="dropdownmenu-items">
                    {children}
                </Dropdown.Menu>,
                document.body
            )}
        </Dropdown>
    );
};

export default DropdownMenu;
