import './Loader.css';

const Loader = (props) => {
    let style = {};
    let wrapperStyle = {};

    const position = props.position || "default";
    const show = 'show' in props ? props.show : true; 

    if(position === "absolute") wrapperStyle = {position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%);", zIndex: 9999};
    if(position === "center") wrapperStyle = {margin: "0 auto"}
    wrapperStyle.display = show ? "inline-block" : "none";

    return (
        <div className="loader-wrapper" style={wrapperStyle}>
            <div className="loader" style={style} />
            {props?.label ? <div className="loader-label">{props.label}</div> : ""}
        </div>
    );
}

export default Loader;