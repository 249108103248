import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
	const [screenSize, setScreenSize] = useState(undefined);
	const [currentColor, setCurrentColor] = useState('#03C9D7');
	const [currentMode, setCurrentMode] = useState('Light');
	const [themeSettings, setThemeSettings] = useState(false);
	const [activeMenu, setActiveMenu] = useState(true);
	const [isMobile, setIsMobile] = useState();
	const [token, setToken] = useState(sessionStorage.getItem("token"));
	const [isAppLoaded, setIsAppLoaded] = useState(false);
	const [notifications, setNotifications] = useState([]);

	const addNotification = (data) => {
		if(typeof data === 'string') data = {title: "", content: data};
		setNotifications([...notifications, {...data, key: "key"+Math.round(Math.random()*10000)}]);
	}

	const removeNotification = (key) => {
		setNotifications(notifications.filter((notification)=>(notification.key !== key)));
	}

	const setMode = (e) => {
		setCurrentMode(e.target.value);
		localStorage.setItem('themeMode', e.target.value);
	};

	const setColor = (color) => {
		setCurrentColor(color);
		localStorage.setItem('colorMode', color);
	};

	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<StateContext.Provider value={{ 	
			isMobile, 
			setIsMobile, 
			currentColor, 
			currentMode, 
			activeMenu, 
			screenSize, 
			setScreenSize, 
			setActiveMenu, 
			setCurrentColor, 
			setCurrentMode, 
			setMode, 
			setColor, 
			themeSettings, 
			setThemeSettings,
			notifications,
			addNotification,
			removeNotification,
			token,
			setToken,
			isAppLoaded,
			setIsAppLoaded
		}}>

		{children}
		</StateContext.Provider>
	);
};

export const useStateContext = () => useContext(StateContext);
