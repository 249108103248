export const scheduleOptions = {
    days: [
        {value: 1, label: "Sundays"},
        {value: 2, label: "Mondays"},
        {value: 3, label: "Tuesdays"},
        {value: 4, label: "Wednesdays"},
        {value: 5, label: "Thursdays"},
        {value: 6, label: "Fridays"},
        {value: 7, label: "Saturdays"},
    ],
    hours: [...Array(24).keys()].map((i)=>({value: i, label : i > 11 ? `${i}:00` : `${i}:00`}))
}

export const rateLimitOptions = {
    period: [
        {value: "day", label: "Daily"},
        {value: "hour", label: "Hourly"},
    ],
    amount: [100,250,500,1000,2000,5000, "custom"]
        .map(val=>({
            value: val, 
            label: Number.isInteger(val) ? val.toLocaleString('en-US') : val
        }))
}

export const balanceActionOptions = {
    type: [
        {value: "top up", label: "Top up"},
        {value: "top up bonus", label: "Top up bonus"},
        {value: "complimentary", label: "Complimentary"},
        {value: "adjustment", label: "Adjustment"},
        {value: "correction", label: "Correction"},
        {value: "promotion", label: "Promotion"},
        {value: "referrals reward", label: "Referrals reward"},
    ],
}

export const scheduleToText = (options) => {
    let text = "";
    if(options?.days) {
        const days = options.days
            .sort((a,b)=>(a > b ? 1 : -1))
            .map(day=>(
                scheduleOptions.days.find((dayOption)=>(dayOption.value === day))
                ).label.substring(0,3));
        text = days.join(", ")
    } else {
        text = "All days";
    }

    if(options?.hours) {
        const startHour = scheduleOptions.hours.find((hour)=>(options.hours.start === hour.value))?.label;
        const endHour = scheduleOptions.hours.find((hour)=>(options.hours.end === hour.value))?.label;
        text = text + " " + startHour + " to " + endHour;
    } else {
        text = text + " All hours";
    }

    return text;
}

export const rateLimitToText = (obj) => {
    let text = "No limit";

    if(obj?.period && obj?.amount) {
        text = obj.amount.toLocaleString() + " / " + obj.period; 
    }

    return text;
}
