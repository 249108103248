import {useState, useEffect, forwardRef} from 'react';
import {default as BSModal} from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Modal = ({children, ...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
    <>
        <BSModal onHide={handleClose} show={show} centered={true} {...props}>
            <BSModal.Header closeButton={typeof (props.closeButton) == 'undefined' || props.closeButton == null ? true : props?.closeButton}>
                <BSModal.Title><h5 className="mb-0">{props.header}</h5></BSModal.Title>
            </BSModal.Header>
            <BSModal.Body>{children}</BSModal.Body>
            {props.showFooter && 
            <BSModal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {props?.button 
                ? props.button
                :   <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                }

            </BSModal.Footer>
            }
        </BSModal>
    </>
    );
}

export default Modal;