import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '../../../auth/AuthProvider';

import { submitContactUsForm } from '../../../api/actions';

import { Container, Row, Col, Stack, Button, Form } from 'react-bootstrap';
import { Box, SelectList, TextInput, Icon, Modal } from '../../../components/components';

const ContactPage = () => {

    const navigate = useNavigate();
    const params = useParams();

    const {profile} = useAuth();

    const [modals, setModals] = useState({});
    const [ticketId, setTicketId] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState(true);

    const {register, handleSubmit, getValues, watch, formState: { errors }, control} = useForm({
        mode: "onBlur",
        defaultValues: {email: profile.email, name:profile.firstName}
    });  

    const formOptions = {
        subject: {
            required: "Required"
        },
        name: {
            required: "Required"
        },
        email: {
            required: "Required"
        },
        message: {
            required: "Required",
            minLength: {
                value: 5,
                message: "Message is too short.",
            },
            maxLength: {
                value: 1024,
                message: "Your message is too long. Up to 1000 characters allowed.",
            }
        },
    };

    const handleSubmission = async () => {
        setSubmitButtonDisabled(true);
        const formData = getValues();
        let allowSubmit = true;

        let submissionData = {
            name: formData.name,
            email: formData.email,
            subject: formData.subject.value,
            message: formData.message,
        };

        if(allowSubmit) {
            const submitForm = await submitContactUsForm(submissionData);
            if(submitForm?.ticketId) {
                setTicketId(submitForm?.ticketId);
                openModal("messageSent");
            } else {
                openModal("errorSending");
                setSubmitButtonDisabled(false);
            }
        }
    }

    const handleError = (errors) => {setIsValid(false); setErrorMessage(errors)};

    useEffect(()=>{

    },[])

    const handleModal = (id, action) => {
        setModals((prevModals) => ({
            ...prevModals,
            [id]: (action === "open")
        }));
    }

    const openModal = (id) => handleModal(id, "open")
    const closeModal = (id) => handleModal(id, "close");

    return (
        <>
            <Container fluid className="p-0 pt-4">
                <Row className="justify-content-md-center">
                    <Col lg={7}>
                        
                        <form onSubmit={handleSubmit(handleSubmission, handleError)}>
                            <Container fluid className="p-0 text-start">
                                <Row>
                                    <Col className="text-start mb-3">
                                        <h5>Let us know how we can help!</h5>
                                        <span>Contact us via Email - <a href="mailto:info@traffics.io">info@traffics.io</a> or fill out the contact form</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{maxWidth: "400px"}}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            rules={formOptions.name}
                                            render={({field}) => (
                                                <TextInput 
                                                    id="name" 
                                                    type="text" 
                                                    floatingLabel="Name" 
                                                    placeholder="Your name" 
                                                    // value={profile?.email}
                                                    errorMessage={errors?.name?.message}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{maxWidth: "400px"}}>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={formOptions.email}
                                            render={({field}) => (
                                                <TextInput 
                                                    id="email" 
                                                    type="email" 
                                                    floatingLabel="Email address" 
                                                    placeholder="Your email address" 
                                                    // value={profile?.email}
                                                    errorMessage={errors?.email?.message}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{maxWidth: "400px"}}>
                                            <Controller
                                                name="subject"
                                                control={control}
                                                rules={formOptions.subject}
                                                render={({field}) => (
                                                    <SelectList 
                                                        id="subject"
                                                        // label="Audience"
                                                        options={[
                                                            {value: "general", label: "General inquiry"},
                                                            {value: "feedback", label: "Feedback"},
                                                            {value: "complaint", label: "Complaint"},
                                                            {value: "partnership", label: "Cooperation and Partnership"},
                                                        ]} 
                                                        placeholder={<>Select subject</>}
                                                        errorMessage={errors?.subject?.message}
                                                        {...field}
                                                    />
                                                )}
                                            /> 
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-3">
                                        <Controller
                                            name="message"
                                            control={control}
                                            rules={formOptions.message}
                                            render={({field}) => (
                                                <>
                                                <Form.Group className="mb-3">
                                                    <Form.Control 
                                                        as="textarea" 
                                                        rows={8}
                                                        errorMessage={errors?.message?.message}
                                                        style={{maxWidth:"800px", border: "2px #387bd1 solid"}}
                                                        placeholder="Type your message here"
                                                        {...field}
                                                    />
                                                    {errors?.message?.message ? <small className="mt-1">{errors?.message?.message}</small> : ""}
                                                </Form.Group>
                                                </>
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Button type="submit" disabled={submitButtonDisabled}>Send</Button>                                    
                                    </Col>
                                </Row>
                            </Container>
                        </form>
                    </Col>
                </Row>
            </Container>

            <Modal show={modals?.errorSending} onHide={()=>closeModal("errorSending")} backdrop="static" header="Something went wrong">
                <p>There was an error while trying to send your message. Please sending it again in a short while.</p>
                <Button onClick={()=>navigate(`/`, {replace: true})}>Go to dashboard</Button>
            </Modal>

            <Modal show={modals?.messageSent} onHide={()=>closeModal("messageSent")} backdrop="static" header="Thank you for reaching out!">
                <p>Ticket ID: <b>{ticketId}</b>. <br />Please allow up to 24 hours for our team to reply.</p>
                <Button onClick={()=>closeModal("messageSent")}>OK</Button>
            </Modal>
        </>
        
    );
}

export default ContactPage;