
import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom';
import { useAuth } from '../../auth/AuthProvider';
import { Logo, Icon } from '../../components/components';
import PagePreferences from '../../utils/pagePreferences.js';
import { getPageHistory, addPageHistory, clearPageHistory } from '../../utils/pageHistory.js';
import { Container, Row, Col, Nav, Navbar, NavDropdown, Stack, Accordion } from 'react-bootstrap';
// icons
import './Sidebar.css';

const Sidebar = (props) => {

    const navigate = useNavigate();

    const auth = useAuth();
    const {profile} = auth;

    const currentLocation = useLocation();
    const [url, setUrl] = useState();
	const [refreshKey, setRefreshKey] = useState(1);

	const [isCampaignsHistoryExpanded, setIsCampaignsHistoryExpanded] = useState(false);
	const [campaignsHistory, setCampaignsHistory] = useState([]);

	const handleLogout = () => {
		auth.logout().then(()=>{navigate('/login')});
	};

	const handleExpandCampaignsHistory = (e) => {
		e.stopPropagation(); 
		e.preventDefault();
		
		PagePreferences.setGeneralPreferences("sidebar", {expanded: !isCampaignsHistoryExpanded});
		setIsCampaignsHistoryExpanded(!isCampaignsHistoryExpanded);
	}

	const handleClearCampaignsHistory = () => {
		clearPageHistory("campaigns");
		setCampaignsHistory([]);
		setRefreshKey(prev=>prev+1);
	}

    useEffect(()=>{
        setUrl(currentLocation.pathname);
		setCampaignsHistory(getPageHistory("campaigns"));
		
    }, [currentLocation, refreshKey])

	useEffect(()=>{
		setIsCampaignsHistoryExpanded(PagePreferences.getGeneralPreferences("sidebar")?.expanded);
	},[])

    return (
        <>	
			<div id="sidebar" className={props.show ? "show-sidebar" : ""}>
				<Stack direction="vertical" className="h-100">
					<Stack direction="horizontal" className="p-2 pt-1">
						<a href="/"><Logo /></a>
						<Icon iconName="X" size="28" className="ms-auto d-lg-none icon-hover" onClick={()=>props.sidebarToggler()}/>
					</Stack>
					<div>

          			</div>
					<Link className={"sidebar-item sidebar-link " + (url === "/" ? "active-sidebar-item" : "")} to="/"><Icon iconName="Home" size="24" className="sidebar-item-icon" /> Dashboard</Link>
					{/* <h5 className="sidebar-item">Advertising</h5> */}
					{/* <Link className={"sidebar-item sidebar-link " + (url && url.startsWith("/campaigns") ? "active-sidebar-item" : "")} to="/campaigns"><Icon iconName="Barchart" size="24" style={{marginBottom: "3px"}}/> Campaigns <span style={{position: "absolute", right: "10px"}} onClick={handleExpandCampaignsHistory}>{isCampaignsHistoryExpanded ? <Icon iconName="MinusSquare" style={{color: "black"}} size="18" /> : <Icon iconName="PlusSquare" style={{color: "black"}} size="18" />}</span></Link>
			
					{isCampaignsHistoryExpanded &&
						<div style={{background: "#f7f7f7"}}>
							{url !== "/campaigns" && <Link className="sidebar-item sidebar-link sidebar-subitem" to="/campaigns"><Icon iconName="Eye"/> View all</Link>}
							<Link className={"sidebar-item sidebar-link sidebar-subitem " + (url === "/campaigns/create" ? "active-sidebar-subitem" : "")} to="/campaigns/create"><Icon iconName="PlusLg"/> New campaign</Link>

							{campaignsHistory && campaignsHistory.length > 0 && 
							campaignsHistory.sort((a,b)=>(a.label > b.label ? 1 : -1)).map(c=>(
								<Link className={"sidebar-item sidebar-link sidebar-subitem " + (url === c.url ? "active-sidebar-subitem" : "")} to={c.url} title={c.label + " (Campaign #" + c.id + ")"}>{c.label}</Link>)
							)}

							{campaignsHistory && campaignsHistory.length > 0 && <Link className={"sidebar-item sidebar-link sidebar-subitem text-muted"} onClick={()=>handleClearCampaignsHistory()}><Icon iconName="Delete" /> Clear recent</Link>}
						</div>
					} */}

					<Accordion {...(isCampaignsHistoryExpanded ? {activeKey: "0"} : {})}>
						<Accordion.Item eventKey="0" style={{padding: 0, border: 0}}>
							<Accordion.Header onClick={handleExpandCampaignsHistory} className={"sidebar-item sidebar-link " + (url && url.startsWith("/campaigns") ? "active-sidebar-item" : "")}><Link to="/campaigns" style={{color: "inherit"}} onClick={(e)=>e.stopPropagation()}><Icon iconName="Barchart" size="24" style={{marginRight: "4px"}} /> Campaigns</Link></Accordion.Header>
							<Accordion.Body>
							<Link className="sidebar-item sidebar-link sidebar-subitem" to="/campaigns"><Icon iconName="List2"/> Browse</Link>
							<Link className={"sidebar-item sidebar-link sidebar-subitem " + (url === "/campaigns/create" ? "active-sidebar-subitem" : "")} to="/campaigns/create"><Icon iconName="PlusLg"/> New campaign</Link>

							{campaignsHistory && campaignsHistory.length > 0 && 
							campaignsHistory.sort((a,b)=>(a.label > b.label ? 1 : -1)).map(c=>(
								<Link className={"sidebar-item sidebar-link sidebar-subitem " + (url === c.url ? "active-sidebar-subitem" : "")} to={c.url} title={c.label + " (Campaign #" + c.id + ")"}>{c.label}</Link>)
							)}

							{campaignsHistory && campaignsHistory.length > 0 && <Link className={"sidebar-item sidebar-link sidebar-subitem text-muted"} onClick={()=>handleClearCampaignsHistory()}><Icon iconName="Delete" /> Clear recent</Link>}

							</Accordion.Body>
						</Accordion.Item>
					</Accordion>	
					{/* <Link className={"sidebar-item sidebar-link " + (url === "/reports" ? "active-sidebar-item" : "")} to="/reports"><Icon iconName="Balloon" size="24" /> Promotions</Link> */}
					{/* <h5 className="sidebar-item">Publishers</h5>
					<Link className={"sidebar-item sidebar-link " + (url === "/websites" ? "active-sidebar-item" : "")} to="/reports"><Icon iconName="WWW" size="18" /> Websites</Link>
					<Link className={"sidebar-item sidebar-link " + (url === "/reports" ? "active-sidebar-item" : "")} to="/reports"><Icon iconName="Cog" size="18" /> Reports</Link> */}
					{/* <h5 className="sidebar-item">Account</h5> */}

		          {/* <span className={url && url.startsWith("/account") ? "active-sidebar-item" : ""}><Link className="sidebar-item" to="/account"><Icon iconName="UserAccount" size="18" /> Profile</Link></span> */}
					<Link className={"sidebar-item sidebar-link " + (url === "/referrals" ? "active-sidebar-item" : "")} to="/referrals"><Icon iconName="PeopleFill" size="24" /> Referrals</Link>
					<Link className={"sidebar-item sidebar-link " + (url === "/account/payments" ? "active-sidebar-item" : "")} to="/account/payments"><Icon iconName="Wallet" size="24" className="sidebar-item-icon"/> Payments</Link>
					{/* <Link className={"sidebar-item sidebar-link " + (url === "/account/top-up" ? "active-sidebar-item" : "")} to="/account/top-up"><Icon iconName="CreditCard" size="24" className="sidebar-item-icon" /> Top up</Link> */}
					<Link className={"sidebar-item sidebar-link " + (url === "/account" ? "active-sidebar-item" : "")} to="/account"><Icon iconName="UserAccount" size="24" className="sidebar-item-icon"/> Profile</Link>

					<Link className={"sidebar-item sidebar-link " + (url === "/contact" ? "active-sidebar-item" : "")} to="/contact"><Icon iconName="Question" size="24" className="sidebar-item-icon" /> Contact us</Link>

					<div className="mt-auto" style={{padding: "10px", textAlign: "center"}}>
						<span style={{fontSize: "12px", color: "#c2c2c2", fontWeight: "300"}}>All rights reserved Traffics.io</span>
					</div>
				</Stack>
			</div>
        </>		
    );
} 
 
export default Sidebar;