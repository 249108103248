import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Icon } from '../../../../../components/components';
import { Container, Row, Col, Stack, Badge, Button } from 'react-bootstrap';
import { getActiveAdFormats } from '../../../../../api/actions';

const CampaignCreateSelectFormatPage = (props) => {

    const navigate = useNavigate();
    
    const [formats, setFormats] = useState([]);

    useEffect(() => {
        getActiveAdFormats()
            .then(result => {
                setFormats(result);
                if(result.length === 1 && result[0].name) {
                    // navigate(`/campaigns/create/${result[0].name}`,{replace:true});
                }
            })
            .catch(error => console.log(error));
        
        document.title = 'New campaign';
	},[]);
    
    return (
        <>
        <Container fluid className="p-0"> 
            <Row>
                <h5 className="mt-3">Choose desired format of advertising</h5>
            </Row>
            <Row className="text-center d-flex justify-content-center mt-3 g-3"> 
                {formats.map((format) => (
                    <Col xs={12} lg={4}>
                        <Box onClick={()=>navigate(`/campaigns/create/${format.name}`)} style={{cursor: "pointer"}}>
                            <h3 className="primary-gradient-text">{format.label}</h3>
                            <img src={`/${format.name}_format_example.png`} className="w-100"/>
                            <p className="text-start">
                                {format.name === "pop" 
                                ? "Receive mass traffic to your landing page or affiliate offer"
                                : format.name === "display"
                                ? "Display your ads in vertical oriented pages and get targeted clicks"
                                : ""
                                }
                            </p>
                            
                            <Button variant="primary">{format.label}</Button>
                        </Box>
                    </Col>  
                ))}
            </Row>
        </Container>
        </>
    );
}

export default CampaignCreateSelectFormatPage;