import './Logo.css';

const Logo = () => {
    return (
        <span className="logo-class">
            <span className="gradient-text">traffics</span><span style={{color: "#ccc"}}>.io</span>
        </span>
    )
}

export default Logo;