export const extractRatelimitDataFromForm = (formData) => {
    let amount = 0;
    if(formData.rateLimitPeriod && formData.rateLimitAmount && formData.rateLimitPeriod !== null) {
        if(formData.rateLimitAmount === "custom") amount = formData.rateLimitCustomAmount;
        else amount = formData.rateLimitAmount;
        return {
            period: formData.rateLimitPeriod.value,
            amount: amount,
        }
    }
}

export const extractScheduleDataFromForm = (formData) => {

    let result = {};
    if(formData.scheduleDays || formData.scheduleHourStart || formData.scheduleHourEnd) {
        if(formData.scheduleDays && formData.scheduleDays.length > 0) 
            result.days = formData.scheduleDays.map(v=>v.value);
        if(formData.scheduleHourStart !== formData.scheduleHourEnd) {
            result.hours = {
                start: formData?.scheduleHourStart?.value || 0, 
                end: formData?.scheduleHourEnd?.value || 0
            }
        }
        return result;
    }
}

export const extractTargetingDataFromForm = (formData) => {
    let result = {};
    const prefix = "targeting";

    Object
        .keys(formData)
        .filter(item=>item.startsWith(prefix))
        .forEach((item) => {
            if(formData[item]) {
                let key = item.substring(prefix.length).charAt(0).toLowerCase() + item.substring(prefix.length + 1);
                result[key] = Array.isArray(formData[item]) ? formData[item].map(v=>v.value) : [formData[item].value];
            }
        });

    return result;
}