import { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import { useStateContext } from '../../contexts/ContextProvider';
import './NotificationToast.css';

const NotificationToast = ({data}) => {

    const [show, setShow] = useState(true);
    const { removeNotification } = useStateContext();

    const toggleShow = () => {
        setShow(!show);
        removeNotification(data.key);
    };

    return (
        <Toast className="notification-toast" key={data.key} show={show} onClose={toggleShow} animation={true} autohide={true} delay={2000}>
            <Toast.Body className={data?.type === "error" ? "text-danger" : ""}>{data.content}</Toast.Body>
        </Toast>
    );
}

export default NotificationToast;