import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Loader, SelectList, TextInput, Icon } from '../../../components/components';
import { Container, Row, Col, Stack, Button, Form } from 'react-bootstrap';
import * as Utils from '../../../utils/common.js';
import { addUrlsToCampaign } from '../../../api/actions';
import validator from 'validator';
import axios from 'axios';

const AddMultipleUrlsSection = ({children, ...props}) => {
    const {register, handleSubmit, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });

    const [loaded, setLoaded] = useState(false);
    const [step, setStep] = useState(1);
    const [text, setText] = useState("");
    const [urls, setUrls] = useState([]);

    const formOptions = {
        urlsText: {required: "URLs are required"},
    };

    const handleText = (e) => {
        setText(e.value);
    }
    
    const handleFormData = (data) => {
        // let rawUrls = data.urlsText.trim();
        setText(data.urlsText);

        let rawUrls = data.urlsText
            .trim()
            .split("\n") // Break lines
            .map((line)=>(line.replace(/(\r\n|\n|\r|\t)/gm,""))) // Remove invalid characters from each line
            .filter((url)=>(validator.isURL(url)))


        if(rawUrls.length > 0) {
            setUrls(rawUrls);
            setLoaded(false);
            setStep(2);
            setLoaded(true);
            // 
        }
    }

    const handleSubmitVerifiedUrls = async () => {
        await addUrlsToCampaign(props.campaignId, urls);
        props.handleClose("urls")

    }

    const verifyUrls = async () => {

        const urlsCopy = [...urls];

        const promises = urlsCopy.map(async (url)=>{
            try {
                const checkUrl = await axios.get(url.url);
                return {...url, data: checkUrl}
            } catch (error) {
                return {...url, error: error.message, code: error.code, filtered: Object.keys(error).filter(key=>(key!=="stack")).map(filteredKey=>(error[filteredKey]))}
            }
        })

        const result = await Promise.all(promises);
        console.log(result);
        return result;

    }

    const handleError = (errors) => {console.log("Errors in form", errors)};

    useEffect(()=>{
        if(step === 2) {
            verifyUrls();
        }
    }, [step])

    useEffect(() => {
        setLoaded(true)
	},[]);

    return (  
        <>
        {loaded ?
            <>
            {step === 1 &&
                <form onSubmit={handleSubmit(handleFormData, handleError)}>
                    <p>Type URLs below. Each URL is a new line.</p>
                    <Container fluid className="m-0 p-0">
                        <Row>
                            <Col md={12}>
                                <Controller
                                    name="urlsText"
                                    control={control}
                                    rules={formOptions.urlsText}
                                    render={({field}) => (
                                        <>
                                        <Form.Group className="mb-3">
                                            <Form.Control 
                                                as="textarea" 
                                                rows={10}
                                                errorMessage={errors?.urlsText && errors.urlsText.message}
                                                {...field}
                                            />
                                        </Form.Group>                                    
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <Button type="submit">Check URLs</Button>
                </form>
            }
            {step === 2 &&
                <div>
                    <h3>Review your URLs</h3>
                    <p><span className="text-primary" style={{cursor: "pointer"}} onClick={()=>setStep(1)}><Icon iconName="ArrowLeft" size="24" /> Go back</span></p>
                    <ol style={{wordBreak: "break-all"}}>

                            {urls && urls.map((url, index)=>(
                                <li><Icon iconName="V" size="24" className="text-success" />{url}</li>
                            ))}                           
                    </ol>
                    <Button onClick={handleSubmitVerifiedUrls}>Add {urls.length} URLs to campaign</Button>
                </div>
            }
        </>
        : <Loader label="Loading section" />}
    </>
    )
}

export default AddMultipleUrlsSection;