export const timezones = [
    {
        "timezone_name" : "Africa/Abidjan",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Accra",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Bamako",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Banjul",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Bissau",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Conakry",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Dakar",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Freetown",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Lome",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Monrovia",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Nouakchott",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Ouagadougou",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Sao_Tome",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Timbuktu",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "America/Danmarkshavn",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Antarctica/Troll",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Atlantic/Canary",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Atlantic/Faeroe",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Atlantic/Faroe",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Atlantic/Madeira",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Atlantic/Reykjavik",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Atlantic/St_Helena",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Etc/GMT",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Etc/GMT-0",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Etc/GMT+0",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Etc/GMT0",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Etc/Greenwich",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Etc/UCT",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Etc/Universal",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Etc/UTC",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Etc/Zulu",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Europe/Belfast",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Europe/Guernsey",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Europe/Isle_of_Man",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Europe/Jersey",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Europe/Lisbon",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Europe/London",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Factory",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "GB",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "GB-Eire",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "GMT",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "GMT-0",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "GMT+0",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "GMT0",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Greenwich",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Iceland",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Portugal",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "UCT",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Universal",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "UTC",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "WET",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Zulu",
        "utc_offset" : "+00:00"
    },
    {
        "timezone_name" : "Africa/Algiers",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Bangui",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Brazzaville",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Casablanca",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Ceuta",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Douala",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/El_Aaiun",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Kinshasa",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Lagos",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Libreville",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Luanda",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Malabo",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Ndjamena",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Niamey",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Porto-Novo",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Tunis",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Arctic/Longyearbyen",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Atlantic/Jan_Mayen",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "CET",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Eire",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Etc/GMT-1",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Amsterdam",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Andorra",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Belgrade",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Berlin",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Bratislava",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Brussels",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Budapest",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Busingen",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Copenhagen",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Dublin",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Gibraltar",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Ljubljana",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Luxembourg",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Madrid",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Malta",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Monaco",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Oslo",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Paris",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Podgorica",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Prague",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Rome",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/San_Marino",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Sarajevo",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Skopje",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Stockholm",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Tirane",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Vaduz",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Vatican",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Vienna",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Warsaw",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Zagreb",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Europe/Zurich",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "MET",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Poland",
        "utc_offset" : "+01:00"
    },
    {
        "timezone_name" : "Africa/Blantyre",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Bujumbura",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Cairo",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Gaborone",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Harare",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Johannesburg",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Juba",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Khartoum",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Kigali",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Lubumbashi",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Lusaka",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Maputo",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Maseru",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Mbabane",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Tripoli",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Windhoek",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Asia/Amman",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Asia/Beirut",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Asia/Damascus",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Asia/Famagusta",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Asia/Gaza",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Asia/Hebron",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Asia/Jerusalem",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Asia/Nicosia",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Asia/Tel_Aviv",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "EET",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Egypt",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Etc/GMT-2",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Athens",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Bucharest",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Chisinau",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Helsinki",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Kaliningrad",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Kiev",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Mariehamn",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Nicosia",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Riga",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Sofia",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Tallinn",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Tiraspol",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Uzhgorod",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Vilnius",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Europe/Zaporozhye",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Israel",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Libya",
        "utc_offset" : "+02:00"
    },
    {
        "timezone_name" : "Africa/Addis_Ababa",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Africa/Asmara",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Africa/Asmera",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Africa/Dar_es_Salaam",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Africa/Djibouti",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Africa/Kampala",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Africa/Mogadishu",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Africa/Nairobi",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Antarctica/Syowa",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Asia/Aden",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Asia/Baghdad",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Asia/Bahrain",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Asia/Istanbul",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Asia/Kuwait",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Asia/Qatar",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Asia/Riyadh",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Etc/GMT-3",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Europe/Istanbul",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Europe/Kirov",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Europe/Minsk",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Europe/Moscow",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Europe/Simferopol",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Europe/Volgograd",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Indian/Antananarivo",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Indian/Comoro",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Indian/Mayotte",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Turkey",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "W-SU",
        "utc_offset" : "+03:00"
    },
    {
        "timezone_name" : "Asia/Tehran",
        "utc_offset" : "+03:30"
    },
    {
        "timezone_name" : "Iran",
        "utc_offset" : "+03:30"
    },
    {
        "timezone_name" : "Asia/Baku",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Asia/Dubai",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Asia/Muscat",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Asia/Tbilisi",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Asia/Yerevan",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Etc/GMT-4",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Europe/Astrakhan",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Europe/Samara",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Europe/Saratov",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Europe/Ulyanovsk",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Indian/Mahe",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Indian/Mauritius",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Indian/Reunion",
        "utc_offset" : "+04:00"
    },
    {
        "timezone_name" : "Asia/Kabul",
        "utc_offset" : "+04:30"
    },
    {
        "timezone_name" : "Antarctica/Mawson",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Aqtau",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Aqtobe",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Ashgabat",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Ashkhabad",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Atyrau",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Dushanbe",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Karachi",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Oral",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Qyzylorda",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Samarkand",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Tashkent",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Yekaterinburg",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Etc/GMT-5",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Indian/Kerguelen",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Indian/Maldives",
        "utc_offset" : "+05:00"
    },
    {
        "timezone_name" : "Asia/Calcutta",
        "utc_offset" : "+05:30"
    },
    {
        "timezone_name" : "Asia/Colombo",
        "utc_offset" : "+05:30"
    },
    {
        "timezone_name" : "Asia/Kolkata",
        "utc_offset" : "+05:30"
    },
    {
        "timezone_name" : "Asia/Kathmandu",
        "utc_offset" : "+05:45"
    },
    {
        "timezone_name" : "Asia/Katmandu",
        "utc_offset" : "+05:45"
    },
    {
        "timezone_name" : "Antarctica/Vostok",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Asia/Almaty",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Asia/Bishkek",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Asia/Dacca",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Asia/Dhaka",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Asia/Kashgar",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Asia/Omsk",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Asia/Qostanay",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Asia/Thimbu",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Asia/Thimphu",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Asia/Urumqi",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Etc/GMT-6",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Indian/Chagos",
        "utc_offset" : "+06:00"
    },
    {
        "timezone_name" : "Asia/Rangoon",
        "utc_offset" : "+06:30"
    },
    {
        "timezone_name" : "Asia/Yangon",
        "utc_offset" : "+06:30"
    },
    {
        "timezone_name" : "Indian/Cocos",
        "utc_offset" : "+06:30"
    },
    {
        "timezone_name" : "Antarctica/Davis",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Bangkok",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Barnaul",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Ho_Chi_Minh",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Hovd",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Jakarta",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Krasnoyarsk",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Novokuznetsk",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Novosibirsk",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Phnom_Penh",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Pontianak",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Saigon",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Tomsk",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Vientiane",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Etc/GMT-7",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Indian/Christmas",
        "utc_offset" : "+07:00"
    },
    {
        "timezone_name" : "Asia/Brunei",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Choibalsan",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Chongqing",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Chungking",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Harbin",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Hong_Kong",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Irkutsk",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Kuala_Lumpur",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Kuching",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Macao",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Macau",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Makassar",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Manila",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Shanghai",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Singapore",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Taipei",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Ujung_Pandang",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Ulaanbaatar",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Asia/Ulan_Bator",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Australia/Perth",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Australia/West",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Etc/GMT-8",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Hongkong",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "PRC",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "ROC",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Singapore",
        "utc_offset" : "+08:00"
    },
    {
        "timezone_name" : "Australia/Eucla",
        "utc_offset" : "+08:45"
    },
    {
        "timezone_name" : "Asia/Chita",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "Asia/Dili",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "Asia/Jayapura",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "Asia/Khandyga",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "Asia/Pyongyang",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "Asia/Seoul",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "Asia/Tokyo",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "Asia/Yakutsk",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "Etc/GMT-9",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "Japan",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "Pacific/Palau",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "ROK",
        "utc_offset" : "+09:00"
    },
    {
        "timezone_name" : "Australia/Adelaide",
        "utc_offset" : "+09:30"
    },
    {
        "timezone_name" : "Australia/Broken_Hill",
        "utc_offset" : "+09:30"
    },
    {
        "timezone_name" : "Australia/Darwin",
        "utc_offset" : "+09:30"
    },
    {
        "timezone_name" : "Australia/North",
        "utc_offset" : "+09:30"
    },
    {
        "timezone_name" : "Australia/South",
        "utc_offset" : "+09:30"
    },
    {
        "timezone_name" : "Australia/Yancowinna",
        "utc_offset" : "+09:30"
    },
    {
        "timezone_name" : "Antarctica/DumontDUrville",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Antarctica/Macquarie",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Asia/Ust-Nera",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Asia/Vladivostok",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/ACT",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/Brisbane",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/Canberra",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/Currie",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/Hobart",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/Lindeman",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/Melbourne",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/NSW",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/Queensland",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/Sydney",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/Tasmania",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/Victoria",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Etc/GMT-10",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Pacific/Chuuk",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Pacific/Guam",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Pacific/Port_Moresby",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Pacific/Saipan",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Pacific/Truk",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Pacific/Yap",
        "utc_offset" : "+10:00"
    },
    {
        "timezone_name" : "Australia/LHI",
        "utc_offset" : "+10:30"
    },
    {
        "timezone_name" : "Australia/Lord_Howe",
        "utc_offset" : "+10:30"
    },
    {
        "timezone_name" : "Antarctica/Casey",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Asia/Magadan",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Asia/Sakhalin",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Asia/Srednekolymsk",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Etc/GMT-11",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Pacific/Bougainville",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Pacific/Efate",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Pacific/Guadalcanal",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Pacific/Kosrae",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Pacific/Norfolk",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Pacific/Noumea",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Pacific/Pohnpei",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Pacific/Ponape",
        "utc_offset" : "+11:00"
    },
    {
        "timezone_name" : "Antarctica/McMurdo",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Antarctica/South_Pole",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Asia/Anadyr",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Asia/Kamchatka",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Etc/GMT-12",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Kwajalein",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "NZ",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Pacific/Auckland",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Pacific/Fiji",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Pacific/Funafuti",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Pacific/Kwajalein",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Pacific/Majuro",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Pacific/Nauru",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Pacific/Tarawa",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Pacific/Wake",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "Pacific/Wallis",
        "utc_offset" : "+12:00"
    },
    {
        "timezone_name" : "NZ-CHAT",
        "utc_offset" : "+12:45"
    },
    {
        "timezone_name" : "Pacific/Chatham",
        "utc_offset" : "+12:45"
    },
    {
        "timezone_name" : "Etc/GMT-13",
        "utc_offset" : "+13:00"
    },
    {
        "timezone_name" : "Pacific/Apia",
        "utc_offset" : "+13:00"
    },
    {
        "timezone_name" : "Pacific/Enderbury",
        "utc_offset" : "+13:00"
    },
    {
        "timezone_name" : "Pacific/Fakaofo",
        "utc_offset" : "+13:00"
    },
    {
        "timezone_name" : "Pacific/Kanton",
        "utc_offset" : "+13:00"
    },
    {
        "timezone_name" : "Pacific/Tongatapu",
        "utc_offset" : "+13:00"
    },
    {
        "timezone_name" : "Etc/GMT-14",
        "utc_offset" : "+14:00"
    },
    {
        "timezone_name" : "Pacific/Kiritimati",
        "utc_offset" : "+14:00"
    },
    {
        "timezone_name" : "America/Scoresbysund",
        "utc_offset" : "-01:00"
    },
    {
        "timezone_name" : "Atlantic/Azores",
        "utc_offset" : "-01:00"
    },
    {
        "timezone_name" : "Atlantic/Cape_Verde",
        "utc_offset" : "-01:00"
    },
    {
        "timezone_name" : "Etc/GMT+1",
        "utc_offset" : "-01:00"
    },
    {
        "timezone_name" : "America/Noronha",
        "utc_offset" : "-02:00"
    },
    {
        "timezone_name" : "Atlantic/South_Georgia",
        "utc_offset" : "-02:00"
    },
    {
        "timezone_name" : "Brazil/DeNoronha",
        "utc_offset" : "-02:00"
    },
    {
        "timezone_name" : "Etc/GMT+2",
        "utc_offset" : "-02:00"
    },
    {
        "timezone_name" : "America/Araguaina",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/Buenos_Aires",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/Catamarca",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/ComodRivadavia",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/Cordoba",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/Jujuy",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/La_Rioja",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/Mendoza",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/Rio_Gallegos",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/Salta",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/San_Juan",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/San_Luis",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/Tucuman",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Argentina/Ushuaia",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Bahia",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Belem",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Buenos_Aires",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Catamarca",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Cayenne",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Cordoba",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Fortaleza",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Godthab",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Jujuy",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Maceio",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Mendoza",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Miquelon",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Montevideo",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Nuuk",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Paramaribo",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Punta_Arenas",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Recife",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Rosario",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Santarem",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/Sao_Paulo",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "Antarctica/Palmer",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "Antarctica/Rothera",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "Atlantic/Stanley",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "Brazil/East",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "Etc/GMT+3",
        "utc_offset" : "-03:00"
    },
    {
        "timezone_name" : "America/St_Johns",
        "utc_offset" : "-03:30"
    },
    {
        "timezone_name" : "Canada/Newfoundland",
        "utc_offset" : "-03:30"
    },
    {
        "timezone_name" : "America/Anguilla",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Antigua",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Aruba",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Asuncion",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Barbados",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Blanc-Sablon",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Boa_Vista",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Campo_Grande",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Caracas",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Cuiaba",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Curacao",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Dominica",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Glace_Bay",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Goose_Bay",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Grenada",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Guadeloupe",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Guyana",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Halifax",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Kralendijk",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/La_Paz",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Lower_Princes",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Manaus",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Marigot",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Martinique",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Moncton",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Montserrat",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Port_of_Spain",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Porto_Velho",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Puerto_Rico",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Santiago",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Santo_Domingo",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/St_Barthelemy",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/St_Kitts",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/St_Lucia",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/St_Thomas",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/St_Vincent",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Thule",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Tortola",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Virgin",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "Atlantic/Bermuda",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "Brazil/West",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "Canada/Atlantic",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "Chile/Continental",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "Etc/GMT+4",
        "utc_offset" : "-04:00"
    },
    {
        "timezone_name" : "America/Atikokan",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Bogota",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Cancun",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Cayman",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Coral_Harbour",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Detroit",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Eirunepe",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Fort_Wayne",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Grand_Turk",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Guayaquil",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Havana",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Indiana/Indianapolis",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Indiana/Marengo",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Indiana/Petersburg",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Indiana/Vevay",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Indiana/Vincennes",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Indiana/Winamac",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Indianapolis",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Iqaluit",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Jamaica",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Kentucky/Louisville",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Kentucky/Monticello",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Lima",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Louisville",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Montreal",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Nassau",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/New_York",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Nipigon",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Panama",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Pangnirtung",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Port-au-Prince",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Porto_Acre",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Rio_Branco",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Thunder_Bay",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Toronto",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "Brazil/Acre",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "Canada/Eastern",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "Cuba",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "EST",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "EST5EDT",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "Etc/GMT+5",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "Jamaica",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "US/East-Indiana",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "US/Eastern",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "US/Michigan",
        "utc_offset" : "-05:00"
    },
    {
        "timezone_name" : "America/Bahia_Banderas",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Belize",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Chicago",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Costa_Rica",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/El_Salvador",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Guatemala",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Indiana/Knox",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Indiana/Tell_City",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Knox_IN",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Managua",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Matamoros",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Menominee",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Merida",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Mexico_City",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Monterrey",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/North_Dakota/Beulah",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/North_Dakota/Center",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/North_Dakota/New_Salem",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Rainy_River",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Rankin_Inlet",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Regina",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Resolute",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Swift_Current",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Tegucigalpa",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Winnipeg",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "Canada/Central",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "Canada/Saskatchewan",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "Chile/EasterIsland",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "CST6CDT",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "Etc/GMT+6",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "Mexico/General",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "Pacific/Easter",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "Pacific/Galapagos",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "US/Central",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "US/Indiana-Starke",
        "utc_offset" : "-06:00"
    },
    {
        "timezone_name" : "America/Boise",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Cambridge_Bay",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Chihuahua",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Creston",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Dawson",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Dawson_Creek",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Denver",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Edmonton",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Fort_Nelson",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Hermosillo",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Inuvik",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Mazatlan",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Ojinaga",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Phoenix",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Shiprock",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Whitehorse",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Yellowknife",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "Canada/Mountain",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "Canada/Yukon",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "Etc/GMT+7",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "Mexico/BajaSur",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "MST",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "MST7MDT",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "Navajo",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "US/Arizona",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "US/Mountain",
        "utc_offset" : "-07:00"
    },
    {
        "timezone_name" : "America/Ensenada",
        "utc_offset" : "-08:00"
    },
    {
        "timezone_name" : "America/Los_Angeles",
        "utc_offset" : "-08:00"
    },
    {
        "timezone_name" : "America/Santa_Isabel",
        "utc_offset" : "-08:00"
    },
    {
        "timezone_name" : "America/Tijuana",
        "utc_offset" : "-08:00"
    },
    {
        "timezone_name" : "America/Vancouver",
        "utc_offset" : "-08:00"
    },
    {
        "timezone_name" : "Canada/Pacific",
        "utc_offset" : "-08:00"
    },
    {
        "timezone_name" : "Etc/GMT+8",
        "utc_offset" : "-08:00"
    },
    {
        "timezone_name" : "Mexico/BajaNorte",
        "utc_offset" : "-08:00"
    },
    {
        "timezone_name" : "Pacific/Pitcairn",
        "utc_offset" : "-08:00"
    },
    {
        "timezone_name" : "PST8PDT",
        "utc_offset" : "-08:00"
    },
    {
        "timezone_name" : "US/Pacific",
        "utc_offset" : "-08:00"
    },
    {
        "timezone_name" : "America/Anchorage",
        "utc_offset" : "-09:00"
    },
    {
        "timezone_name" : "America/Juneau",
        "utc_offset" : "-09:00"
    },
    {
        "timezone_name" : "America/Metlakatla",
        "utc_offset" : "-09:00"
    },
    {
        "timezone_name" : "America/Nome",
        "utc_offset" : "-09:00"
    },
    {
        "timezone_name" : "America/Sitka",
        "utc_offset" : "-09:00"
    },
    {
        "timezone_name" : "America/Yakutat",
        "utc_offset" : "-09:00"
    },
    {
        "timezone_name" : "Etc/GMT+9",
        "utc_offset" : "-09:00"
    },
    {
        "timezone_name" : "Pacific/Gambier",
        "utc_offset" : "-09:00"
    },
    {
        "timezone_name" : "US/Alaska",
        "utc_offset" : "-09:00"
    },
    {
        "timezone_name" : "Pacific/Marquesas",
        "utc_offset" : "-09:30"
    },
    {
        "timezone_name" : "America/Adak",
        "utc_offset" : "-10:00"
    },
    {
        "timezone_name" : "America/Atka",
        "utc_offset" : "-10:00"
    },
    {
        "timezone_name" : "Etc/GMT+10",
        "utc_offset" : "-10:00"
    },
    {
        "timezone_name" : "HST",
        "utc_offset" : "-10:00"
    },
    {
        "timezone_name" : "Pacific/Honolulu",
        "utc_offset" : "-10:00"
    },
    {
        "timezone_name" : "Pacific/Johnston",
        "utc_offset" : "-10:00"
    },
    {
        "timezone_name" : "Pacific/Rarotonga",
        "utc_offset" : "-10:00"
    },
    {
        "timezone_name" : "Pacific/Tahiti",
        "utc_offset" : "-10:00"
    },
    {
        "timezone_name" : "US/Aleutian",
        "utc_offset" : "-10:00"
    },
    {
        "timezone_name" : "US/Hawaii",
        "utc_offset" : "-10:00"
    },
    {
        "timezone_name" : "Etc/GMT+11",
        "utc_offset" : "-11:00"
    },
    {
        "timezone_name" : "Pacific/Midway",
        "utc_offset" : "-11:00"
    },
    {
        "timezone_name" : "Pacific/Niue",
        "utc_offset" : "-11:00"
    },
    {
        "timezone_name" : "Pacific/Pago_Pago",
        "utc_offset" : "-11:00"
    },
    {
        "timezone_name" : "Pacific/Samoa",
        "utc_offset" : "-11:00"
    },
    {
        "timezone_name" : "US/Samoa",
        "utc_offset" : "-11:00"
    },
    {
        "timezone_name" : "Etc/GMT+12",
        "utc_offset" : "-12:00"
    }
]
