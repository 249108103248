import { useState, useEffect, forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import './SelectList.css';

const SelectList = forwardRef((props, ref) => {
    const id = props.id || null;
    const className = props.className || "";
    const placeholder = props.placeholder || "";
    const label = props.label || null;
    const floatingLabel = props.floatingLabel || null;
    const description = props.description || null;
    const onChange = props.onChange || null; // Handle onChange event
    const onBlur = props.onBlur || null; // Handle onChange event
    const value = props.value || null;
    const isValid = (props.isValid === null || typeof props.isValid === "undefined") ? true : props.isValid;
    const [selection, setSelection] = useState([]);
    const inheritedProps = {
        onChange: props.onChange,
        onBlur: props.onBlur,
        name: props.name,
        value: props.value,
        ref: props.ref
    }

    const handleValueChange = (event) => {
        setSelection(event);
        if(onChange !== null) onChange(event);
    }

    const handleOnBlur = (event) => {
        if(onBlur !== null) onBlur(event);
    }

    useEffect(()=>{
        if(props.preselected) {
            const preselectedOptions = 
                props.options.filter(option => (
                    props.preselected.some(preselectedOption => (
                        option.value === preselectedOption
                    )
                )))

            if(props.multi) {
                setSelection(preselectedOptions);
                handleValueChange(preselectedOptions); 
            } else {
                setSelection(preselectedOptions[0]);
                handleValueChange(preselectedOptions[0]);                 
            }
        } 

        if(props?.options?.length === 1) {
            setSelection(props.options[0]);
            handleValueChange(props.options[0]);
        }
    }, [])

    return (
        <div className="mb-3">
        {label 
            ? <Form.Label className="text-start w-100 fw-bold text-secondary" style={{verticalAlign: "middle"}}>
                {label} {selection && selection.length > 0 && props.isMulti ? `(${selection.length})` : ""}
              </Form.Label>
            : ""}

        <Select
            inputId={props.id}
            className={`select-list ${className}` + (!isValid ? " invalid-highlight" : "")}
            classNamePrefix="select"
            placeholder={props.placeholder ? props.placeholder : "Select..."}
            isMulti={props.multi ? true : false}
            isDisabled={props.disabled ? true : false}
            isLoading={props.loading ? true : false}
            isClearable={props.clearable ? true : false}
            isRtl={props.rtl ? true : false}
            isSearchable={props.searchable ? true : false}
            name={props.name ? props.name : "select-unnamed"}
            options={props.options}
            value={selection}
            onChange={handleValueChange}
            onBlur={handleOnBlur}
            menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            

        />

        {props?.errorMessage
            ? <span className="small w-100 text-danger">{props.errorMessage}</span>
            : ""}

        {description
            ? <Form.Text id={`${id}-description`} className="text-input-description" muted>{description}</Form.Text>
            : ""}
        
        </div>
    );
});

export default SelectList;