import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { Col, Row, Container, Button } from "react-bootstrap";
import { useAuth } from '../../../auth/AuthProvider';
import { unsubscribeUser } from '../../../api/actions';

const UnsubscribePage = () => {
    const auth = useAuth();

    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isDone, setIsDone] = useState(false);


    useEffect(()=>{
        setEmail(searchParams.get("email"));
    },[]);

    const handleSubmitUnsubscription = () => {
        setIsButtonDisabled(true);

        unsubscribeUser(email)
            .then(result=>setIsDone(true))
            .catch(error=>console.log("an error occured"))
    }

    return (
        <Container fluid>
            <Row className="vh-100 d-flex justify-content-center">
                <Col md={6} lg={6} xs={12} className="d-flex" style={{alignItems: "center"}}>
                    <div className="text-center m-auto">
                        <h3 className="mb-3">Unsubscribe from Traffics.io</h3>
                        {!isDone && 
                            <p className="text-start">Please confirm that you wish to stop receiving communications to a registered account with the Email address: <b>{email}</b></p>
                        }
                        
                        {isDone && 
                            <p className="text-start" style={{border: "2px #5ca14d dashed", padding: "10px 20px", borderRadius: "10px"}}>
                                Your request has been received. <br /><br />If an account with this Email address exists in our system, it will no longer receive communications. 
                                <br /><br />* Please note that transactional communication will still be sent in case you use the service.
                            </p>
                        }
                        <div className="d-flex gap-3">
                            {(!isDone || !isButtonDisabled) && <Button variant="primary" onClick={handleSubmitUnsubscription} disabled={isButtonDisabled}>Unsubscribe</Button>}
                            <a href="/"><Button variant="outline-primary">Back to platform</Button></a>
                        </div>

                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default UnsubscribePage;