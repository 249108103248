import { useState, useEffect, forwardRef } from 'react';
import './TextInput.css';
import Form from 'react-bootstrap/Form';

const TextInput = forwardRef((props, ref) => {
    const id = props.id || null;
    const type = props.type || 'text';
    const disabled = props.disabled || null;
    const readOnly = props.readOnly || null;
    const placeholder = props.placeholder || "";
    const className = props.className || "";
    const label = props.label || null;
    const floatingLabel = props.floatingLabel || null;
    const description = props.description || null;
    const value = props.value || "";
    const isValid = (props.isValid === null || typeof props.isValid === "undefined") ? true : props.isValid;
    const onChange = props.onChange || null; // Handle onChange event
    const onBlur = props.onBlur || null;
    const min = props.min || null;
    const max = props.max || null;
    const step = props.step || null;
    const required = props.required || null;

    useEffect(()=>{

    },[])

    const handleValueChange = (event) => {
        if(onChange !== null) onChange(event);
    }

    const handleOnBlur = (event) => {
        if(onBlur !== null) onBlur(event);
    }    

    return (
        <div className="text-input">
        {label 
        ? <Form.Label className="text-start w-100 mb-0" style={{verticalAlign: "middle"}}>{label} </Form.Label> 
        : ""}

        <Form.Floating className={`mb-3 text-start`}>
            <Form.Control 
                id={id}
                type={type}
                placeholder={placeholder}
                onChange={handleValueChange}
                onBlur={handleOnBlur}
                value={value}
                className={className + (!isValid ? " invalid-highlight" : "")}
                style={props.style}
                isValid={props.isValid}
                {...(min) && {min}}
                {...(max) && {max}}
                {...(step) && {step}}
                disabled={disabled}
                readOnly={readOnly}
                required={required}
            />

            {props?.errorMessage 
            ? <span className="small w-100 text-danger">{props.errorMessage}</span>
            : ""}

            {floatingLabel
            ? <label htmlFor={id}>{floatingLabel}</label>
            : ""}

            {description
            ? <Form.Text id={`${id}-description`} className="text-input-description" muted>{description}</Form.Text>
            : ""}
                      
        </Form.Floating>
        </div>     
     
    );
})

export default TextInput;