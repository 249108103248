import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Form, InputGroup, Button, Dropdown, Col, Row, Container, DropdownButton, Pagination, Stack } from 'react-bootstrap';
import { Icon, StatusBadge } from '../../components/components';
import * as Utils from '../../utils/common.js';
import './DataTable.css';

const DataTable = ({ data, ...props }) => {
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    if(!(data && data?.rows?.length > 0)) {
        data = {columns: data.columns, rows: []};
    }

    // Sort the data based on the selected column and direction
    const sortedData = [...data.rows].sort((a, b) => {
        if (sortColumn) {
            const aValue = a[data.columns.indexOf(sortColumn)];
            const bValue = b[data.columns.indexOf(sortColumn)];
            if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });

    // Filter the data based on the search term
    const filteredData = sortedData.filter((item) => 
        Object.values(item).some((value) => {
            if(value === null) return false;
            return value.toString().toLowerCase().includes(searchTerm ? searchTerm.toLowerCase() : "") || false
        })
    );

    // Get current items based on pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    
    // Handle sorting when a column header is clicked
    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    // Handle selecting/deselecting rows
    const handleRowSelect = (row) => {
        if (selectedRows.includes(row)) {
            setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== row));
        } else {
            setSelectedRows([...selectedRows, row]);
        }
    };

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle items per page change
    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
    };

    const handleChangeSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    }

    // Calculate total number of pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    return (
    <>

    <Container fluid style={{fontSize: "0.8rem"}}>
        <Row>
            <Col className="col px-0">
                <Stack direction="horizontal" className="my-2">
                    {(typeof(props?.showSearch) === 'undefined' || props.showSearch) && <Form.Control onChange={handleChangeSearchTerm} placeholder="Search..." style={{maxWidth:"500px", background: "none", border: 0, boxShadow: "none", padding: 0, color: "var(--first-color-l1"}}></Form.Control>}
                    {console.log(props)}
                    <div className="ms-auto">
                        <span className="me-3">
                            {currentPage * itemsPerPage - itemsPerPage + 1}
                            - 
                            {(currentPage * itemsPerPage) + currentItems.length - itemsPerPage} of {filteredData.length}
                        </span>
                    </div>
                    <div>
                        {totalPages > 1 &&  
                            <div style={{display: "inline-block", textAlign: "end", fontSize: "0.8rem"}}>
                            <Pagination className="mb-0">
                                {currentPage > 4 ? <Pagination.Prev onClick={() => handlePageChange(currentPage-1)} /> : ""}
                                {currentPage > 1 ? <Pagination.Item onClick={() => handlePageChange(1)}>{1}</Pagination.Item> : ""}
                                {currentPage - 2 > 2 ? <Pagination.Ellipsis /> : ""}
                                {currentPage - 1 > 1 ? <Pagination.Item onClick={() => handlePageChange(currentPage-1)}>{currentPage-1}</Pagination.Item> : ""}
                                <Pagination.Item active>{currentPage}</Pagination.Item> 
                                {currentPage + 1 < totalPages ? <Pagination.Item onClick={() => handlePageChange(currentPage+1)}>{currentPage+1}</Pagination.Item> : ""}
                                {currentPage + 2 < totalPages ? <Pagination.Ellipsis /> : ""}
                                {currentPage !== totalPages ? <Pagination.Item onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item> : ""}
                                {currentPage < totalPages ? <Pagination.Next onClick={() => handlePageChange(currentPage+1)} />: ""}
                            </Pagination>
                            </div>
                        } 
                    </div>
                
                    <Dropdown className="ms-2">
                        <Dropdown.Toggle variant="span" id="dropdown-items-per-page" className="text-primary" style={{fontSize: "1em", borderWidth: 0, padding: "2px 1px"}}>
                            Per Page: {itemsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {[10,25,100,500].map((value)=>(<Dropdown.Item onClick={() => handleItemsPerPageChange(value)}>{value}</Dropdown.Item>))}
                        </Dropdown.Menu>
                    </Dropdown> 
                </Stack>
            </Col>
          
        </Row>
    </Container>

    <div style={{overflow: "auto", border: "2px #eee solid", borderWidth: "2px", borderRadius: "10px"}}>
        <Table id="dataTable" className="mb-0" hover size="sm" style={{tableLayout: props?.fixed ? "fixed" : "initial"}}> 
            <thead style={{whiteSpace: 'nowrap'}}>
                <tr>
                    <th style={{width: "1%"}}></th>
                    {data?.columns?.map((column)=>(
                        <th key={column} className="text-start" onClick={() => handleSort(column)}>
                            {Utils.toSentenceCase(Utils.toSnakeCase(column))}
                            {sortColumn === column && (
                                <Icon iconName={`Arrow${sortDirection === 'asc' ? 'Up' : 'Down'}`} />
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody style={{verticalAlign: 'middle'}}>
            {currentItems.map((row, index) => (
                <tr
                key={index}
                className={selectedRows.includes(row) ? 'table-primary' : ''}
                >
                    <td key={index} 
                        onClick={() => handleRowSelect(row)}
                    ></td>
                    {row.map((r,index)=>(
                        <td className="text-start">
                            {row[index]  == null
                                ? "-" 
                                : typeof row[index] === "object" 
                                    ? JSON.stringify(row[index]) 
                                    : row[index]}
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </Table>
    </div>
    <Container fluid>
        <Row>
            <Col>
                {selectedRows.length > 0 && (
                <>
                    Selected Rows: {selectedRows.length}
                    <Button variant="danger" className="ms-2" onClick={() => setSelectedRows([])}>
                        Clear Selection
                    </Button>                        
                </>
                )}
            </Col>
        </Row>
    </Container>

    </>
    );
}

export default DataTable;