import { useState, useEffect } from 'react';

import BarChart from "./BarChart";
import LineChart from "./LineChart";

const ChartPlaceholder = ({data, style, message}) => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [mockData, setMockData] = useState({});

    useEffect(()=>{
        // Prevent creation of mock data on each rendering
        if(!isLoaded) {
            setMockData({
                labels: Array.from({length: 50}).fill("",0,50),
                datasets: [{label: "" , data: Array.from({length: 50}).fill("",0,50).map(a=>Math.round(Math.random() * 100))}],
            });
            setIsLoaded(true);
        }
    })

    return (
        <>
        {isLoaded && <>
            {message && <div style={{position: "absolute", left: "50%", top: "45%", transform: "translate(-50%, -50%)", border: "2px #eee solid", background: "#fff", padding: "10px 20px"}}>{message}</div>}

            <BarChart 
            // key={chartKey}
            chartData={mockData}                                
            options={{
                responsive: true,
                maintainAspectRatio: false,
                maxBarThickness: 40,
                plugins: {
                    legend: {
                        position: 'bottom',
                        display: false,
                    },
                    tooltip: {enabled: false},
                    hover: {mode: null},
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        border: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                        }
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                        border: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                        }
                    }
                },
            }}
            // style={style ? style : {maxHeight: "200px", width: "100%"}}
        />
        </>}
        </>
    )
}

export default ChartPlaceholder;