import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement} from "chart.js";
import { Bar } from "react-chartjs-2";

const createGradientForChart = (ctx, area) => {
	const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

	gradient.addColorStop(0, "#ff6c00");
	gradient.addColorStop(0.5, "#eee");
	gradient.addColorStop(1, "yellow");

	return gradient;
}

const BarChart = ({chartData, options, ...props}) => {
	ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);
	return (
		<Bar options={options} data={chartData} {...props} />
	)
}

export default BarChart;