import { useState, useEffect } from 'react';
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import { DateRange, DayPicker, SelectRangeEventHandler } from 'react-day-picker';
import { Button, Container, Row, Col, Stack } from 'react-bootstrap';
import 'react-day-picker/dist/style.css';
import './DatePicker.css';

const DatePicker = ({...props}) => {
    const [isVisible, setIsVisible] = useState(true);
    const [selectedRange, setSelectedRange] = useState();
    const [fromValue, setFromValue] = useState('');
    const [toValue, setToValue] = useState('');
    
    const handleRangeSelect = (range) => {
        setSelectedRange(range);
        if (range?.from) {
            setFromValue(format(range.from, 'y-MM-dd'));
        } else {
            setFromValue('');
        }
        if (range?.to) {
            setToValue(format(range.to, 'y-MM-dd'));
        } else {
            setToValue('');
        }
    };

    const handleSave = () => {
        props.saveaction(selectedRange || false);
        // handleClose();
    };    

    useEffect(()=>{
        if(props.selectedRange) {
            handleRangeSelect(props.selectedRange);
        }
    },[])


    return (
        isVisible &&
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Stack direction="horizontal" style={{flexWrap: "wrap"}}>
                        <DayPicker
                            className="date-picker-class"
                            mode="range"
                            selected={selectedRange}
                            onSelect={handleRangeSelect}
                            showOutsideDays
                            footer={
                                selectedRange ? `Selected: ${fromValue} ${toValue ? ` - ${toValue}` : ""}` : "Pick a day."
                            }
                            styles={{}}
                            {...props}
                        />
                        <Stack className="py-3">
                            <Button variant="outline-secondary" className="mb-2" onClick={()=>handleRangeSelect({from: new Date()})}>Today</Button>
                            <Button variant="outline-secondary" className="mb-2" onClick={()=>handleRangeSelect(false)}>All time</Button>
                            <Button variant="outline-primary" className="mt-auto" onClick={()=>handleSave()}>Save</Button>
                        </Stack>                        
                    </Stack>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {false && fromValue &&
                        <p className="text-center p-1 mb-1">Selected {fromValue && toValue ? `${((new Date(toValue).getTime() - new Date(fromValue).getTime()) / (1000 * 3600 * 24) ) +1} days` : "1 days"}<br /><b>{fromValue}</b><b>{toValue ? ` - ${toValue}` : ""}</b></p>
                    }
                </Col>
            </Row>

        </Container>
    );
}

export default DatePicker;