import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { Box, Loader, TextInput, SelectList, ExpandableBox, Icon, Tooltip } from '../../../../components/components';
import { Container, Row, Col, Stack, Badge, Button, InputGroup, Form } from 'react-bootstrap';
import { newPaymentRequest } from '../../../../api/actions';
import validator from 'validator';

const TopUpPage = () => {

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const {register, handleSubmit, getValues, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });    

    const formOptions = {
        paymentProvider: {required: "Required"},
        currency: {required: "Required"},
        amount: {
            required: "Required",
            min: {
                value: 10,
                message: "Must be $10 or more"
            },
            validate: (v) => (validator.isDecimal(v) || "Amount must be a decimal number")
        },
        agree: {
            validate: (v) => {console.log(v || "You must agree to the terms")}
        }
    };

    const handleSubmission = async () => {
        setSubmitButtonDisabled(true)
        setShowLoader(true);
        const formData = getValues();

        const submitData = {
            paymentProvider: formData.paymentProvider.value,
            currency: formData.currency.value,
            amount: formData.amount,
            returnUrl: document.location.path,
            type: "advertiser top up",
            description: "Deposit to advertising account"
        }

        try {
            const paymentLink = await newPaymentRequest(submitData);
            if(typeof(paymentLink) === 'object' && paymentLink?.link) {
                window.location.replace(paymentLink.link);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleError = (errors) => {
        // console.log("Errors in form", errors)
    };    

    return (
        
        <Container fluid>
            <Row className="justify-content-md-center">
                <Col lg={6} className="mobile-no-x-padding mt-3">
                    <div>
                        <p>Add funds to your account in order to run campaigns.</p>
                        <form onSubmit={handleSubmit(handleSubmission, handleError)}>
                            <Container style={{maxWidth: "500px"}}>
                                <Row>
                                    <Col lg={12}>
                                        <Box>
                                        <Container fluid>
                                            <Row>
                                                <Col lg={12}>
                                                    
                                                <Controller
                                                    name="paymentProvider"
                                                    control={control}
                                                    rules={formOptions.paymentProvider}
                                                    render={({field})=> (
                                                        <SelectList 
                                                            label="Payment method"
                                                            options={[{value: "bluesnap", label: <><Icon iconName="CreditCard" size="26" /> Credit/debit card</>}]}
                                                            errorMessage={errors?.paymentProvider?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                                </Col>
                                                <Col>
                                                    <Controller
                                                        name="currency"
                                                        control={control}
                                                        rules={formOptions.currency}
                                                        render={({field})=> (
                                                            <SelectList 
                                                                label="Currency"
                                                                options={[{value: "USD", label: <><Icon iconName="USD" size="26" /> US Dollar</>}]}
                                                                errorMessage={errors?.currency?.message}
                                                                {...field}
                                                            />
                                                        )}
                                                    />   
                                                </Col>
                                            </Row>                                                
                                            <Row>
                                                <Col md={3}></Col>
                                                <Col md={12}>
                                                <Controller
                                                    name="amount"
                                                    control={control}
                                                    rules={formOptions.amount}
                                                    defaultValue=""
                                                    render={({field})=> (
                                                        <TextInput 
                                                            label="Amount"
                                                            floatingLabel={`Type amount in ${watch("currency")?.value || "USD"}`}
                                                            description="Min. amount is $10"
                                                            errorMessage={errors?.amount?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                                
                                                </Col>
                                            </Row>                                                
                                        </Container>
                                        </Box>
                                    </Col>
                                </Row>
                                <Row className="mt-3 text-center">
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <Form.Check 
                                                id="agree" 
                                                aria-label="Terms checkbox" 
                                                label="I agree to Traffics.io's terms and conditions"
                                                errorMessage={errors?.agree?.message}
                                                {...register("agree")}
                                                required
                                            />
                                        </InputGroup>
                                        <Button type="submit" variant="primary" disabled={submitButtonDisabled}>Make Payment</Button>
                                        <br />
                                        {showLoader && <div className="mt-3"><Loader label="Please wait" /></div>}
                                    </Col>
                                </Row>
                            </Container>
                        
                        </form>
                    </div> 
                </Col>
            </Row>
        </Container>
    );
}

export default TopUpPage;