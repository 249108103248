import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import { addPageView } from '../api/actions.js';

const AuthLayout = ({ children }) => {

    const currentLocation = useLocation();

    useEffect(()=>{
        addPageView({url: window.location.href, referrer: document.referrer});
	}, [currentLocation]);

    return (
        <>
        <Container fluid  className="p-0">
            <Row className="m-0 p-0" >
                <Col className="p-0">
                    <Outlet />
                    {children}
                </Col>
            </Row>

        </Container>

        </>
    )
  }
  
  export default AuthLayout;