import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useStateContext } from './contexts/ContextProvider';
import { useMediaQuery } from 'react-responsive';
import AuthProvider, { useAuth } from './auth/AuthProvider';

import { getQueryParam, removeQueryParam } from './utils/common.js';
import { applyToken } from './api/actions.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import layouts from './layouts/layouts.js';
import pages from './pages/pages.js';
import ProtectedRoute from './auth/ProtectedRoute.js';
import './App.css';

const App = () => {

	const { isMobile, setIsMobile, setCurrentMode, currentMode, token, setToken, isAppLoaded, setIsAppLoaded } = useStateContext();
	const { isAuthorized } = useAuth();
	const checkIfMobile = useMediaQuery({ query: '(max-width: 992px)' }) // monitor if mobile
	
	useEffect(() => {
		setIsMobile(checkIfMobile); 
		
		const tokenFromQuery = getQueryParam("token");
		if(tokenFromQuery) {
			setToken(tokenFromQuery);
			applyToken(token)
				.then((result)=>{
					removeQueryParam("token")
				})
				.catch((error)=>{})
			;
		}

		// Hide page loader once rendered
		const loader = document.getElementById('app-loader');
		if (loader) loader.style.display = 'none';
		
		setIsAppLoaded(true);
		console.log("Loaded platform");
	}, []);

	return (
		isAppLoaded &&
		<div className="App" data-bs-theme={currentMode} >
			<Router>
				<Routes>
					<Route element={<ProtectedRoute />}>
						<Route path="/" element={<layouts.PlatformLayout />}>
							<Route index element={<pages.DashboardPage />} />
							<Route path="campaigns">
								<Route index element={<pages.CampaignsPage />} />
								<Route path="view/:id" element={<pages.CampaignViewPage />} />
								<Route path="create" element={<pages.CampaignCreateSelectFormatPage />} />
								<Route path="create/:format" element={<pages.CampaignCreatePage />} />
								<Route path="archived" element={<pages.ArchivedCampaignsPage />} />
							</Route>
							<Route path="reports" element={<pages.ReportsPage />} />
							<Route path="referrals" element={<pages.ReferralsPage />} />
							<Route path="contact" element={<pages.ContactPage />} />
							<Route path="account">
								<Route index element={<pages.ProfilePage />} />
								<Route path="payments" element={<pages.PaymentsPage />} />
								<Route path="top-up" element={<pages.TopUpPage />} />
							</Route>
						</Route>
					</Route>
					<Route path="/" element={<layouts.AuthLayout />}>
						<Route path="login/:entity?" element={isAuthorized ? <Navigate replace to="/" /> : <pages.LoginPage />} />
						<Route path="register" element={isAuthorized ? <Navigate replace to="/" /> : <pages.RegisterPage />} />
						<Route path="reset-password" element={<pages.ResetPasswordPage />} />
						<Route path="unsubscribe" element={<pages.UnsubscribePage />} />
					</Route>
					<Route path="*" element={<pages.NotFoundPage />} />
				</Routes>
			</Router>
		</div>

	);
}

export default App;
