import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Form, InputGroup, Button, Dropdown, Col, Row, Container, DropdownButton, Pagination, Stack } from 'react-bootstrap';
import { TextInput, Icon, StatusBadge, FlagIcon, DropdownMenu, Value } from '../../../components/components.jsx';
import * as Utils from '../../../utils/common.js';
import '../CampaignsTable/CampaignsTable.css';

const targetingTypes = {
    country: {},
    device: {},
}

const CampaignTargetingStatsTable = ({ targetingType, data, search }) => {

    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);

    if(!(data && Array.isArray(data) && data.length > 0)) {
        // return (<p>No data yet</p>)
        data = [];
    }

    const targetingTypeCellContent = (id) => {
        switch(targetingType) {
            case "country" : 
                return (
                    <Stack direction="horizontal" gap={2}>
                        <DropdownMenu className="hide-dropdown-arrow d-none" label={<Icon iconName="ThreeDotsVertical" />}>
                            <Dropdown.Item href="#/action-1"><Link to={`/campaigns/view/${id}`}><Icon iconName="Eye" size="22" /> View campaign</Link></Dropdown.Item>
                            <Dropdown.Item href="#/action-2"><Icon iconName="Archive" size="22" /> Archive</Dropdown.Item>
                            <Dropdown.Item href="#/action-3"><Icon iconName="Copy" size="22"/> Clone</Dropdown.Item>
                        </DropdownMenu>
                        <span style={{display: "inline-block", minWidth: "250px"}}>
                            <FlagIcon country={id} width="20" style={{boxShadow: "0px 0px 3px #c4c4c4"}}/> <span className="text-primary disable-text-selection">{Utils.getCountryByIso(id)?.name}</span>
                        </span>
                    </Stack>
                );
            case "device" : 
                return (
                    <Stack direction="horizontal" gap={2}>
                        <DropdownMenu className="hide-dropdown-arrow d-none" label={<Icon iconName="ThreeDotsVertical" />}>
                            <Dropdown.Item href="#/action-1"><Link to={`/campaigns/view/${id}`}><Icon iconName="Eye" size="22" /> View campaign</Link></Dropdown.Item>
                            <Dropdown.Item href="#/action-2"><Icon iconName="Archive" size="22" /> Archive</Dropdown.Item>
                            <Dropdown.Item href="#/action-3"><Icon iconName="Copy" size="22"/> Clone</Dropdown.Item>
                        </DropdownMenu>
                        <span style={{display: "inline-block", minWidth: "250px"}}>
                            <span className="text-primary disable-text-selection">{Utils.capitalizeString(id)}</span>
                        </span>
                    </Stack>
                );
            case "site_id" : 
                return (
                    <Stack direction="horizontal" gap={2}>
                        <DropdownMenu className="hide-dropdown-arrow" label={<Icon iconName="ThreeDotsVertical" />}>
                            <Dropdown.Item href="#/action-1"><Link to={`/campaigns/view/${id}`}><Icon iconName="Eye" size="22" /> View campaign</Link></Dropdown.Item>
                            <Dropdown.Item href="#/action-2"><Icon iconName="Archive" size="22" /> Archive</Dropdown.Item>
                            <Dropdown.Item href="#/action-3"><Icon iconName="Copy" size="22"/> Clone</Dropdown.Item>
                        </DropdownMenu>
                        <span style={{display: "inline-block", minWidth: "250px"}}>
                            <Link>{Utils.capitalizeString("Site " + id)}</Link>
                        </span>
                    </Stack>
                );
        }

    }

    // Sort the data based on the selected column and direction
    const sortedData = [...data].sort((a, b) => {
        if (sortColumn) {
            const aValue = a[sortColumn];
            const bValue = b[sortColumn];
            if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });

    // Filter the data based on the search term
    const filteredData = sortedData.filter((item) => 
        Object.values(item).some((value) => {
            if(value === null) return false;
            return value.toString().toLowerCase().includes(search ? search.toLowerCase() : "") || false
        })
    );

    // Get current items based on pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Handle sorting when a column header is clicked
    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    // Handle selecting/deselecting rows
    const handleRowSelect = (row) => {
        if (selectedRows.includes(row)) {
            setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== row));
        } else {
            setSelectedRows([...selectedRows, row]);
        }
    };

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle items per page change
    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
    };

    // Calculate total number of pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const tableColumns = [ 
        {
            id: targetingType,
            label: targetingType,
            colSize: "80px"
        },
        {
            id: "views",
            label: "Impressions",
            colSize: "50px"
        },
        {
            id: "value",
            label: "Spent",
            colSize: "50px"
        },
        {
            id: "avgBid",
            label: "Avg. Bid",
            colSize: "50px"
        },
        {
            id: "conversions",
            label: "Conversions",
            colSize: "50px"
        },
    ];

    return (
    <>
    <Container fluid style={{fontSize: "0.8rem"}}>
        <Row>
            <Col md={12}>
                <Stack direction="horizontal" className="my-2" style={{flexWrap: "wrap", gap: "14px"}}>
                    <div className="ms-1">
                        <div className="me-3" style={{display: "flex", gap:"10px"}}>
                            {totalPages > 1 && currentPage > 2 ? <span className="text-primary pointer disable-text-selection" onClick={() => handlePageChange(1)}>{`<<`}</span> : ""}
                            {totalPages > 1 && currentPage > 1 ? <span className="text-primary pointer disable-text-selection" onClick={() => handlePageChange(prev=>prev-1)}>{`<`}</span> : ""}
                            {currentPage * itemsPerPage - itemsPerPage + 1}
                            - 
                            {(currentPage * itemsPerPage) + currentItems.length - itemsPerPage} of {filteredData.length}
                            {totalPages > 1 && currentPage < totalPages ? <span className="text-primary pointer disable-text-selection" onClick={() => handlePageChange(prev=>prev+1)}>{`>`}</span> : ""}
                            {totalPages > 1 && currentPage < totalPages - 1 ? <span className="text-primary pointer disable-text-selection" onClick={() => handlePageChange(totalPages)}>{`>>`}</span> : ""}
                        </div>
                    </div>

                    <Dropdown className="ms-lg-1">
                        <Dropdown.Toggle variant="span" id="dropdown-items-per-page" className="text-primary" style={{fontSize: "1em", borderWidth: 0, padding: "2px 1px"}}>
                            Per Page: {itemsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {[10,25,100,500].map((value)=>(<Dropdown.Item onClick={() => handleItemsPerPageChange(value)}>{value}</Dropdown.Item>))}
                        </Dropdown.Menu>
                    </Dropdown> 
                </Stack>
            </Col>

            <Col md={6} className="text-end px-0 d-none">
                <InputGroup className="mb-1" style={{maxWidth: "500px", width: "100%"}}>
                    <Form.Control 
                        id="search"
                        floatingLabel="Search"
                        value={search}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(1);
                        }}
                        type="text" 
                        placeholder="Type here.." />
                </InputGroup> 
            </Col>            
        </Row>
    </Container>

    <div style={{overflow: "auto", fontSize: "0.9em"}}>

            <Table id="campaignsTable" hover size="sm" className="mb-0"> 
                <thead style={{whiteSpace: 'nowrap'}}>
                    <tr>
                        <th style={{width: "1%"}}></th>
                        {tableColumns.map((column, index)=>(
                            <th key={column.id} className={index===0 ? "text-start" :""} style={{width: column.colSize, fontWeight: "400", textAlign: "center"}} onClick={() => handleSort(column.id)}>
                                {Utils.toSentenceCase(column.label)}
                                {sortColumn === column.id && (
                                    <Icon iconName={`Arrow${sortDirection === 'asc' ? 'Up' : 'Down'}`} />
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody style={{verticalAlign: 'middle'}}>
                {currentItems.map((row, index) => (
                    <tr
                    key={index}
                    className={selectedRows.includes(row) ? 'table-primary' : ''}
                    >
                        <td
                            onClick={() => handleRowSelect(row)}
                        ></td>
                        <td key={index} className="text-start">
                            {targetingTypeCellContent(row[targetingType])}
                        </td>
                        <td className="text-center">{row.views ? Utils.formatNumber(row.views,4) : 0}</td>
                        <td className="text-center"><Value type="monetary" value={row.value} symbol="$" decimals="4" /></td>
                        <td className="text-center">${row.views && row.value ? Utils.formatNumber(row.value / row.views, 5) : 0}</td>
                        <td className="text-center">{Utils.formatNumber(row.conversions) || 0}</td>
                    </tr>
                ))}
                    <tr className="fw-bold">
                        <td></td>
                        <td></td>
                        <td className="text-center">{Utils.formatNumber(currentItems.reduce((acc,cur)=>(acc+=cur.views),0))}</td> 
                        <td className="text-center"><Value type="monetary" value={currentItems.reduce((acc,cur)=>(acc+=cur.value),0)} symbol="$" decimals="4" /></td>
                        <td className="text-center">${Utils.formatNumber(currentItems.reduce((acc,cur)=>(acc+=cur.value),0) / currentItems.reduce((acc,cur)=>(acc+=cur.views),0),5)}</td> 
                        <td className="text-center">{Utils.formatNumber(currentItems.reduce((acc,cur)=>(acc+=cur.conversions),0))}</td>                  
                    </tr>
                </tbody>
            </Table>
        </div>
        <Container fluid>
            <Row>
                <Col>
                    {selectedRows.length > 0 && (
                    <>
                        Selected Rows: {selectedRows.length}
                        <Button variant="danger" className="ms-2" onClick={() => setSelectedRows([])}>
                            Clear Selection
                        </Button>                        
                    </>
                    )}
                </Col>
            </Row>
        </Container>

        </>
    );
}

export default CampaignTargetingStatsTable;