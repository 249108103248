import DashboardPage from './Platform/Dashboard/DashboardPage';
import ProfilePage from './Platform/Account/Profile/ProfilePage';
import ContactPage from './Platform/Contact/ContactPage';

import CampaignsPage from './Platform/Campaigns/CampaignsPage';
import ArchivedCampaignsPage from './Platform/Campaigns/ArchivedCampaignsPage';
import CampaignViewPage from './Platform/Campaigns/Campaign/ViewCampaign/CampaignViewPage';
import CampaignCreatePage from './Platform/Campaigns/Campaign/CreateCampaign/CampaignCreatePage';
import CampaignCreateSelectFormatPage from './Platform/Campaigns/Campaign/CreateCampaign/CampaignCreateSelectFormatPage';

import TopUpPage from './Platform/Account/TopUp/TopUpPage';
import ReportsPage from './Platform/Reports/ReportsPage';
import NotFoundPage from './Errors/NotFoundPage';

import LoginPage from './Auth/Login/LoginPage';
import RegisterPage from './Auth/Register/RegisterPage';
import ResetPasswordPage from './Auth/ResetPassword/ResetPasswordPage';
import UnsubscribePage from './Auth/Unsubscribe/UnsubscribePage';

import ReferralsPage from './Platform/Referrals/ReferralsPage';
import PaymentsPage from './Platform/Account/Payments/PaymentsPage';

const pages = {
    DashboardPage,
    ProfilePage,
    CampaignsPage,
    ArchivedCampaignsPage,
    CampaignViewPage,
    CampaignCreatePage,
    CampaignCreateSelectFormatPage,

    TopUpPage,
    PaymentsPage,
    ReportsPage,
    NotFoundPage,

    LoginPage,
    RegisterPage,
    ResetPasswordPage,
    UnsubscribePage,

    ReferralsPage,
    ContactPage,
} 

export default pages;