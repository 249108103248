import { useState, useEffect } from 'react';
// import { useStateContext } from '../../contexts/ContextProvider';
import { Stack } from 'react-bootstrap';
import './QuickActions.css';

const QuickActions = ({children, className}) => {

	// const { isMobile } = useStateContext();
    const isMobile = true;

    return (
        <div className={`quick-actions ${className} ${isMobile ? "scroll-shadows" : ""}`}>
            <Stack gap={3} direction={isMobile ? "horizontal" : "vertical"}>
                {children}
            </Stack>
        </div>
    );
};

export default QuickActions;