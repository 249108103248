import EasyEdit, { Types } from "react-easy-edit";
import './EditableTextField.css';
import { Icon } from '../../components/components';

const EditableTextField = (props) => {
    return (
        <div class="editable-text-field">
            <EasyEdit
                type={Types.TEXT}
                value={props.value}
                onSave={props.onSave}
                onCancel={props.onCancel}
                saveButtonLabel={props.saveLabel || <Icon iconName="V" />}
                cancelButtonLabel={props.cancelLabel || <Icon iconName="X" />}
                attributes={{ name: "awesome-input", id: 1 }}
                instructions={props.helperText || null}
                {...props}
            />
        </div>

    )
}

export default EditableTextField;
