import { useState, useEffect, forwardRef } from 'react';
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom';
import { useAuth } from '../../auth/AuthProvider';
import { Logo, Icon, DropdownMenu } from '../../components/components';
import { capitalizeString } from '../../utils/common';
import { Container, Row, Col, Nav, Navbar, NavDropdown, Dropdown } from 'react-bootstrap';
import { formatNumber } from '../../utils/common';

import './Topbar.css';

const Topbar = forwardRef(({sidebarToggler, isSidebarOpen, ...props}, ref) => {
    const navigate = useNavigate();

    const auth = useAuth();
    const {profile} = auth;

    const currentLocation = useLocation();
    const [url, setUrl] = useState();
    const [breadcrumbs, setBreadcrumbs] = useState([]);

	const handleLogout = () => {
		auth.logout().then(()=>{navigate('/login')});
	};

    const buildBreadcrumbs = () => {
        setBreadcrumbs([]);
        
        const arr = currentLocation.pathname.slice(1).split("/");
        let breadcrumbs = [];

        for(let i=0;i<arr.length;i++) {
            
            switch(arr[i]) {
                case "platform" : if(!arr[i+1]) breadcrumbs.push({path: "/", label: "Dashboard"}); break;
                case "campaigns" : breadcrumbs.push({path: "/campaigns", label: "My Campaigns"}); break;
                case "pop" : breadcrumbs.push({path: "/campaigns/create/pop", label: "New Campaign"}); break;
                case "create" : breadcrumbs.push({path: "/campaigns/create", label: "Select ad format"}); break;
                case "referrals" : breadcrumbs.push({path: "/referrals", label: "Referrals"}); break;
                case "account" : breadcrumbs.push({path: "/account", label: "Account"}); break;
                case "profile" : breadcrumbs.push({path: "/profile", label: "Profile"}); break;
                case "contact" : breadcrumbs.push({path: "/contact", label: "Contact us"}); break;
                case "payments" : breadcrumbs.push({path: "/account/payments", label: "Payments"}); break;
                case "top-up" : breadcrumbs.push({path: "/account/top-up", label: "Top up"}); break;
                case "view" : 
                    // if(["campaigns"].includes(arr[i-1])) 
                    breadcrumbs.push({path: "/"+arr[i-1]+"/view/"+arr[i+1], label: "Campaign"}); i++; break;
                default : breadcrumbs.push({path: currentLocation.pathname, label: "Dashboard"});
            }
        }

        const selectedBreadcrumb = breadcrumbs.pop();
        setBreadcrumbs([selectedBreadcrumb])
    }

    useEffect(()=>{
        setUrl(currentLocation.pathname);
        buildBreadcrumbs();
        if(props?.setLoaded) props.setLoaded(true);
    }, [currentLocation])

    return (
        <>
            <div id="topbar-wrapper" className={isSidebarOpen ? "sidebar-offset" : ""} ref={ref}>
                <div id="topbar" className="py-2 py-lg-1">
                    <div style={{flexShrink: 1, lineHeight: 1}}>
                        <Icon iconName="List2" size="24" className="icon-hover" onClick={()=>sidebarToggler()}/>
                    </div>

                    <p className="nav-breadcrumbs ms-2 w-sm-100 text-start" style={{flexShrink: 1}}>{breadcrumbs.length > 0 && breadcrumbs.map((breadcrumb,index)=>(
                        <span>
                            <Link className={`nav-breadcrumb ${index===0 && "gradient-text"} `} to={breadcrumb.path}>{breadcrumb.label}</Link>{breadcrumbs.length>index+1 ? " > " : ""}
                        </span>))}
                    </p>
                    
                    <div className="ms-auto me-lg-1">
                        <DropdownMenu id="dropdown-item-button" className="ms-auto" variant="span" as="span" size="sm" label={
                            <>
                                <Icon iconName="Profile" size="28" style={{color: "#777"}} className="icon-hover" />
                                <span className="top-profile-balance" style={{color: "#555", fontSize:"0.8em"}}>
                                    <span className="desktop-only me-2">
                                        {profile?.firstName?.split(/[ -]/g).map(v=>v.charAt(0).toUpperCase()).join(".") || "hello"} 
                                    </span>

                                    ${profile?.accounts?.advertiser?.length > 0 ? formatNumber(profile.accounts.advertiser.find((acc=>acc.id===profile.advertiserId)).balance,2) : 0}
                                </span>
                            </>}>
                            <Dropdown.ItemText>
                                {profile?.firstName || "Hello"}<br />
                                <span style={{fontSize: "0.8em", color: "#555"}}>User ID {profile.id}</span>
                            </Dropdown.ItemText>
                            <Dropdown.Item><Link to="/account">My Account</Link></Dropdown.Item>
                            <Dropdown.Item><Link to="/account/top-up">Top up funds</Link></Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                        </DropdownMenu>
                    </div>
                </div>
            </div>
        </>
    );
})

export default Topbar;