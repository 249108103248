import { useContext, createContext, useState, useEffect } from "react";
import { getUserProfile } from '../api/actions';
import useInterval from "../utils/useInterval.js";
import { AUTO_REFRESH_PROFILE_INTERVAL_MS } from "../config.js"; 
import useIsActiveWindow from '../utils/useIsActiveWindow.js';
import api from '../api/api';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const [profile, setProfile] = useState(localStorage.getItem("profile") || "");
	const [autoRefreshProfile, setAutoRefreshProfile] = useState(true);
	const [isAuthorized, setIsAuthorized] = useState(Boolean(localStorage.getItem("profile") || false));
	const [isLoaded, setIsLoaded] = useState(false);

    const isActiveWindow = useIsActiveWindow();

	const login = async (credentials) => {
		try {
			const response = await api.post("/login", credentials);

			if(response?.headers?.authorization) {
				const profile = JSON.stringify(response.data);
				localStorage.setItem('authtoken',response.headers.authorization);
				localStorage.setItem('profile',profile);
				saveProfile(response.data);
				setIsAuthorized(true);
			
				return response.data;
			} 

			if(response?.error) {
				return {error: response.error};
			}

			throw new Error(response.message);
		} catch (error) {
			
			if(error?.response?.data?.error) {
				return {error: error.response.data.error}
			}

			return {error}
		}
	};

	const register = async (data) => {
		try {
			const response = await api.post('/register',data);

			if(response?.headers?.authorization) {
				localStorage.setItem('authtoken',response.headers.authorization);
				localStorage.setItem('profile',JSON.stringify(response.data));
			}

			return response.data;
		} catch (error) {
			if(error?.response?.data?.error) {
				return {error: error.response.data.error}
			}

			return {error};
		}
	}

	const logout = async () => {
		try {
			await api.post("/logout");
		} catch (error) {
			console.log(error);
		}
		await wipeAuth();
	};

	const wipeAuth = async () => {
		setProfile(null);
		setIsAuthorized(false);

		localStorage.clear();
	}

    const saveProfile = (data) => {
        if(data?.id) {
			if(data?.accounts?.advertiser.length > 0) data.advertiserId = data.accounts.advertiser[0].id;
			if(data?.accounts?.publisher.length > 0) data.publisherId = data.accounts.publisher[0].id;
            localStorage.setItem('profile',JSON.stringify(data));

            setProfile(data); 
        }
    }

	const checkProfile = async () => {
		const currentAuth = localStorage.getItem('authtoken');
		const currentProfile = localStorage.getItem('profile');
		if (currentAuth && currentProfile) {
			const profileObj = JSON.parse(currentProfile);

			setIsAuthorized(true);
			setProfile(profileObj);

			// Get updated user information

			if(profileObj?.entity === "user") {
				const entityInfo = await getUserProfile();

				if(entityInfo?.id) {
					saveProfile({...entityInfo, entity: profileObj.entity})
				} else {
					// wipeAuth(); // Log out if backend is not available
				}
			} else {
				wipeAuth(); 
			}
		} else {
			wipeAuth(); 
		}		
	}

    useInterval(async () => {
        if(profile?.id && isActiveWindow && autoRefreshProfile) {
            await checkProfile();
        }
    }, profile?.autoRefreshInterval || AUTO_REFRESH_PROFILE_INTERVAL_MS);

	useEffect(() => {
		checkProfile().then(()=>setIsLoaded(true));
    }, []);

	return (
		<AuthContext.Provider value={{ isAuthorized, profile, login, register, logout }}>
		{isLoaded && children}
		</AuthContext.Provider>
	);

};

export default AuthProvider;

export const useAuth = () => {
	return useContext(AuthContext);
};
