import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../auth/AuthProvider';

import useInterval from '../../../utils/useInterval.js';
import { AUTO_REFRESH_DASHBOARD_INTERVAL_MS } from '../../../config.js';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { StatWidget, Icon, Box, TrafficByTimeChart, ChartPlaceholder, Loader } from '../../../components/components';
import { QuickActions } from '../../../containers/containers';

import * as Utils from '../../../utils/common.js';

import { getCampaignsChartByAdvertiserId, getUserDashboardData } from '../../../api/actions.js';

const DashboardPage = () => {

    const {profile} = useAuth();
    const navigate = useNavigate();

    const [chart, setChart] = useState(false);
    const [chartMessage, setChartMessage] = useState(false);
    const [dashboardData, setDashboardData] = useState({updates: false});
    const [autoRefresh, setAutoRefresh] = useState(true);

    useInterval(async () => {
        if(autoRefresh) {
            await refreshDashboardData();
        }
    }, profile?.autoRefreshInterval || AUTO_REFRESH_DASHBOARD_INTERVAL_MS);

    const refreshDashboardData = async () => {
        const options = {
            startTime: parseInt((new Date().getTime() / 1000) - (60*60*24)),
            endTime: parseInt(new Date().getTime() / 1000) + (60*60*24*7),
            groupBy: "time",
            timeGroup: "hour"
        }

        getUserDashboardData()
            .then(result=>setDashboardData(result))
            .catch(error=>console.log(error));
            
        getCampaignsChartByAdvertiserId(profile.advertiserId, options)
            .then(data=>{
                setChart(data)
                if(!data) {
                    setChartMessage("There was no traffic in the last 24 hours")
                } else if(data.error) {
                    setChartMessage("Data is not available")
                } else if (Object.keys(data).length === 0) {
                    setChartMessage("No data in timeframe")
                }
            })
            .catch(error=>console.log(error));
    }

    useEffect(()=>{
        refreshDashboardData();
    },[])

    return (
        <Container fluid className="p-0">
            <Row className="justify-content-md-center">
                <Col lg={12}>
                    <div className="my-2 mt-md-1" style={{display: "flex", flexWrap: "wrap"}}>
                        <div style={{maxWidth: "100%"}}>
                        <QuickActions className="my-2 my-lg-0 me-auto">
                                <span className="link-primary text-nowrap"><Link to="/campaigns/create"><Icon iconName="PlusLg" size="22" /> New campaign</Link></span>
                                <span className="link-primary text-nowrap"><Link to="/account/top-up"><Icon iconName="CreditCard" size="1rem" /> Add funds</Link></span>
                            </QuickActions> 
                        </div>
                    </div>  
                </Col>
            </Row>
            <Row>
                <Col lg={9}>
                    <Container fluid className="p-0 text-center mt-1 mb-3">
                        <Row className="g-2">
                            <Col xs={6} lg={3}>
                                <StatWidget 
                                    title="Account balance"
                                    value={`$${profile?.accounts?.advertiser?.length > 0 ? Utils.formatNumber(profile.accounts.advertiser.find((acc=>acc.id===profile.advertiserId)).balance,2) : 0}`}
                                    // text={"Top up | History"}
                                    tooltip="This is the relevant tooltip"
                                />
                            </Col>
                            <Col xs={6} lg={3}>
                                <StatWidget 
                                    title="Active campaigns"
                                    value={<span className="link-primary">{dashboardData?.campaigns?.activeCampaigns || "0"}</span>}
                                    onClick={()=>navigate("/campaigns")}
                                    // text={dashboardData?.campaigns?.pendingCampaigns > 0 ? dashboardData?.campaigns?.pendingCampaigns + " pending" :  " "}
                                />
                            </Col>
                            <Col xs={6} lg={3}>
                                <StatWidget 
                                    title="Pending campaigns"
                                    value={<span>{dashboardData?.campaigns?.pendingCampaigns || "0"}</span>}
                                    // text={dashboardData?.campaigns?.pendingCampaigns > 0 ? dashboardData?.campaigns?.pendingCampaigns + " pending" :  " "}
                                />
                            </Col>
                            <Col xs={6} lg={3}>
                                <StatWidget 
                                    title="Daily spend"
                                    value={"$"+Utils.formatNumber(dashboardData?.campaigns?.dailyBudgetUsed,2) || "0"}
                                />
                            </Col>                            
                        </Row>
                        <Row className="mt-3 text-start">
                            <Col>

                                <p>Last 24 hour traffic</p>
                                <div className="position-relative px-1 py-3 text-center" style={{height: "240px", overflow: "hidden"}}>
                                    {chart 
                                        ? chart?.rows
                                            ? <TrafficByTimeChart data={chart}/>
                                            : <ChartPlaceholder message={chartMessage} />
                                        : <div style={{height:"240px"}}> <Loader label="Loading chart" position="center" /> </div>
                                    }
                                </div>

                            </Col>
                        </Row>
                        <Row className="mt-3 d-none">
                            <Col>
                            <Box className="text-start">
                                    <h5 className="green">The new platform is here!</h5>
                                    We have re-engineered our service to enhance your marketing experience with us. <br /><br />
                                    <ul>
                                        <li>Multi URL campaigns</li>
                                        <li>Dynamic Bid</li>
                                        <li>Localized timezones</li>
                                    </ul>
                                </Box>  
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className="p-0">
                        <Row>
                            <Col className="col-lg-5 text-start mb-3">
                                <p>Top 5 campaigns by daily spend</p>
                                <div className="position-relative text-center" style={{wordBreak: "break-word", fontSize: "0.8em", color: "#5A5A5A"}}>

                                {dashboardData?.campaigns?.recentCampaigns && dashboardData?.campaigns?.recentCampaigns.length > 0
                                    ? dashboardData?.campaigns?.recentCampaigns.map((campaign, index)=>{
                                        return (
                                            <p className="mb-2 text-start">
                                                <span className="me-1" style={{fontWeight: "300", color: "#b9b9b9"}}>#{index+1}</span> <Link to={`/campaigns/view/${campaign.id}`}>{campaign.label || "Campaign #" + campaign.id}</Link> <span className="float-end">${(Math.floor(campaign.daily_budget_used * 100) / 100).toFixed(2)}</span><br />
                                            </p>
                                        )
                                    })
                                    : !dashboardData?.campaigns?.recentCampaigns
                                        ? <div className="d-block py-2"><Loader position="center" label="Loading active campaigns" /></div>
                                        : <div className="text-start">No campaign activity in the last 24 hours.</div>
                                }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                
                <Col className="text-start">
                    <p>Recent updates</p>
                    <div className="position-relative text-center" style={{wordBreak: "break-word", fontSize: "0.8em", color: "#5A5A5A"}}>
                    {dashboardData?.updates && dashboardData.updates.length > 0
                        ? dashboardData.updates.map(update=>{
                            return (
                                <p className="mb-1 text-start"><small style={{color: "#b9b9b9"}}>{Utils.timeAgo(new Date(update.create_time + "Z").toISOString())} ago</small><br />
                                    {update.module === "advertisers" ? <span>{update.type === 'timezone' ? "Advertiser Time zone" : ""} {update.data}</span> : ""} 
                                    {update.module === "campaigns" ? <span><Link to={`/campaigns/view/${update.related_id}`}>Campaign {update.label || update.related_id}</Link> {update.data}</span> : ""} 
                                    {update.module === "ads" ? <span><Link to={`/campaigns/view/${update.related_id}`}>Ad {update.label || update.related_id}</Link> {update.data}</span> : ""} 
                                </p>
                            )
                        })
                        : !dashboardData?.updates
                            ? <div className="d-block py-2"><Loader position="center" label="Loading updates" /></div>
                            : <div className="text-start">No updates in the last 24 hours.</div>
                    }
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default DashboardPage;