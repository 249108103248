import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useForm, Controller } from "react-hook-form";
import validator from 'validator';
import { useAuth } from '../../../auth/AuthProvider';
import { Button, Card, Form, Stack} from "react-bootstrap";
import { Modal, TextInput } from '../../../components/components';

import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm';
import OneTimeLoginLinkForm from '../OneTimeLoginLinkForm/OneTimeLoginLinkForm';

const LoginForm = ({returnUrl, ...props}) => {

    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [showOneTimeLoginLinkModal, setShowOneTimeLoginLinkModal] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const auth = useAuth();
    const entity = params.entity || "user";

    const {register, handleSubmit, getValues, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });    

    const [errorText, setErrorText] = useState(false);

    const formOptions = {
        email: {
            required: "Email is required",
            validate: (v)=>(validator.isEmail(v) || "Email is invalid"),
        },
        password: {
            required: "Password is required",
            minLength: {
                value: 1,
                message: "Password is too short."
            }
        },
    }
    const handleError = (errors) => {
        return true;
    };

    const handleSubmission = async () => {
        const formData = getValues();

		const loginAttempt = await auth.login({entity, authMethod: "email", email: formData.email, password: formData.password});
		if(loginAttempt?.error) {
			setErrorText(loginAttempt.error);
		} else if(loginAttempt?.id) {
            if(returnUrl) {
                navigate(returnUrl);
            } else {
                navigate("/");
            }
        }
	};

    const handleShowForgotPasswordModal = () => setShowForgotPasswordModal(!showForgotPasswordModal);
    const handleShowOneTimeLoginLinkModal = () => setShowOneTimeLoginLinkModal(!showOneTimeLoginLinkModal);

    return (
        <>
            <div className="mb-3 mt-3 mt-lg-1" style={{maxWidth: "550px", margin: "0 auto"}}>
                {props.error}
                <h2 className="fw-bold mb-2">Login to Traffics.io</h2>
                <p className="mb-4">Please enter your email and password!</p>
                <div className="mb-3">
                    <Form onSubmit={handleSubmit(handleSubmission, handleError)}>
                        <Controller
                            name="email"
                            control={control}
                            rules={formOptions.email}
                            render={({field}) => (
                                <TextInput 
                                    id="email" 
                                    type="email" 
                                    floatingLabel="Email address" 
                                    placeholder="Your email address" 
                                    // defaultValue=""
                                    errorMessage={errors?.email?.message}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            rules={formOptions.password}
                            render={({field}) => (  
                                <TextInput 
                                    id="password" 
                                    type="password" 
                                    floatingLabel="Password" 
                                    placeholder="Your password"
                                    // defaultValue=""
                                    errorMessage={errors?.password?.message}
                                    {...field}
                                />
                            )}
                        />
                        
                        <p className="small">
                            <Stack direction="horizontal">
                                <a className="text-primary" onClick={()=>handleShowForgotPasswordModal()} style={{cursor: "pointer", flexGrow: "1", textAlign: "left"}}>Forgot password?</a>
                                <a className="text-primary" onClick={()=>handleShowOneTimeLoginLinkModal()} style={{cursor: "pointer"}}>Get Login Link</a>
                            </Stack>
                        </p>
                        {errorText 
                        ? <p className="text-danger">{errorText.toString()}</p>
                        : ""}
                        <div className="d-grid">
                            <Button variant="primary" type="submit">Login</Button>
                        </div>
                    </Form>
                    <div className="mt-3">
                        <p className="mb-0  text-center">
                            Don't have an account yet?{" "}
                            <Link to="/register"><a className="text-primary fw-bold text-nowrap">Get started</a></Link>
                        </p>
                    </div>
                </div>
            </div>        
        
            <Modal show={showForgotPasswordModal} onHide={()=>handleShowForgotPasswordModal()} header="Need help logging in?">
                <ForgotPasswordForm closeModal={handleShowForgotPasswordModal} />
            </Modal>

            <Modal show={showOneTimeLoginLinkModal} onHide={()=>handleShowOneTimeLoginLinkModal()} header="Need help logging in?">
                <OneTimeLoginLinkForm closeModal={handleShowOneTimeLoginLinkModal} />
            </Modal>
        </>

    );
}

export default LoginForm;