import { Col, Row, Container } from "react-bootstrap";
import Sections from "../../../sections/sections";

const RegisterPage = () => {

    return (
        <Container fluid>
            <Row className="vh-100 d-flex justify-content-center">
                <Col md={6} lg={6} xs={12}  className="d-flex" style={{alignItems: "center"}}>
                    <Sections.Auth.RegisterForm />
                </Col>
                <Col md={6} lg={6} className="d-none d-md-flex text-center" style={{alignItems: "center", background: "#f6f6f6"}}>
                    <img src="/images/tio-map.png" style={{maxWidth: "90%", maxHeight: "600px", margin: "0 auto"}} />
                </Col>   
            </Row>
        </Container>
    );
}

export default RegisterPage;