import './StatusBadge.css';
import { Icon } from '../components';

const StatusBadge = (props) => {
    const status = props.status || "N/A";

    const statuses = {
        "new": {
            color: '#6dbc57'
        },
        "cancelled": {
            color: 'red',
        },
        "paused": {
            color: '#bbb',
        },
        "enabled" : {
            fill: true,
            color: '#5a5'
        },
        "pending" : {
            fill: true,
            color: '#777',
            icon: 'Clock'
        }        
    }

    const badgeColor = statuses[status]?.color ? statuses[status].color : '#555';

    return (
        
        // <span className={"status-badge" + (props?.small ? " status-badge-small" : props?.smaller ? " status-badge-smaller" : "") + props?.className ? " " + props.className : ""} style={{
        <div 
            className={`status-badge ${props?.small ? " status-badge-small" : props?.smaller ? " status-badge-smaller" : ""} ${props?.className ? " " + props.className : ""}`}
            style={{
                color: badgeColor ? badgeColor : "#fff", 
                borderColor: badgeColor ? badgeColor : "#eee",
                ...(props?.onClick) && {cursor: "pointer"}
            }}
            onClick={props?.onClick}
        >
            {statuses[status]?.icon ? <Icon iconName={statuses[status].icon} /> : ""} {status ? <span style={{flexGrow: 1, textAlign: "center"}}>{status}</span> : <small>N/A</small>}
        </div>
    );
}

export default StatusBadge;