import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Table, Form, InputGroup, Button, Dropdown, Col, Row, Container, DropdownButton, Pagination, Stack } from 'react-bootstrap';
import { Icon, StatusBadge, EditableTextField, DropdownMenu, Value } from '../../../components/components.jsx';
import * as Utils from '../../../utils/common.js';
import { enableAd, disableAd, editAd, archiveAd } from '../../../api/actions';
import dayjs from 'dayjs';

import '../CampaignsTable/CampaignsTable.css';

const CampaignDataTable = ({ data, search, refreshCampaign, editAdHandler, openDrawerHandler, openModalHandler, campaign }) => {
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);

    const { addNotification } = useStateContext();

    if(!(data && Array.isArray(data) && data.length > 0)) {
        // return (<p>No data yet</p>)
        data = [];
    }

    // Sort the data based on the selected column and direction
    const sortedData = [...data].sort((a, b) => {
        if (sortColumn) {
            const aValue = a[sortColumn];
            const bValue = b[sortColumn];
            if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });

    // Filter the data based on the search term
    const filteredData = sortedData.filter((item) => 
        Object.values(item).some((value) => {
            if(value === null) return false;
            return value.toString().toLowerCase().includes(searchTerm ? searchTerm.toLowerCase() : "") || false
        })
    );

    // Get current items based on pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Handle sorting when a column header is clicked
    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    // Handle selecting/deselecting rows
    const handleRowSelect = (row) => {
        if (selectedRows.includes(row)) {
            setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== row));
        } else {
            setSelectedRows([...selectedRows, row]);
        }
    };

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle items per page change
    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
    };

    const handleEditAd = async (adId, newData) => {

        try {
            let isChanged = false;
            let ad = data.filter(row=>(row.id === adId))[0];
    
            Object.keys(newData).forEach(key=>{
                if(newData[key] !== ad[key]) isChanged = true;
            })
    
            if(isChanged) {
                await editAd(adId, newData);
                addNotification({title: "Ad", content: "Changed URL"});
                refreshCampaign();
            }
        } catch (error) {
            addNotification({title: "Ad", content: "There was an error"});
        }

    }

    const handleEnableAd = async (adId) => {
        try {
            const result = await enableAd(adId);
            if(result.error) {
                addNotification({title: "Ad", content: result.error});
            } else {
                addNotification({title: "Ad", content: "Ad enabled successfully"});
                refreshCampaign();
            }            
        } catch (error) {
            console.log(error);
        }
    }

    const handleDisableAd = async (adId) => {
        try {
            const result = await disableAd(adId);
            if(result.error) {
                addNotification({title: "Ad", content: result.error});
            } else {
                addNotification({title: "Ad", content: "Ad disabled successfully"});
                refreshCampaign();
            }     
        } catch (error) {
            console.log(error);
        }
    }

    const handleArchiveAd = async (adId) => {
        try {
            const result = await archiveAd(adId);
            if(result.error) {
                addNotification({title: "Ad", content: result.error});
            } else {
                addNotification({title: "Ad", content: "Ad archived successfully"});
                refreshCampaign();
            }     
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    }

    // Calculate total number of pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const tableColumns = [ 
        {
            id: "url",
            label: "URL",
            colSize: "80px"
        },
        {
            id: "status",
            label: "Status",
            colSize: "50px"
        },
        {
            id: "views",
            label: "Impressions",
            colSize: "50px"
        },
        {
            id: "value",
            label: "Spent",
            colSize: "50px"
        },
        {
            id: "avgBid",
            label: "Avg. Bid",
            colSize: "50px"
        },
        {
            id: "conversions",
            label: "Conversions",
            colSize: "50px"
        },
    ];

    return (
    <>


    <Container fluid style={{fontSize: "0.8rem"}}>
        <Row>
            <Col className="px-lg-0">
                <Stack direction="horizontal" className="my-2" style={{flexWrap: "wrap", gap: "14px"}}>
                    <Stack direction="horizontal">
                        <Form.Control onChange={handleChangeSearchTerm} placeholder="Type to filter..." style={{background: "var(--bg-tone)", maxWidth:"500px", border: 0, boxShadow: "none", padding: "4px 6px", color: "var(--first-color-l1"}}></Form.Control>
                        {campaign.archived === 0 && <Button className="ms-2 text-primary" onClick={()=>openModalHandler("addUrl")} style={{whiteSpace: "nowrap", padding: "4px 6px", borderRadius: "6px", background: "var(--bg-tone)", border: "0"}}><Icon iconName="PlusLg"/>Add URL</Button>}
                    </Stack>
                    <div className="ms-1 ms-md-auto">
                        <div className="me-3" style={{display: "flex", gap:"10px"}}>
                            {totalPages > 1 && currentPage > 2 ? <span className="text-primary pointer disable-text-selection" onClick={() => handlePageChange(1)}>{`<<`}</span> : ""}
                            {totalPages > 1 && currentPage > 1 ? <span className="text-primary pointer disable-text-selection" onClick={() => handlePageChange(prev=>prev-1)}>{`<`}</span> : ""}
                            {currentPage * itemsPerPage - itemsPerPage + 1}
                            - 
                            {(currentPage * itemsPerPage) + currentItems.length - itemsPerPage} of {filteredData.length}
                            {totalPages > 1 && currentPage < totalPages ? <span className="text-primary pointer disable-text-selection" onClick={() => handlePageChange(prev=>prev+1)}>{`>`}</span> : ""}
                            {totalPages > 1 && currentPage < totalPages - 1 ? <span className="text-primary pointer disable-text-selection" onClick={() => handlePageChange(totalPages)}>{`>>`}</span> : ""}
                        </div>
                    </div>

                    <Dropdown className="ms-lg-1">
                        <Dropdown.Toggle variant="span" id="dropdown-items-per-page" className="text-primary" style={{fontSize: "1em", borderWidth: 0, padding: "2px 1px"}}>
                            Per Page: {itemsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {[10,25,100,500].map((value)=>(<Dropdown.Item onClick={() => handleItemsPerPageChange(value)}>{value}</Dropdown.Item>))}
                        </Dropdown.Menu>
                    </Dropdown> 
                </Stack>
            </Col>
        
        </Row>
    </Container>

    <div style={{overflow: "auto"}}>
            <Table id="campaignsTable" hover size="sm" className="pb-0 m-0"> 
                <thead style={{whiteSpace: "nowrap"}}>
                    <tr>
                        <th style={{width: "1%"}}></th>
                        {tableColumns.map((column,index)=>(
                            <th key={column.id} className={index===0 ? "text-start" :""} style={{width: column.colSize, fontWeight: "400"}} onClick={() => handleSort(column.id)}>
                                {Utils.toSentenceCase(column.label)}
                                {sortColumn === column.id && (
                                    <Icon iconName={`Arrow${sortDirection === 'asc' ? 'Up' : 'Down'}`} />
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody style={{verticalAlign: 'middle'}}>
                {currentItems.map((row, index) => (
                    <tr
                    key={index}
                    className={selectedRows.includes(row) ? 'table-primary' : ''}
                    >
                        <td
                            // onClick={() => handleRowSelect(row)}
                        ></td>
                        <td key={index} className="text-start">
                            <Stack direction="horizontal" gap={2}>
                                <DropdownMenu className="hide-dropdown-arrow" label={<Icon iconName="ThreeDotsVertical" />}>
                                    <Dropdown.ItemText style={{fontSize: "0.7rem"}}>
                                        <span>Ad ID</span> <b>{row["id"]}</b><br/>
                                        Created {dayjs(row["createTime"]).format("MMMM D, YYYY")}
                                    </Dropdown.ItemText>
                                    <Dropdown.Divider />
                                    <Dropdown.ItemText>
                                        <a target="_blank" href={row.url}><Icon iconName="Link" size="1.2em" /> Open URL</a>
                                    </Dropdown.ItemText>
                                {/*row.moderationStatus === "approved" && */ ['new','enabled','disabled'].includes(row.status) 
                                    ? row.status==="disabled" 
                                        ? <Dropdown.Item onClick={()=>handleEnableAd(row.id)}><Icon iconName="Play" size="1.2em"></Icon> Enable ad</Dropdown.Item>
                                        : <Dropdown.Item onClick={()=>handleDisableAd(row.id)}><Icon iconName="Pause" size="1.2em"></Icon> Disable ad</Dropdown.Item>
                                    : ""}
                                    <Dropdown.Item onClick={()=>handleArchiveAd(row.id)}><Icon iconName="Archive" size="1.2em"></Icon> Archive ad</Dropdown.Item>
                                </DropdownMenu>

                                <span style={{display: "inline-block", minWidth: "250px"}}>
                                    <span className="text-primary w-100" style={{display: "inline-block", wordBreak: "break-all"}}><EditableTextField onSave={(e)=>handleEditAd(row.id,{url: e})} value={row.url} /></span>
                                    {row.moderationStatus === "rejected" ? <small className="text-danger">Rejected by moderation</small> : ""}
                                    {row.status === 'blocked'
                                        ? <small className="text-secondary">URL is blocked</small>
                                        : row.moderationStatus === "pending" ? 
                                            <small className="text-secondary">Pending moderation</small> 
                                            : ""
                                    }
                                </span>
                            </Stack>
                        </td>
                        <td style={{whiteSpace: "nowrap", width: "1%"}}>
                            {row.archived 
                                ? "Archived"
                                : <StatusBadge status={row.status} onClick={()=>row.status==="disabled" ? handleEnableAd(row.id) : row.status==="enabled" ? handleDisableAd(row.id) : false}/> 
                            }
                        </td>
                        <td className="text-center"><Value type="number" value={row.views} /></td>
                        <td className="text-center"><Value type="monetary" value={row.value} symbol="$" decimals="4" /></td>
                        <td className="text-center">${row.views && row.value ? Utils.formatNumber(row.value / row.views, 5) : 0}</td>
                        <td className="text-center"><Value type="number" value={row.conversions} /></td>
                    </tr>
                ))}
                    <tr className="fw-bold">

                        <td></td>
                        <td>Total</td>
                        <td></td>
                        <td className="text-center"><Value className="fw-bold" type="number" value={(searchTerm ? currentItems : filteredData).reduce((acc,cur)=>(acc+=cur.views || 0),0)} /></td> 
                        <td className="text-center"><Value className="fw-bold" type="monetary" symbol="$" decimals="2" value={(searchTerm ? currentItems : filteredData).reduce((acc,cur)=>(acc+=cur.value || 0),0)} /></td> 
                        <td className="text-center">$<Value className="fw-bold" type="number" decimals="5" value={
                            (searchTerm ? currentItems : filteredData).reduce((acc,cur)=>(acc+=cur.value || 0),0) / (searchTerm ? currentItems : filteredData).reduce((acc,cur)=>(acc+=cur.views || 0),0.1)
                        } /></td> 
                        <td className="text-center"><Value className="fw-bold" type="number" value={(searchTerm ? currentItems : filteredData).reduce((acc,cur)=>(acc+=cur.conversions || 0),0)} /></td>            
                    </tr>
                </tbody>
            </Table>
        </div>
        <Container fluid>
            <Row>
                <Col>
                    {selectedRows.length > 0 && (
                    <>
                        Selected Rows: {selectedRows.length}
                        <Button variant="danger" className="ms-2" onClick={() => setSelectedRows([])}>
                            Clear Selection
                        </Button>                        
                    </>
                    )}
                </Col>
            </Row>
        </Container>

        </>
    );
}

export default CampaignDataTable;