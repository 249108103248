import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import validator from 'validator';
import { useAuth } from '../../../auth/AuthProvider';
import { Button, Card, Form, Modal } from "react-bootstrap";
import TextInput from '../../../components/TextInput/TextInput';
import { forgotPassword } from '../../../api/actions';

const ForgotPasswordForm = ({closeModal}) => {

    const navigate = useNavigate();
    const params = useParams();
    const auth = useAuth();
    const entity = params.entity || "user";

    const [step, setStep] = useState(1);

    const {register, handleSubmit, getValues, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });    

    const [errorText, setErrorText] = useState(false);

    const formOptions = {
        email: {
            required: "Email is required",
            validate: (v)=>(validator.isEmail(v) || "Email is invalid"),
        },
    }

    const handleError = (errors) => {
        return true;
    };

    const handleSubmission = async () => {
		
        const formData = getValues();

		const requestForgotPassword = await forgotPassword({entity, email: formData.email, inputType: "email"});
        
		if(requestForgotPassword?.error) {
			setErrorText(requestForgotPassword.error);
		} else {
            setStep(2);
        }
	};

    return (
        <>
        {step && step === 1 &&
            <div className="text-center p-3">
                <p>Type your Email address to receive a password reset link.</p>
                <div className="mb-3">
                    <Form onSubmit={handleSubmit(handleSubmission, handleError)}>
                        <Controller
                            name="email"
                            control={control}
                            rules={formOptions.email}
                            render={({field}) => (
                                <TextInput 
                                    id="email" 
                                    type="email" 
                                    floatingLabel="Email address" 
                                    placeholder="Your email address" 
                                    // defaultValue=""
                                    errorMessage={errors?.email?.message}
                                    {...field}
                                />
                            )}
                        />

                        {errorText 
                        ? <p className="text-danger">{errorText.toString()}</p>
                        : ""}
                        <div className="d-grid">
                            <Button variant="primary" type="submit">Send Reset Link</Button>
                        </div>
                    </Form>
                </div>
            </div>
        }
        {step && step === 2 &&
            <div className="text-center">
                <p>If an account with this email exists, a message from us will be received shortly.</p>
                <Button variant="primary" onClick={closeModal}>Got it</Button>
            </div>
            
            
        }
        </>

    );
}

export default ForgotPasswordForm;