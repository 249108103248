import { Col, Row, Container, Modal } from "react-bootstrap";
import Sections from "../../../sections/sections";
import { useAuth } from "../../../auth/AuthProvider";
import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getToken } from '../../../api/actions';
import { Loader } from "../../../components/components";
import { useStateContext } from '../../../contexts/ContextProvider';

const ResetPasswordPage = () => {

    const { token } = useStateContext();
    const [isValidToken, setIsValidToken] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(()=> {
        if(token) {
            getToken(token).then((result)=>{
                if(result.token && result.action === 'reset password') {
                    setIsValidToken(true);
                } else {
                    navigate("/login")
                }
            });
        } else {
            navigate("/login")
        }
    }, []);

    return (
    
        <Container fluid>
            <Row className="vh-100 d-flex justify-content-center">
                <Col md={6} lg={6} xs={12}  className="d-flex" style={{alignItems: "center"}}>
                <div style={{margin: "0 auto"}}>
                    <h2 className="fw-bold mb-2">Update your password</h2>
                    <p className="mb-4">Type in your new password</p>

                    {isValidToken 
                        ? <Sections.Auth.ResetPasswordForm token={token} />
                        : <Loader label="Loading" position="center" />
                    }
                </div>

                </Col>
                <Col md={6} lg={6} className="d-none d-md-flex text-center" style={{alignItems: "center", background: "#f6f6f6"}}>
                    <img src="/images/tio-map.png" style={{maxWidth: "90%", maxHeight: "600px", margin: "0 auto"}} />
                </Col>   
            </Row>
        </Container>
    
    );
}

export default ResetPasswordPage;