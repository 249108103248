import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../auth/AuthProvider';
import { useStateContext } from '../../../contexts/ContextProvider.js';
import useInterval from '../../../utils/useInterval.js';
import useIsActiveWindow from '../../../utils/useIsActiveWindow.js';

import { Icon, DatePicker, Tooltip, TrafficByTimeChart, ChartPlaceholder, DropdownMenu, Loader } from '../../../components/components';
import QuickActions from '../../../containers/QuickActions/QuickActions';
import { Container, Row, Col, OverlayTrigger, Popover, Dropdown} from 'react-bootstrap';
import * as Utils from '../../../utils/common.js';

import { getCampaigns, getCampaignsChartByAdvertiserId, getCampaignsStatsByAdvertiserId } from '../../../api/actions';
import { AUTO_REFRESH_CAMPAIGNS_INTERVAL_MS, timeGroupsByTimeframe } from '../../../config.js';

import PagePreferences from '../../../utils/pagePreferences.js';
import Sections from '../../../sections/sections';

const CampaignsPage = () => {

    const {profile} = useAuth();

    const [campaigns, setCampaigns] = useState(false);
    const [chart, setChart] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedTimeRange, setSelectedTimeRange] = useState(false); // useState({from: new Date(), to: new Date()});
    const [searchTerm, setSearchTerm] = useState();
    const [chartMessage, setChartMessage] = useState("");
    const [todos, setTodos] = useState([]);
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [currentlyLoadingData, setCurrentlyLoadingData] = useState(false);
    const [chartOptions, setChartOptions] = useState(false);
    const [statsOptions, setStatsOptions] = useState(false);
    const [chartTimeGroups, setChartTimeGroups] = useState(["day"]);

    const { addNotification } = useStateContext();

    const isActiveWindow = useIsActiveWindow();

    useInterval(async () => {
        if(autoRefresh && isActiveWindow && !currentlyLoadingData) {
            if(campaigns?.rows?.length > 0) {
                // await loadCampaigns();
                await loadDataForTimeRange(["campaigns","chart"]);
            }
        }
    }, profile?.autoRefreshInterval || AUTO_REFRESH_CAMPAIGNS_INTERVAL_MS);

    const handleSetSelectedTimeRange = async (timeframe) => {
        setChart(false);
        const {statsTimeGroup, chartSupportedTimeGroups} = timeGroupsByTimeframe(timeframe);
        let defaultChartOptions;

        setChartTimeGroups(chartSupportedTimeGroups);

        // if(!chartSupportedTimeGroups.includes(statsTimeGroup) || !chartSupportedTimeGroups.includes(chartOptions.timeGroup) || !chartOptions) {
        if(!chartSupportedTimeGroups.includes(chartOptions.timeGroup) || !chartOptions) {
            defaultChartOptions = {timeGroup: chartSupportedTimeGroups[0]};
            handleSetChartOptions(defaultChartOptions);
        }

        setStatsOptions(prev=>({...prev, timeGroup: statsTimeGroup}));
        PagePreferences.setPagePreferences({timeframe, statsOptions: {timeGroup: statsTimeGroup}});

        setSelectedTimeRange(typeof timeframe === "string" ? Utils.getDateRange(timeframe) : timeframe);
    }

    const handleSetChartOptions = (options) => {
        setChart(false)
        setChartOptions(prev=>({...prev, ...options}));
        PagePreferences.setPagePreferences({chartOptions: options});
    }    

    useEffect(()=>{
        const preferences = PagePreferences.getPagePreferences();

        let savedTimeframe = preferences?.timeframe;
        if(!!savedTimeframe) handleSetSelectedTimeRange(savedTimeframe)
        else handleSetSelectedTimeRange("Today");
        
        let loadChartOptions = preferences.chartOptions;
        if(loadChartOptions) handleSetChartOptions(loadChartOptions);
        else handleSetChartOptions({timeGroup: "hour"});

    }, []);

    const loadCampaigns = async () => {
        try {
            const data = await getCampaigns(profile.advertiserId);
            
            if(data.total > 0) {
                setCampaigns(data);
            } else if (data.total === 0) {
                addTodo("", <>You don't have any campaigns yet. <Link to="/campaigns/create"> Create a new campaign</Link></>);
                setCampaigns({rows:[],columns:[]});
            } 

            setIsLoaded(true);
        
        } catch (error) {
            console.log(error)
            return Promise.reject("error loading campaigns");
        }
    }

	useEffect(()=>{
        loadCampaigns().then(v=>{return}).catch(error=>{console.log(error)});
	},[]);

    const editRow = (campaignId, field, value) => {
        const campaignIndex = campaigns.rows.findIndex((campaign)=>(campaign[campaigns.columns.indexOf("id")] === campaignId))
        setCampaigns(prev=>{
            prev.rows[campaignIndex][campaigns.columns.indexOf(field)] = value;
            return prev;
        })
    }

    useEffect(()=>{
        if(campaigns?.rows?.length > 0) {
            loadDataForTimeRange(["campaigns","chart"]);
        } else if(campaigns?.rows?.length === 0) {
            setChart({})
        }
    }, [selectedTimeRange, isLoaded])

    useEffect(()=>{
        if(isLoaded) {
            loadDataForTimeRange(["chart"]);
        }
    }, [chartOptions]);
  

    const loadDataForTimeRange = async (components = []) => {
        setCurrentlyLoadingData(true);
        try {
            const timeRange = Utils.getDateRange(selectedTimeRange?.label);

            const options = {
                startTime: parseInt(timeRange.from.getTime() / 1000),
                endTime: parseInt(timeRange.to.getTime() / 1000),
            }

            if(components.includes("campaigns")) {
                const campaignsWithStatsData = await loadCampaignsStats({...options, timeGroup: statsOptions.timeGroup});

                if(campaignsWithStatsData.error) throw "Could not load statistics.";
                setCampaigns(campaignsWithStatsData);
            }

            if(components.includes("chart")) {
                const chartData = await getCampaignsChartByAdvertiserId(profile.advertiserId, {...options, timeGroup: chartOptions.timeGroup});
                
                if(chartData.error) {
                    setChart({})
                    throw "Could not load chart";
                }

                if(!chartData?.rows || Object.keys(chartData).length === 0) {
                    setChart({})
                    throw "There are no statistics in this time frame";
                } else {
                    setChart(chartData);
                }
            }
        } catch (error) {
            setChartMessage(error);
            setChart({});
            addNotification(error);
        }

        setCurrentlyLoadingData(false);
    }

    const loadCampaignsStats = async (options) => {

        if(!campaigns) {
            console.log("campaign not loaded yet, cant load stats")
            return;
        }

        const statsData = await getCampaignsStatsByAdvertiserId(profile.advertiserId, {...options, groupBy: "campaign_id"});
        if(statsData.error) {
            return {error: statsData.error}
        }

        const combinedData = campaigns?.rows?.map((campaign) => { // Show all ads even if they dont have stats
            const campaignStats = statsData?.rows?.find(stat=>stat[statsData.columns.indexOf("campaign_id")] === campaign[campaigns.columns.indexOf("id")]);
            
            const resetKeys = ["views","clicks","cost","value","conversions"];
            const campaignInfo = campaign
                .map((value, index)=>(resetKeys.indexOf(campaigns.columns[index]) !== -1 ? 0 : value))
 
            if(campaignStats) {
                statsData?.columns?.forEach((column)=>{campaignInfo[campaigns.columns.indexOf(column)] = parseFloat(campaignStats[statsData?.columns?.indexOf(column)])})
            }                
  
            return campaignInfo;
        });

        return {columns: campaigns.columns, rows: combinedData};
    }

    const addTodo = (type, content) => {
        setTodos((prevTodos)=>({...prevTodos, [type]:[content]}))
    }

    useMemo(()=>{
        setTodos([]);

        const balance = profile.accounts.advertiser.find((acc=>acc.id===profile.advertiserId)).balance;
        if(balance !== undefined && balance < 1 && campaigns?.rows  && campaigns.rows.length > 0) {
            addTodo("Advertiser balance", <>Your balance (${Utils.formatNumber(balance,2)}) is insufficient for running campaigns. <Link to="/account/top-up">Top up</Link></>);
        }
        
        if(campaigns && campaigns?.rows?.length === 0) {
            addTodo("No campaigns",<>You don't have any campaigns yet. <Link to="/campaigns/create">Create your first campaign</Link></>)
            setChart({});
            setChartMessage("No information yet")
        }

    }, [campaigns])

    return (
        <>  
            <Container fluid className="p-0">
                <Row>
                    <Col>
                    {Object.keys(todos).length > 0 &&
                        <div className="p-2 text-start my-2" style={{border: "2px #ff6c00 dashed", borderRadius: "10px"}}>
                            <ul className="mb-0 ps-3">
                                {Object.keys(todos).map(key=>(<li className="mb-0">{todos[key]}</li>))}
                            </ul>
                        </div>
                    }      
                    </Col>
                </Row>
                <Row className="gx-lg-3">
                    <Col lg={12}>
                        <div className="my-2 mt-md-1" style={{display: "flex", flexWrap: "wrap"}}>
                            <QuickActions className="my-2 my-lg-0 me-auto">
                                <Link to="/campaigns/create"><Icon iconName="PlusLg" size="22" /> New campaign</Link>
                                <Link to="/campaigns/archived"><Icon iconName="Archive" size="22" /> View archived</Link>
                            </QuickActions> 
                               
                        </div>

                        <div style={{display: "flex", flexDirection: "row", fontSize: "0.9rem", flexWrap: "wrap"}}>
                            <div className="timeframe-button disable-text-selection">
                                <DropdownMenu
                                    id="dropdown-item-button" 
                                    variant="span"
                                    as="span"
                                    size="sm" 
                                    style={{display: "inline-block", verticalAlign: "bottom"}}
                                    label={
                                        <span style={{fontSize: "0.9rem", verticalAlign: "inherit"}}>
                                            <span className="me-1 black">Showing data for</span>
                                            <span className="text-primary fw-bold">
                                                {Utils.capitalizeString(selectedTimeRange.label)}
                                            </span>
                                        </span>
                                    }>
                                    {["Today", "Yesterday", "Last 7 days", "This week", "Last week", "This month", "Last month", "This year", "Last year", "All time"]
                                        .map((timeframe,i)=>(<Dropdown.Item onClick={()=>handleSetSelectedTimeRange(timeframe)} value={timeframe} style={{fontSize: "0.8rem"}}>{timeframe}</Dropdown.Item>))}
                                </DropdownMenu>
                            </div>
                            <div className="position-relative ms-lg-auto">
                                <DropdownMenu 
                                    id="dropdown-item-button" 
                                    as="span" 
                                    size="sm" 
                                    style={{color: "black", background: "#ffffff90", borderRadius: "3px"}} 
                                    label={<span className="text-secondary" style={{fontSize: "0.9rem"}}>{Utils.capitalizeString({minute: "minute", hour: "hourly", day: "daily", month: "monthly"}[chartOptions?.timeGroup || "hour"])} chart</span>}
                                    >
                                    {chartTimeGroups.includes("minute") ? <Dropdown.Item style={{fontSize: "0.8rem"}} onClick={()=>handleSetChartOptions({timeGroup: "minute"})}>Minute chart</Dropdown.Item> : ""}
                                    {chartTimeGroups.includes("hour") ? <Dropdown.Item style={{fontSize: "0.8rem"}}onClick={()=>handleSetChartOptions({timeGroup: "hour"})}>Hourly chart</Dropdown.Item> : ""}
                                    {chartTimeGroups.includes("day") ? <Dropdown.Item style={{fontSize: "0.8rem"}}onClick={()=>handleSetChartOptions({timeGroup: "day"})}>Daily chart</Dropdown.Item> : ""}
                                    {chartTimeGroups.includes("month") ? <Dropdown.Item style={{fontSize: "0.8rem"}}onClick={()=>handleSetChartOptions({timeGroup: "month"})}>Monthly chart</Dropdown.Item> : ""}
                                </DropdownMenu> 
                            </div>
                        </div>

                        <Container fluid className="p-0 mb-1">
                            <Row>
                                <Col lg={12}>
                                    <div className="position-relative px-1 py-3" style={{height: "240px", overflow: "hidden"}}>
                                        {chart 
                                            ? chart?.rows
                                                ? <TrafficByTimeChart data={chart} chartOptions={chartOptions}/>
                                                : <ChartPlaceholder message={chartMessage} />
                                            : <div style={{height:"240px"}}> <Loader label="Loading chart" position="center" /> </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container> 
                    </Col>
                </Row>
            </Container>
            <Container fluid className="p-0">
                <Row className="gx-lg-1">
                    <Col className="p-0 p-lg-1">
                        {isLoaded ?
                            campaigns?.rows ? <Sections.Campaigns.CampaignsTable data={campaigns} search={searchTerm} editRow={editRow}/> : <Loader label="Loading campaigns" />
                            : <Loader label="Loading campaigns" />
                        }
                    </Col>
                </Row>
            </Container>
        </>
        
    );
}

export default CampaignsPage;