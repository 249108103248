import { Accordion, Form } from 'react-bootstrap';
import './ExpandableBox.css';

const ExpandableBox = ({children, ...props}) => {

    return (
        <>
        {props.label 
        ? <Form.Label className="text-start fw-bold w-100">{props.label}</Form.Label> 
        : ""}

        {props.description
            ? <Form.Text className="text-input-description ms-1 mb-2" muted>{props.description}</Form.Text>
            : ""}       

        <Accordion className="styledAccordion" defaultActiveKey={props.collapsed ? 0 : 1} {...props}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>{props.header}</Accordion.Header>
                <Accordion.Body>
                    {children}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        </>

    );
}

export default ExpandableBox;