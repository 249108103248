import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider.js';
import { enableCampaign, disableCampaign} from '../../../api/actions.js';
import { Table, Form, InputGroup, Button, Dropdown, Col, Row, Container, DropdownButton, Pagination, Stack, ProgressBar } from 'react-bootstrap';
import { Icon, StatusBadge, DropdownMenu, Value } from '../../../components/components';
import * as Utils from '../../../utils/common.js';
import '../CampaignsTable/CampaignsTable.css';

const ArchivedCampaignsTable = ({ data, editRow }) => {
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [dataType, setDataType] = useState("datatable");

    const { addNotification } = useStateContext();

    if(!(data && data.rows.length > 0)) {
        data = {columns: data.columns, rows: []};
    } 

    // Sort the data based on the selected column and direction
    const sortedData = [...data.rows].sort((a, b) => {
        if (sortColumn) {
            const aValue = a[data.columns.indexOf(sortColumn)];
            const bValue = b[data.columns.indexOf(sortColumn)];
            if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });

    // Filter the data based on the search term
    const filteredData = sortedData.filter((item) => 
        Object.values(item).some((value) => {
            if(value === null) return false;
            return value.toString().toLowerCase().includes(searchTerm ? searchTerm.toLowerCase() : "") || false
        })
    );

    // Get current items based on pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Handle sorting when a column header is clicked
    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('desc');
        }
    };

    // Handle selecting/deselecting rows
    const handleRowSelect = (row) => {
        if (selectedRows.includes(row)) {
            setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== row));
        } else {
            setSelectedRows([...selectedRows, row]);
        }
    };

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle items per page change
    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
    };

    const handleChangeSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    }

    // Calculate total number of pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const tableColumns = [ 
        {
            id: "label",
            label: "Campaign",
            colSize: "400px"
        },
        {
            id: "status",
            label: "Status",
            colSize: "50px"
        },
        {
            id: "bid",
            label: "Bid",
            colSize: "50px"
        },
        {
            id: "value",
            label: "Spent",
            colSize: "50px"
        },
        {
            id: "views",
            label: "Impressions",
            colSize: "50px"
        },
        {
            id: "clicks",
            label: "Clicks",
            colSize: "50px"
        },
        {
            id: "conversions",
            label: "Conversions",
            colSize: "50px"
        },
        {
            id: "budget",
            label: "Daily Budget",
            colSize: "50px"
        },
        {
            id: "create_time",
            label: "Created",
            colSize: "80px"
        },
    ];

    const col = (columnName) => dataType === "datatable" ? data.columns.indexOf(columnName) : columnName;

    return (
    <>

    <Container fluid style={{fontSize: "0.8rem"}}>
        <Row>
            <Col className="col px-0">
                <Stack direction="horizontal" className="my-2" style={{flexWrap: "wrap", gap: "5px"}}>
                    <Form.Control onChange={handleChangeSearchTerm} placeholder="Search archived campaigns..." style={{maxWidth:"500px", border: 0, boxShadow: "none", padding: "4px 6px", color: "var(--first-color-l1", background: "var(--bg-tone)"}}></Form.Control>

                    <div className="ms-auto">
                        <span className="me-3">
                            {currentPage * itemsPerPage - itemsPerPage + 1}
                            - 
                            {(currentPage * itemsPerPage) + currentItems.length - itemsPerPage} of {filteredData.length}
                        </span>
                    </div>
                    <div>
                        {totalPages > 1 &&  
                            <div style={{display: "inline-block", textAlign: "end", fontSize: "0.8rem"}}>
                            <Pagination className="mb-0" size="sm">
                                {currentPage > 4 ? <Pagination.Prev onClick={() => handlePageChange(currentPage-1)} /> : ""}
                                {currentPage > 1 ? <Pagination.Item onClick={() => handlePageChange(1)}>{1}</Pagination.Item> : ""}
                                {currentPage - 2 > 2 ? <Pagination.Ellipsis /> : ""}
                                {currentPage - 1 > 1 ? <Pagination.Item onClick={() => handlePageChange(currentPage-1)}>{currentPage-1}</Pagination.Item> : ""}
                                <Pagination.Item active>{currentPage}</Pagination.Item> 
                                {currentPage + 1 < totalPages ? <Pagination.Item onClick={() => handlePageChange(currentPage+1)}>{currentPage+1}</Pagination.Item> : ""}
                                {currentPage + 2 < totalPages ? <Pagination.Ellipsis /> : ""}
                                {currentPage !== totalPages ? <Pagination.Item onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item> : ""}
                                {currentPage < totalPages ? <Pagination.Next onClick={() => handlePageChange(currentPage+1)} />: ""}
                            </Pagination>
                            </div>
                        } 
                    </div>
                
                    <Dropdown className="ms-2">
                        <Dropdown.Toggle variant="span" id="dropdown-items-per-page" className="text-primary" style={{fontSize: "1em", borderWidth: 0, padding: "2px 1px"}}>
                            Per Page: {itemsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {[10,25,100,500].map((value)=>(<Dropdown.Item onClick={() => handleItemsPerPageChange(value)}>{value}</Dropdown.Item>))}
                        </Dropdown.Menu>
                    </Dropdown> 
                </Stack>
            </Col>
            <Col md={6} className="text-end px-0 d-none">
                <InputGroup className="mb-1" style={{maxWidth: "500px", width: "100%"}}>
                    <Form.Control 
                        id="search"
                        floatingLabel="Search campaigns"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(1);
                        }}
                        type="text" 
                        placeholder="Search archived campaigns" 
                        style={{backgroundColor: "var(--bg-tone)"}}/>
                </InputGroup> 
            </Col>            
        </Row>
    </Container>

    <div style={{overflow: "auto", border: "2px #eee solid", borderWidth: "2px", borderRadius: "10px"}}>
            <Table id="campaignsTable" className="mb-0" hover size="sm"> 
                <thead style={{whiteSpace: 'nowrap'}}>
                    <tr>
                        <th style={{width: "1%"}}></th>
                        {tableColumns.map((column, index)=>(
                            <th key={column.id} style={{width: column.colSize, textAlign: index === 0 ? "left" : "center"}} onClick={() => handleSort(column.id)}>
                                {Utils.toSentenceCase(column.label)}
                                {sortColumn === column.id && (
                                    <Icon iconName={`Arrow${sortDirection === 'asc' ? 'Up' : 'Down'}`} />
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody style={{verticalAlign: 'middle'}}>
                {currentItems.map((row, index) => (
                    <tr
                    key={index}
                    className={selectedRows.includes(row) ? 'table-primary' : ''}
                    >
                        <td
                            onClick={() => handleRowSelect(row)}
                        ></td>
                        <td key={index} className="text-start">
                            <Stack direction="horizontal" gap={2}>
                                <DropdownMenu className="hide-dropdown-arrow" label={<Icon iconName="ThreeDotsVertical" className="m-2"/>}>
                                    <Dropdown.Item href="#/action-1"><Link to={`/campaigns/view/${row[col("id")]}`}><Icon iconName="Eye" size="22" /> View campaign</Link></Dropdown.Item>
                                    <Dropdown.Item href="#/action-3"><Icon iconName="Copy" size="22"/> Clone</Dropdown.Item>
                                </DropdownMenu>
                                <span>
                                    <Link to={`/campaigns/view/${row[col("id")]}`}>{row[col("label")] ? <b>{row[col("label")]}</b> : 'Unnamed campaign'}</Link>
                                    <br/><span className="text-secondary small">{Utils.capitalizeString(row[col("format")])} Campaign ID {row[col("id")]}</span> 
                                </span>
                            </Stack>
                        </td>
                        <td style={{whiteSpace: "nowrap", width: "1%"}}>
                            {row[col("status")] ? <StatusBadge status={row[col("archived")] === 1 ? "Archived" : row[col("status")]} /> : ""}
                        </td>
                        <td>
                            {row[col("bid_type")] ? `$${Utils.formatNumber(Number(row[col("bid")]))} ${row[col("bid_type")].toUpperCase()}` : ''}
                        </td>
                        <td>${Utils.formatNumber(row[col("value")])}</td>
                        <td>{Utils.formatNumber(row[col("views")])}</td>
                        <td>{Utils.formatNumber(row[col("clicks")])}</td>
                        <td>{Utils.formatNumber(row[col("conversions")])}</td>
                        <td>{row[col("daily_budget")] ? `$${row[col("daily_budget")]}` : ''}</td>
                        <td>
                            <span title={row[col("create_time")]}>{Utils.timeAgo(row[col("create_time")])}</span>
                        </td>                          
                    </tr>
                ))}
                    <tr className="fw-bold">

                        <td></td>
                        <td className="text-start">Total</td>
                        <td></td>
                        <td></td>
                        <td className="text-center">${Utils.formatNumber(currentItems.reduce((acc,cur)=>(acc+=cur[col("value")] || 0),0),2)}</td> 
                        <td className="text-center">{Utils.formatNumber(currentItems.reduce((acc,cur)=>(acc+=cur[col("views")] || 0),0))}</td> 
                        <td className="text-center">{Utils.formatNumber(currentItems.reduce((acc,cur)=>(acc+=cur[col("clicks")] || 0),0))}</td> 
                        <td className="text-center">{Utils.formatNumber(currentItems.reduce((acc,cur)=>(acc+=cur[col("conversions")] || 0),0))}</td> 
                        <td></td> 
                        <td></td>                      
                    </tr>
                </tbody>
            </Table>
        </div>
        <Container fluid>
            <Row>
                <Col>
                    {selectedRows.length > 0 && (
                    <>
                        Selected Rows: {selectedRows.length}
                        <Button variant="danger" className="ms-2" onClick={() => setSelectedRows([])}>
                            Clear Selection
                        </Button>                        
                    </>
                    )}
                </Col>
            </Row>
        </Container>

        </>
    );
}

export default ArchivedCampaignsTable;