import { useState } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useForm, Controller } from "react-hook-form";
import validator from 'validator';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Form, Alert } from "react-bootstrap";

import { useAuth } from '../../../auth/AuthProvider';

import TextInput from '../../../components/TextInput/TextInput';
import './RegisterForm.css';

const RegisterForm = () => {

    const navigate = useNavigate();
    const auth = useAuth();

    const {register, handleSubmit, getValues, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });
    
    const formOptions = {
        name: {
            required: "Required"
        },
        phone: {},
        email: {
            required: "Required",
            validate: (v)=>(validator.isEmail(v) || "Email is invalid"),
        },
        password: {
            required: "Password is required, Duh :)",
            minLength: {
                value: 1,
                message: "Password is too short."
            }
        },
        agree: {
            // required: "You must agree to our terms in order to register",
            validate: (v)=>(v || "You must agree to our terms in order to register"),
        }
    }

    const handleError = (errors) => {console.log("Errors in form", errors)};
    const [errorText, setErrorText] = useState(false);

	const handleSubmission = async () => {
        const formData = getValues();

		const user = await auth.register({
            email: formData.email, 
            firstName: formData.name, 
            phone: formData.phone, 
            password: formData.password, 
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            defaultAccountType: 'advertiser'
        });

		if(user?.error) {
			return setErrorText(user.error);
		}
        
		if(user?.id) {
			const user = await auth.login({entity: "user", email: formData.email, password: formData.password});
			navigate('/', { replace: true });
		} else {
			return setErrorText('An error occured');
		}
	};

    return (
            <div className="mb-3 mt-3 mt-lg-1" style={{maxWidth: "550px", margin: "0 auto"}}>
                <h2 className="fw-bold mb-2">Create your account</h2>
                <p className=" mb-4">Fill out your details and get started!</p>
                <div className="mb-3">
                    <Form onSubmit={handleSubmit(handleSubmission, handleError)}>
                        <Controller
                            name="name"
                            control={control}
                            rules={formOptions.name}
                            render={({field}) => (    
                            <TextInput 
                                id="name" 
                                type="text" 
                                floatingLabel="Your name" 
                                placeholder="Your name"
                                defaultValue=""
                                errorMessage={errors?.name?.message}
                                {...field}
                            />
                            )}
                        />
                        <Controller
                            name="email"
                            control={control}
                            rules={formOptions.email}
                            render={({field}) => (    
                                <TextInput 
                                    id="email" 
                                    type="email" 
                                    floatingLabel="Email address" 
                                    placeholder="Your email address" 
                                    defaultValue=""
                                    errorMessage={errors?.email?.message}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="phone"
                            control={control}
                            rules={formOptions.phone}
                            render={({field}) => (                            
                                <TextInput 
                                    id="phone" 
                                    type="text" 
                                    floatingLabel="Phone number" 
                                    placeholder="Your phone number" 
                                    defaultValue=""
                                    errorMessage={errors?.phone?.message}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            rules={formOptions.password}
                            render={({field}) => (
                                <TextInput 
                                    id="password" 
                                    type="password" 
                                    floatingLabel="Password" 
                                    placeholder="Your password" 
                                    defaultValue=""
                                    errorMessage={errors?.password?.message}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="agree"
                            control={control}
                            rules={formOptions.agree}
                            render={({field}) => (
                                <div className="my-3 text-start">
                                    <input 
                                    id="agree" 
                                    type="checkbox" 
                                    defaultValue=""
                                    errorMessage={errors?.agree?.message}
                                    style={{borderWidth: "2px", borderColor: "black"}}
                                    className={`form-check-input ${errors?.agree ? 'is-invalid' : ''}`}
                                    {...field}
                                        />
                                    <label htmlFor="agree" className="ms-2 form-check-label">Accept Terms & Conditions</label>

                                </div>
                            
                            )}
                        />

                        {errorText 
                        ? <p className="text-danger">{errorText.toString()}</p>
                        : ""}
                        <div className="d-grid">
                            <Button variant="primary" type="submit">Get started</Button>
                        </div>
                    </Form>
                    <div className="mt-3">
                        <p className="mb-0 text-center">
                            Already have an account?{' '}
                            <Link to="/login"><a className="text-primary fw-bold">Login</a></Link>
                        </p>
                    </div>
                </div>
            </div>
    );
}

export default RegisterForm;