export const BACKEND_URL = 'https://main.traffics.io';
// export const BACKEND_URL = 'http://localhost:99';
export const WEBSITE_URL = 'https://traffics.io';

export const AUTO_REFRESH_DASHBOARD_INTERVAL_MS = 10000;
export const AUTO_REFRESH_PROFILE_INTERVAL_MS = 10000;
export const AUTO_REFRESH_CAMPAIGNS_INTERVAL_MS = 10000;

export const timeGroupsByTimeframe = (timeframe) => {
    let statsTimeGroup = "hour"; 
    let chartSupportedTimeGroups = [];
    
    switch(timeframe) {
        case "Today" : 
        case "Yesterday" :      chartSupportedTimeGroups = ["hour", "minute"];   statsTimeGroup = "hour"; break;
        case "Last 7 days" :
        case "This week" :
        case "Last week" :
        case "This month" :
        case "Last month" :     chartSupportedTimeGroups = ["hour", "day"];      statsTimeGroup = "hour"; break;
        case "This year" :
        case "Last year" :      chartSupportedTimeGroups = ["day", "month"];     statsTimeGroup = "day"; break;
        case "All time" :       chartSupportedTimeGroups = ["month"];            statsTimeGroup = "month"; break;
    }

    return {
        statsTimeGroup,
        chartSupportedTimeGroups
    }
}