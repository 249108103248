import { useState, useEffect } from 'react';
import { Loader, SelectList, TextInput, Icon } from '../../../components/components.jsx';
import { Container, Row, Col, Stack, Button, Table } from 'react-bootstrap';
import * as Utils from '../../../utils/common.js';
import { getCampaignUpdates } from '../../../api/actions.js';

const CampaignUpdatesSection = (props) => {

    const [loaded, setLoaded] = useState(false);
    const [updates, setUpdates] = useState([]);

    useEffect(() => {
        getCampaignUpdates(props.campaignId).then(result=>{
            if(Array.isArray(result)) {
                setUpdates([...result, {create_time: props.data.campaignCreateTime, data: "Campaign created"}]);
                setLoaded(true);
            }

        })

	},[]);

    return (  
        <>
        {loaded ?
            <div style={{fontSize: "0.9em"}}>
                {updates.length > 0 
                    ? updates.map((update, index)=>(<p className="mb-1"><span className="text-secondary" style={{whiteSpace: "nowrap", fontSize:"0.7em"}}>{Utils.localizeDateTime(update.create_time)}</span><br /><span>{Utils.capitalizeString(update.data)}</span></p>))
                    : "No updates yet"
                }

                {/* {updates.map((update, index)=>(<p><small>{Utils.timeAgo(update.create_time)}</small> <b>{Utils.capitalizeString(update.data)}</b></p>))} */}
            </div>
        : <Loader label="Loading updates" position="center" />}
        </>
    )
}

export default CampaignUpdatesSection;