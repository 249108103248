import { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';

import useInterval from '../../../../../utils/useInterval.js';
import { useAuth } from '../../../../../auth/AuthProvider';
import useIsActiveWindow from '../../../../../utils/useIsActiveWindow.js';
import { useStateContext } from '../../../../../contexts/ContextProvider';
import Fade from 'react-bootstrap/Fade';
import { AUTO_REFRESH_CAMPAIGNS_INTERVAL_MS, WEBSITE_URL, timeGroupsByTimeframe } from '../../../../../config.js';

import dayjs from 'dayjs';
import * as Utils from '../../../../../utils/common.js';
import { scheduleToText, rateLimitToText } from '../../../../../utils/options.js';
import { getCampaignById, enableCampaign, disableCampaign, editCampaign, updateCampaignDailyBudget, resetCampaignDailyBudget, updateCampaignBid, getTargetingOptionsByFormat, getCampaignChart, getCampaignStats, archiveCampaign, cloneCampaign, resetCampaignRateLimit } from '../../../../../api/actions';

import { ChartPlaceholder, TrafficByTimeChart, Modal, DatePicker, DropdownMenu, StatWidget, StatusBadge, Icon, Drawer, ProgressBar, ExpandableBox, EditableTextField, TextInput, Value, Loader } from '../../../../../components/components';
import { Container, Row, Col, Stack, Badge, Nav, Button, Table, Dropdown, DropdownButton, Popover, OverlayTrigger, Tabs, Tab, Form } from 'react-bootstrap';
import QuickActions from '../../../../../containers/QuickActions/QuickActions';

import CampaignAdsStatsTable from '../../../../../sections/Campaigns/CampaignDataTabs/CampaignAdsStatsTable';
import CampaignTargetingStatsTable from '../../../../../sections/Campaigns/CampaignDataTabs/CampaignTargetingStatsTable';

import Sections from '../../../../../sections/sections';

import PagePreferences from '../../../../../utils/pagePreferences.js';
import { getPageHistory, addPageHistory } from '../../../../../utils/pageHistory.js';

const CampaignViewPage = (props) => {

    const params = useParams();
    const {profile} = useAuth();
    
    const navigate = useNavigate();
    const currentLocation = useLocation();

    const { addNotification } = useStateContext();
    const isActiveWindow = useIsActiveWindow();

    const [campaign, setCampaign] = useState(false);
    const [campaignId, setCampaignId] = useState(false);
    const [todos, setTodos] = useState([]);
    const [formData, setFormData] = useState({});
    const [drawers, setDrawers] = useState({});
    const [modals, setModals] = useState({});    
    const [selectedTimeRange, setSelectedTimeRange] = useState(false); // useState({from: new Date(), to: new Date()});
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [chart, setChart] = useState(false);
    const [chartMessage, setChartMessage] = useState();
    const [statsSummary, setStatsSummary] = useState({});
    const [adsStats, setAdsStats] = useState([]);
    const [countriesStats, setCountriesStats] = useState([]);
    const [devicesStats, setDevicesStats] = useState([]);
    const [activeStatsTab, setActiveStatsTab] = useState("ads");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAd, setSelectedAd] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [key, setKey] = useState(1);
    const [chartOptions, setChartOptions] = useState(false);
    const [statsOptions, setStatsOptions] = useState(false);
    const [chartTimeGroups, setChartTimeGroups] = useState(["day"]);

    useInterval(async () => {
        if(autoRefresh && isActiveWindow && (true || campaign.status === "enabled")) {
            // await loadDataForTimeRange(["chart", activeStatsTab]);
            await loadCampaignData();
        }
    }, profile?.autoRefreshInterval || AUTO_REFRESH_CAMPAIGNS_INTERVAL_MS);

    const handleSetSelectedTimeRange = async (timeframe) => {
        setChart(false);
        const {statsTimeGroup, chartSupportedTimeGroups} = timeGroupsByTimeframe(timeframe);
        let defaultChartOptions;

        setChartTimeGroups(chartSupportedTimeGroups);

        if(!chartSupportedTimeGroups.includes(chartOptions.timeGroup) || !chartOptions) {
            defaultChartOptions = {timeGroup: chartSupportedTimeGroups[0]};
            handleSetChartOptions(defaultChartOptions);
        }

        setStatsOptions(prev=>({...prev, timeGroup: statsTimeGroup}));
        PagePreferences.setPagePreferences({timeframe, statsOptions: {timeGroup: statsTimeGroup}});

        setSelectedTimeRange(typeof timeframe === "string" ? Utils.getDateRange(timeframe) : timeframe);
    }

    useEffect(()=>{
        console.log("useEffect #1")
        setIsLoading(true);
        const preferences = PagePreferences.getPagePreferences();

        let savedTimeframe = preferences?.timeframe;
        if(!!savedTimeframe) handleSetSelectedTimeRange(savedTimeframe)
        else handleSetSelectedTimeRange("Today");

        let loadChartOptions = preferences.chartOptions;
        if(loadChartOptions) handleSetChartOptions(loadChartOptions);
        else handleSetChartOptions({timeGroup: "hour"});

        let activeStatsTab = PagePreferences.getPagePreferences()?.activeStatsTab || "ads";
        setActiveStatsTab(activeStatsTab);
        loadCampaignData().then(r=>{setIsLoading(false)});
    }, [campaignId]);

    useEffect(()=>{
        console.log("useEffect #2")

        if(campaignId !== params.id) {
            clearPageData();
            setCampaignId(params.id)
        }
    }, [currentLocation])
   
    useEffect(()=>{
        console.log("useEffect #3")

        if(campaign) {
            loadDataForTimeRange(["chart", activeStatsTab || "ads"]);
        }
    }, [selectedTimeRange])
    
    useEffect(()=>{
        console.log("useEffect #4")

        if(campaign && chartOptions) {
            loadDataForTimeRange(["chart"]);
        }
    }, [chartOptions]);

    const handleEnableCampaign = async () => {
        enableCampaign(campaign.id).then(result=>{
            loadCampaignData();
            addNotification("Enabled campaign")
        })
    }
    const handleDisableCampaign = async () => {
        disableCampaign(campaign.id).then(result=>{
            loadCampaignData();
            addNotification("Disabled campaign")
        })
    }    
    const handleStatsTabSelect = async (eventKey) => {
        await loadDataForTimeRange(eventKey);
        setActiveStatsTab(eventKey);
        PagePreferences.setPagePreferences({activeStatsTab: eventKey});
    }

    const clearPageData = () => {
        setCampaign(false);
        setChart(false);
        setStatsSummary({});
        setStatsOptions({});
        setActiveStatsTab(false);
        setAdsStats(false);
    }

    const updateCampaignStats = async (data) => {
        if(campaign && data.rows && Array.isArray(data.rows)) {
            let summary = data.rows.reduce((acc,cur)=>{
                for(let col in data.columns) {
                    const key = data.columns[col];
                    if(["cost","value","clicks","conversions","impressions","views"].includes(key)) {
                        if(acc[key]) acc[key] += parseFloat(cur[col]);
                        else acc[key] = parseFloat(cur[col]) || 0;
                    } else {
                        acc[key] = 0;
                    }
                }
                return acc;
            },{})
            setStatsSummary(summary);
        } else {
            setStatsSummary(["cost","value","clicks","conversions","impressions","views"].reduce((acc,cur)=>({...acc, [cur]:0}),{}))
        }
    }

    const handleEditAd = (adId) => {
        setSelectedAd(adId);
        handleDrawer("editAd", "open");
    }

    const handleArchive = async () => {
        await archiveCampaign(campaign.id);
        await loadCampaignData();
        addNotification("Archived campaign")
        handleModal("archive", "close");
    }

    const handleResetDailyBudget = async () => {
        await resetCampaignDailyBudget(campaign.id);
        addNotification("Resetting daily budget")
        loadCampaignData();
    }

    const handleClone = () => {
        cloneCampaign(campaign.id);
        addNotification("Cloning campaign")
        handleModal("clone", "close");
    }    

    const handleResetRateLimit = async () => {
        await resetCampaignRateLimit(campaign.id);
        addNotification("Resetting rate limit")
        loadCampaignData();
    }    
   
    const handleSetChartOptions = (options) => {
        setChartOptions(prev=>({...prev, ...options}));
        PagePreferences.setPagePreferences({chartOptions: options})
    }

    const loadAdsStatsTable = async (options) => {
        if(!campaign) {
            console.log("campaign not loaded yet, cant load ads stats")
            return;
        }
        const ads = campaign.ads || [];
        const statsData = await getCampaignStats(params.id, {...options, groupBy: "ad_id"});
        if(statsData.error) {
            setAdsStats(ads);
            updateCampaignStats(Utils.objectsArrayToDataTable(ads));
            setChartMessage(<span>Could not load up-to-date statistics</span>)
            
            return {error: statsData.error}
        }
        // addNotification({type: "error", content: "Error getting stats ad_id"});

        const combinedData = ads.map((ad,index)=>{ // Show all ads even if they dont have stats
            const adStats = statsData?.rows?.find(stat=>stat[statsData?.columns?.indexOf("ad_id")] === ad.id);
            const {cost, value, views, clicks, conversions, ...adInfo} = ad; // remove stats from ad level so that only relevant stats show
         
            if(adStats) {
                const normalizedAdStats = statsData?.columns?.reduce((acc,cur)=>({...acc, [cur]: parseFloat(adStats[statsData?.columns?.indexOf(cur)])}), {})

                return {
                    ...adInfo, 
                    ...normalizedAdStats
                }
            } else {
                return {
                    ...adInfo,
                    cost: 0, 
                    value: 0, 
                    views: 0, 
                    clicks: 0, 
                    conversions: 0
                };
            }            
        });

        setAdsStats(combinedData);
        updateCampaignStats(statsData);
    }

    const loadTargetingStatsTable = async (type, options) => {
        if(!campaign) console.log("campaign not loaded yet, cant load " + type + " stats")

        const targeting = campaign?.targeting?.[type] || [];
        let statsData = await getCampaignStats(params.id, {...options, groupBy: type}) || [];

        if(statsData.error) { // Need to finish this
            statsData = {};
        }

        const reportOptions = new Set([...targeting]);

        if(statsData?.rows) {
            statsData.rows.forEach(stat=>reportOptions.add(stat[statsData.columns.indexOf(type)]));
        }

        const combinedData = Array.from(reportOptions).map((item)=>{ // Show all countries even if they dont have stats
            const itemStats = statsData?.rows?.find(stat=>stat[statsData?.columns?.indexOf(type)] == item);
            if(itemStats) {
                const normalizedItemStats = statsData.columns.reduce((acc,cur)=>({...acc, [type]: Utils.capitalizeString(item), [cur]: parseFloat(itemStats[statsData.columns.indexOf(cur)])}), {});
                console.log(normalizedItemStats)

                return {...normalizedItemStats}
            } else {
                return {[type]:item, views: 0, clicks:0, conversions:0, value:0};
            }
        });

        switch(type) {
            case "country" : setCountriesStats(combinedData); break;
            case "device" : setDevicesStats(combinedData); break;
        }

        updateCampaignStats(statsData);
    }    

    const submitEditCampaign = async (data) => {
        await editCampaign({id: campaign.id, ...data});
        loadCampaignData();
    }

    const loadCampaignData = async () => {
        try {
            const data = await getCampaignById(params.id);
            
            if(!data.archived) {
                addPageHistory("campaigns", {url: currentLocation.pathname, label: data.label || "Campaign #" + data.id, id: data.id})
            }

            if(data.id) {
                setCampaign(data);
            } else {
                navigate("/", {replace:true})
            } 

        } catch (error) {
            addNotification("Error loading campaign")
            console.log(error)
            // return Promise.reject("error loading campaign")
        }
    }
    
    const loadDataForTimeRange = async (components = []) => {
        // console.log("loadDataForTimeRange")
        try {
            const timeRange = Utils.getDateRange(selectedTimeRange?.label);

            const options = {
                startTime: parseInt(timeRange.from.getTime() / 1000),
                endTime: parseInt(timeRange.to.getTime() / 1000),
            }

            if(components.includes("chart")) {
                const chartData = await getCampaignChart(params.id, {...options, timeGroup: chartOptions.timeGroup});
                if(!chartData.error && !chartData?.rows) {
                    setChartMessage(<span>No data is available for this time frame</span>)
                } 
                setChart(chartData);
            }

            if(components.includes("ads")) {
                await loadAdsStatsTable({...options, timeGroup: statsOptions.timeGroup}); 
            }

            if(components.includes("countries")) {
                await loadTargetingStatsTable("country", {...options, timeGroup: statsOptions.timeGroup})
            }

            if(components.includes("devices")) {
                await loadTargetingStatsTable("device", {...options, timeGroup: statsOptions.timeGroup})
            }
            // addNotification("Loaded")
        } catch (error) {
            addNotification("Error loading chart")
            console.log(error);
        }
    }

    const addTodo = (type, content) => {
        setTodos((prevTodos)=>({...prevTodos, [type]:[content]}))
    }

    useMemo(()=>{
        setTodos([]);

        const balance = profile.accounts.advertiser.find((acc=>acc.id===profile.advertiserId)).balance;
        if(balance !== undefined && balance <= 0.1) {
            addTodo("Advertiser balance", <>Advertiser balance (${Utils.formatNumber(balance,2)}) is insufficient for running this campaign. <Link to="/account/top-up">Top up</Link></>);
        }

        if(campaign?.id) {
            loadDataForTimeRange(["chart", activeStatsTab]);
        }

        if(campaign.archived === 1) return;
        if(campaign?.ads?.length === 0) addTodo("",<>You campaign doesn't have any URLs yet. <span className="link-primary" onClick={()=>openModal("addUrl")}>Add URL</span></>);

        if(campaign?.ads?.length > 0) {
            if(campaign.moderationStatus === "rejected") addTodo("Moderation",<span><b>Campaign has been rejected.</b><br /> Make sure your ads fit {campaign.targeting.category ? campaign.targeting.category.join(", ") + " audiences" : ""} and comply with our <a href="https://traffics.io/legal/aup/" target="_blank">Acceptable Use Policy</a></span>);
            if(campaign.moderationStatus === "pending" || campaign.moderationStatus === null) {
                addTodo("Moderation","Your campaign is being moderated. We will let you know once we're done.");
                if(campaign.statusAfterApproval && balance > 0) {
                    switch(campaign.statusAfterApproval) {
                        case "enabled": addTodo("Status after approval", <>Your campaign will be automatically enabled upon approval. <Link onClick={()=>openDrawer("statusAfterApproval")}>Change this setting</Link></>); break;
                        case "disabled": addTodo("Status after approval", <>Your campaign will be automatically disabled upon approval. <Link onClick={()=>openDrawer("statusAfterApproval")}>Change this setting</Link></>); break;
                    }
                }
            }
            // Show warning if there are no approved ads
            if(campaign.moderationStatus === "approved" && campaign.ads.filter(ad=>ad.moderationStatus === "approved" && ad.moderationStatus === "pending").length === 0 && campaign.ads.filter(ad=>ad.moderationStatus === "approved").length === 0) addTodo("Ad moderations", "Please wait while your ads are being moderated. We will let you know once we're done :)")
            if(campaign.moderationStatus === "approved" && campaign.ads.filter(ad=>ad.moderationStatus === "rejected").length === campaign.ads.length) addTodo("Ad moderations", "Your ads have been rejected by moderation.")
        } 

        if(campaign.status === 'enabled' && campaign.dailyBudget > 0 && campaign.dailyBudget <= (campaign.dailyBudgetUsed + campaign.dailyBudgetUsedAdjustment)) {
            addTodo("campaign",<>Your campaign has reached its daily budget limit. <Link onClick={()=>openDrawer("dailyBudget")}>Adjust budget</Link> or <Link onClick={()=>handleResetDailyBudget()}>Reset daily budget</Link></>)
        }

        if(campaign.status === 'enabled' && campaign.rateLimitPeriod && campaign.rateLimitCounter >= campaign.rateLimitAmount) {
            addTodo("campaign",<>Your campaign has reached its rate limit. <Link onClick={()=>openDrawer("rateLimit")}>Adjust rate limit</Link> or <Link onClick={()=>handleResetRateLimit()}>Reset rate limit for period</Link></>)
        }

        if(campaign.status === 'enabled' && !campaign.scheduled) {
            addTodo("schedule",<>Your campaign is currently out of its schedule. <Link onClick={()=>openDrawer("schedule")}>Update schedule</Link></>)
        }

        if(campaign.id && !campaign.bid) {
            addTodo("campaign",<>Your need to set a Bid for your campaign. <Link onClick={()=>openDrawer("bid")}>Set Bid</Link></>)
        }

    }, [campaign])

    const handleInput = (event) => {
        const { id, value } = event.target;
        
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value
        }));
    }

    const handleDrawer = (id, action) => {
        setDrawers((prevDrawers) => ({
            ...prevDrawers,
            [id]: (action === "open")
        }));
    }

    const handleModal = (id, action) => {
        setModals((prevModals) => ({
            ...prevModals,
            [id]: (action === "open")
        }));
    }

    const openDrawer = (id) => handleDrawer(id, "open")
    const closeDrawer = (id) => {handleDrawer(id, "close"); loadCampaignData()};

    const openModal = (id) => handleModal(id, "open")
    const closeModal = (id) => {handleModal(id, "close"); loadCampaignData()};

    return (
        <>
        {isLoading && !campaign && <div style={{paddingTop: "100px"}}><Loader position="center" label="Loading campaign..." /></div>}
        {campaign ?
        
        <Container key={key} fluid className="p-0">
            <Row>
                <Col>
                {Object.keys(todos).length > 0 &&
                    <div className="p-2 text-start my-2" style={{border: "2px #ff6c00 dashed", borderRadius: "10px"}}>
                        <ul className="mb-0 ps-3">
                            {Object.keys(todos).map(key=>(<li className="mb-0">{todos[key]}</li>))}
                        </ul>
                    </div>
                }      
                </Col>
            </Row>
            <Row className="gx-lg-3">
                <Col lg={12}>
 
                    <div className="mb-2">
                        <div className="my-2 mt-md-1" style={{display: "flex", flexWrap: "wrap"}}>
                            <div style={{maxWidth: "100%"}}>
                                <div style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                                    <h4 className="me-auto mb-0 mb-lg-1 fw-bold text-start">
                                        <span className="gradient-text d-none">Campaign</span> <span className="text-break display-inline me-2"><EditableTextField onSave={(e)=>submitEditCampaign({label: e})} value={campaign.label || "Unnamed campaign"} /></span>
                                        <span className="text-muted no-wrap text-nowrap" style={{fontWeight: 300, fontSize: "0.6em"}}>ID {campaign.id} | <span>{campaign?.status ? Utils.capitalizeString(campaign.status) : ""}</span> <b className="text-danger">{campaign?.archived && campaign?.status !== "archived" ? "Archived" : ""} {campaign.scheduled === 0 ? " Out of schedule" : ""}</b></span>
                                    </h4>
                                </div>
                                <QuickActions className="my-2 my-lg-0 me-auto">
                                    {campaign.moderationStatus === "approved" && ['enabled','disabled'].includes(campaign.status) 
                                    ? campaign.status==="disabled" 
                                        ? <Link onClick={()=>handleEnableCampaign()}><Icon iconName="Play" size="1.7em" style={{fill:"#1fa91f"}} />Enable campaign</Link>
                                        : <Link onClick={()=>handleDisableCampaign()} style={{color: "#adadad"}}><Icon iconName="Pause" size="1.7em"></Icon>Disable campaign</Link>
                                    :""}
                                    <span className="link-primary text-nowrap" onClick={()=>openDrawer("settings")}><Icon iconName="CogFilled" size="1rem" /> Settings</span>
                                    <span className="link-primary text-nowrap" onClick={()=>openDrawer("targeting")}><Icon iconName="Bullseye" size="1rem" /> Targeting {campaign?.targeting && <>({Object.keys(campaign?.targeting).map(key=>campaign.targeting[key].length).reduce((accumulator, currentValue)=>accumulator += currentValue, 0)-1})</>}</span>
                                    <span className="link-primary text-nowrap" onClick={()=>openDrawer("schedule")}><Icon iconName="CalendarRange" size="1.1em" /> Schedule</span>
                                    <span className="link-primary text-nowrap" onClick={()=>openDrawer("updates")}><Icon iconName="List" size="1rem" /> Updates</span>
                                    
                                    <Link onClick={()=>openModal('tracking')}><Icon iconName="Code" size="1.2em"></Icon> Tracking</Link>
                                    <div>
                                        <DropdownMenu id="dropdown-item-button" variant="span" as="span" size="sm" label={<span className="text-primary" style={{fontSize: "0.9rem"}}>More options</span>}>
                                            <Dropdown.Item>
                                                <Link onClick={()=>openModal("clone")}><Icon iconName="Copy" size="1em"></Icon> Clone</Link>
                                            </Dropdown.Item>
                                            {campaign.archived === 0 && 
                                                <Dropdown.Item>
                                                    <Link onClick={()=>openModal("archive")}><Icon iconName="Archive" size="1em"></Icon> Move to Archive</Link>
                                                </Dropdown.Item>
                                            }
                                        </DropdownMenu>
                                    </div>

                                </QuickActions> 
                            </div>

                            {/* <OverlayTrigger id="overlayid" trigger="click" className="mobile-only d-none" rootClose placement="auto" overlay={
                                <Popover id="popover-trigger-click" onHide={(e)=>{console.log("hiding")}} style={{maxWidth: "100%", boxShadow: "6px 6px 15px #aeaeae", fontFamily: "inherit"}}>
                                    <DatePicker 
                                        captionLayout="dropdown-buttons" 
                                        saveaction={handleSetSelectedTimeRange} 
                                        selectedRange={selectedTimeRange} 
                                        toDate={new Date()} 
                                        defaultMonth={selectedTimeRange?.to ? new Date(selectedTimeRange?.to?.getFullYear(), selectedTimeRange?.to?.getMonth()) : new Date()} 
                                        fromYear={new Date("2020-01-01").getFullYear()} toYear={new Date().getFullYear()}
                                    />
                                </Popover>
                                }>
                                
                                <div className="timeframe-button ms-auto disable-text-selection" id="mypopover" style={{fontSize: "1rem"}}>
                                    <span><small className="black">Showing data for</small><br className="desktop-only" />
                                    <Icon iconName="CalendarRange" size="1rem" className="me-1 ms-2" style={{marginBottom: "3px"}}/>
                                    
                                    {selectedTimeRange
                                    ? selectedTimeRange?.from?.toDateString() === selectedTimeRange?.to?.toDateString() || (selectedTimeRange?.from && !selectedTimeRange?.to) // 
                                        ? new Date(selectedTimeRange.from).toDateString() === new Date().toDateString() 
                                            ? "Today"
                                            : Utils.ddmmyyyy(selectedTimeRange.from)
                                        : `${Utils.ddmmyyyy(selectedTimeRange.from)} - ${Utils.ddmmyyyy(selectedTimeRange.to)}`
                                    : "All time"
                                    }
                                    </span>
                                </div>
                            </OverlayTrigger> */}

                            {/* <div className="timeframe-button ms-auto disable-text-selection" style={{fontSize: "1rem", padding: 0, border: 0}}>
                                <small className="black me-1">Showing data for {Utils.capitalizeString(selectedTimeRange.label)}</small><br className="desktop-only" />
                                <DropdownButton id="dropdown-item-button" variant="span" style={{display: "inline-block"}} size="sm" title={
                                    <span className="text-primary"><Icon iconName="CalendarRange" size="1rem" className="me-1" style={{marginBottom: "3px"}}/> 
                                        <span style={{fontSize: "1.2em"}}>
                                        {selectedTimeRange?.label !== "All time"
                                            ? dayjs(selectedTimeRange.from).format("DD.MM.YYYY") === dayjs(selectedTimeRange.to).format("DD.MM.YYYY")
                                                ? dayjs(selectedTimeRange.from).format("DD.MM.YYYY")
                                                : `${dayjs(selectedTimeRange.from).format("DD.MM.YYYY")} - ${dayjs(selectedTimeRange.to).format("DD.MM.YYYY")}`
                                            : "All time"
                                        }
                                        </span>
                                    </span>}>
                                    {["Today", "Yesterday", "Last 7 days", "This week", "Last week", "This month", "Last month", "This year", "Last year", "All time"]
                                        .map((v,i)=>(<Dropdown.Item as="span" onClick={()=>handleSetSelectedTimeRange(v)} value={v} style={{fontSize: "0.8rem"}}>{v}</Dropdown.Item>))}
                                </DropdownButton>
                            </div> */}

                            <div className="timeframe-button disable-text-selection ms-auto" style={{alignContent: "end"}}>
                                <DropdownMenu
                                    id="dropdown-item-button" 
                                    variant="span"
                                    as="span"
                                    size="sm" 
                                    style={{display: "inline-block", verticalAlign: "bottom"}}
                                    label={
                                        <span style={{fontSize: "0.9rem", verticalAlign: "inherit"}}>
                                            <span className="me-1 black">Showing data for</span>
                                            <span className="text-primary fw-bold">
                                                {Utils.capitalizeString(selectedTimeRange.label)}
                                            </span>
                                        </span>
                                    }>
                                    {["Today", "Yesterday", "Last 7 days", "This week", "Last week", "This month", "Last month", "This year", "Last year", "All time"]
                                        .map((timeframe,i)=>(<Dropdown.Item onClick={()=>handleSetSelectedTimeRange(timeframe)} value={timeframe} style={{fontSize: "0.8rem"}}>{timeframe}</Dropdown.Item>))}
                                </DropdownMenu>
                            </div>
                        </div>                        
                    </div>      
                    
                    <Container className="p-0 mb-2" fluid>
                        <Row className="g-2">
                            <Col xs={6} md={3} lg={2}>
                                <StatWidget 
                                    title="Views" 
                                    value={statsSummary.views ? Utils.formatNumber(statsSummary.views) : "0"} 
                                    // valueText="views" 
                                    text={<>All time {Utils.formatNumber(statsSummary.views > campaign.views ? statsSummary.views : campaign.views)}</>}
                                />
                            </Col>
                            <Col xs={6} md={3} lg={2}>
                                <StatWidget 
                                    title="Cost" 
                                    value={<>${Utils.formatNumber(statsSummary.value,2) || 0}</>} 
                                    text={<>All time ${Utils.formatNumber(statsSummary.value > campaign.value ? statsSummary.value : campaign.value,2)}</>}
                                />
                            </Col>
                            <Col xs={6} md={3} lg={2}>
                                <StatWidget 
                                    title="Avg. Bid"
                                    link={campaign?.bid ? <span>${Utils.formatNumber(Number(campaign?.bid))} {campaign?.bidType?.toUpperCase()}</span> : "Set bid"}
                                    value={<>${Utils.formatNumber(statsSummary.value / statsSummary.views,5)}</>} 
                                    text={<>All time ${Utils.formatNumber(campaign.value / campaign.views,5)}</>}
                                    onClick={()=>openDrawer('bid')}
                                />
                            </Col>
                            <Col xs={6} md={3} lg={2}>
                                <StatWidget 
                                    title="Conversions" 
                                    value={statsSummary.conversions ? Utils.formatNumber(statsSummary.conversions) : "0"} 
                                    text={<>All time {Utils.formatNumber(statsSummary.conversions > campaign.conversions ? statsSummary.conversions : campaign.conversions)}</>}                            
                                />
                            </Col>
                            <Col xs={6} md={3} lg={2}>
                                <StatWidget 
                                    title="Daily budget" 
                                    value={<><span>{campaign.dailyBudget > 0 ? <>${Utils.formatNumber(campaign.dailyBudget)}</> : <>Unlimited</>}</span></>}
                                    onClick={()=>openDrawer('dailyBudget')}
                                    text={!campaign.dailyBudget ? "-" :""}
                                    highlighted={campaign.dailyBudget > 0 && ((campaign.dailyBudgetUsed + campaign.dailyBudgetUsedAdjustment) / campaign.dailyBudget >= 1)}
                                >
                                    {campaign.dailyBudget ?
                                    <Stack direction="horizontal" gap={1} style={{marginTop: "4px"}}>
                                        <span style={{flexGrow: 1}}>
                                            <ProgressBar percent={((campaign.dailyBudgetUsed + campaign.dailyBudgetUsedAdjustment) / campaign.dailyBudget * 100).toFixed(1)} />
                                        </span>
                                    </Stack>
                                    : ""
                                    }
                                </StatWidget>
                            </Col>
                            <Col xs={6} md={3} lg={2}>
                                <StatWidget
                                    title={`Rate limit ${campaign?.rateLimitPeriod ? " per " + Utils.capitalizeString(campaign.rateLimitPeriod) : ""} `} 
                                    text={!campaign.rateLimitPeriod ? "-" :""}
                                    value={
                                        <>
                                        {campaign?.rateLimitPeriod 
                                            ? <Value type="number" value={campaign.rateLimitAmount} />
                                            : <>Unlimited</>
                                        }
                                        </>
                                    }
                                    onClick={()=>openDrawer('rateLimit')}
                                    highlighted={campaign.rateLimitPeriod > 0 && campaign.rateLimitCounter / campaign.rateLimitAmount >= 1}
                                >
                                    {campaign.rateLimitPeriod
                                        ? <Stack direction="horizontal" gap={1} style={{marginTop: "4px"}}>
                                            <span style={{flexGrow: 1}}><ProgressBar percent={(campaign.rateLimitCounter / campaign.rateLimitAmount * 100).toFixed(1)} /></span>
                                        </Stack>
                                        : ""
                                    }
                                </StatWidget>  
                            </Col>
                        </Row>
                    </Container>

                    <div style={{display: "flex", flexDirection: "row", fontSize: "0.9rem", flexWrap: "wrap"}}>
                        <div className="position-relative ms-lg-auto">
                            <DropdownMenu 
                                id="dropdown-item-button" 
                                as="span" 
                                size="sm" 
                                style={{color: "black", background: "#ffffff90", borderRadius: "3px"}} 
                                label={<span className="text-secondary" style={{fontSize: "0.9rem"}}>{Utils.capitalizeString({minute: "minute", hour: "hourly", day: "daily", month: "monthly"}[chartOptions?.timeGroup || "hour"])} chart</span>}
                                >
                                {chartTimeGroups.includes("minute") ? <Dropdown.Item style={{fontSize: "0.8rem"}} onClick={()=>handleSetChartOptions({timeGroup: "minute"})}>Minute chart</Dropdown.Item> : ""}
                                {chartTimeGroups.includes("hour") ? <Dropdown.Item style={{fontSize: "0.8rem"}}onClick={()=>handleSetChartOptions({timeGroup: "hour"})}>Hourly chart</Dropdown.Item> : ""}
                                {chartTimeGroups.includes("day") ? <Dropdown.Item style={{fontSize: "0.8rem"}}onClick={()=>handleSetChartOptions({timeGroup: "day"})}>Daily chart</Dropdown.Item> : ""}
                                {chartTimeGroups.includes("month") ? <Dropdown.Item style={{fontSize: "0.8rem"}}onClick={()=>handleSetChartOptions({timeGroup: "month"})}>Monthly chart</Dropdown.Item> : ""}
                            </DropdownMenu> 
                        </div>
                    </div>

                    <Container fluid className="p-0 mb-1">
                        <Row>
                            <Col lg={12}>
                                <div className="position-relative px-1 pt-3" style={{height: "240px", overflow: "hidden"}}>
                                    {chart 
                                        ? chart?.rows
                                            ? <TrafficByTimeChart data={chart} chartOptions={chartOptions}/>
                                            : <ChartPlaceholder message={chartMessage} />
                                        : <div style={{height:"240px"}}> <Loader label="Loading chart" position="center" /> </div>
                                    }
                                </div>
                            </Col>                                
                        </Row>
                    </Container> 
                </Col>
            </Row>
            
            <Row className="gx-lg-1">
                <Col className="p-0 p-lg-1">
                    <div style={{background: "white", borderRadius: "8px"}}>
                        <Container fluid className="p-0 mb-3">
                            <Tabs
                                defaultActiveKey={activeStatsTab}
                                id="tabs"
                                className="mb-0"
                                onSelect={handleStatsTabSelect}
                                transition={Fade}
                                >
                                <Tab eventKey="ads" title="Target URLs" transition={Fade}>
                                    {/* <QuickActions className="p-2 mb-0">
                                        <span>Create a new ad</span>
                                        <span className="link-primary text-nowrap" onClick={()=>openDrawer("urls")}> Pop</span>
                                    </QuickActions>  */}
                                    <CampaignAdsStatsTable data={adsStats} campaign={campaign} refreshCampaign={loadCampaignData} editAdHandler={handleEditAd} openDrawerHandler={openDrawer} openModalHandler={openModal}/>
                                </Tab>
                                <Tab eventKey="countries" title="Countries" transition={Fade}>
                                    <CampaignTargetingStatsTable targetingType="country" data={countriesStats}/>
                                </Tab>
                                <Tab eventKey="devices" title="Devices" transition={Fade}>
                                    <CampaignTargetingStatsTable targetingType="device" data={devicesStats}/>
                                </Tab>
                            </Tabs>
                        </Container>                        
                    </div>
                </Col>
            </Row>

            <Drawer show={drawers?.schedule} onHide={()=>closeDrawer("schedule")} placement="end" headertext="Schedule">
                <Sections.Campaigns.EditCampaignScheduleSection handleClose={closeDrawer} campaignId={campaign.id} timezone={profile?.timezone} selections={campaign?.schedule ? JSON.parse(campaign.schedule) : {}} />
            </Drawer>

            <Drawer show={drawers?.audience} onHide={()=>closeDrawer("audience")} placement="end" headertext="Audience">
                <p>Changing your audience will clear your vertical targeting and queue campaign for moderation.</p>
                <Button>Update Audience</Button>
            </Drawer>

            <Drawer show={drawers?.targeting} onHide={()=>closeDrawer("targeting")} placement="end" headertext={`Set Targeting ${campaign?.targeting?.category ? "for " + campaign.targeting.category : ""}` }>
                <Sections.Campaigns.EditCampaignTargetingSection handleClose={closeDrawer} format={campaign.format} targetingCategory={campaign?.targeting?.category} campaignId={campaign.id} selections={campaign.targeting}/>
            </Drawer>
            
            <Drawer show={drawers?.editAd} onHide={()=>closeDrawer("editAd")} placement="end" headertext="Edit Ad">
                <Sections.Campaigns.EditAdSection handleClose={closeDrawer} adId={selectedAd}/>
            </Drawer>

            <Drawer show={drawers?.settings} onHide={()=>closeDrawer("settings")} placement="end" headertext="Settings">
                <div>
                    <Table className="mb-0">
                    <tbody>
                        <tr>
                            <td>Ad format</td>
                            <td>{campaign.format ? <b>{campaign.format.toUpperCase()}</b> : ""}</td>
                        </tr>
                        {campaign?.targeting?.category 
                            ? <tr><td>Audience</td><td><span className="link-primary" onClick={()=>openDrawer('audience')}>{Utils.capitalizeString(campaign.targeting.category[0])}</span></td></tr>
                            : ""
                        }                       
                        <tr>
                            <td>Bid</td>
                            <td>
                                <span className="link-primary" onClick={()=>openDrawer("bid")}> {campaign.bid ? `$${campaign.bid}` : "Set bid"} {`${campaign.bidType ? campaign.bidType.toUpperCase() : ""}`}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Daily budget</td>
                            <td>
                                <span className="display-inline link-primary" onClick={()=>openDrawer('dailyBudget')}>{`${campaign.dailyBudget ? `$${campaign.dailyBudget}` : "Unlimited"}`}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Schedule</td>
                            <td>
                                <span className="display-inline link-primary" onClick={()=>openDrawer('schedule')}>{scheduleToText(campaign.schedule)}</span>
                                {campaign?.schedule?.startTime && <p className="mb-0">Start: <span className="link-primary" onClick={()=>openDrawer('schedule')}>{dayjs(campaign.schedule.startTime).format("DD.MM.YYYY")}</span></p>}
                                {campaign?.schedule?.endTime && <p className="mb-0">End: <span className="link-primary" onClick={()=>openDrawer('schedule')}>{dayjs(campaign.schedule.endTime).format("DD.MM.YYYY")}</span></p>}
                            </td>
                        </tr>
                        <tr>
                            <td className="no-bottom-border">Rate limiter</td>
                            <td className="no-bottom-border">
                                <span className="display-inline link-primary" onClick={()=>openDrawer('rateLimit')}>{rateLimitToText({period: campaign.rateLimitPeriod, amount: campaign.rateLimitAmount})}</span>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Button variant="outline-secondary" className="mt-3 d-block" onClick={()=>closeDrawer("settings")} style={{margin: "0 auto", maxWidth: "200px", width: "200px"}}>Close</Button>

                </div>
            </Drawer>

            <Drawer show={drawers?.rateLimit} onHide={()=>closeDrawer("rateLimit")} placement="end" headertext="Set Rate limiter">
                <Sections.Campaigns.EditCampaignRateLimiterSection campaignId={campaign.id} handleClose={closeDrawer} handleReset={handleResetRateLimit} selections={{period: campaign.rateLimitPeriod, amount: campaign.rateLimitAmount}}/>
            </Drawer>

            <Drawer show={drawers?.updates} onHide={()=>closeDrawer("updates")} placement="end" headertext="Updates">
                <Sections.Campaigns.CampaignUpdatesSection campaignId={campaign.id} data={{campaignCreateTime: campaign.createTime}}/>
            </Drawer> 

            <Drawer show={drawers?.bid} onHide={()=>closeDrawer("bid")} placement="end" headertext="Bid" >
                <Form onSubmit={(e)=>{e.preventDefault(); updateCampaignBid(campaign.id, formData.bid, "cpm").then(result=>{closeDrawer("bid")})}}>
                    <p>The maximum amount your are willing to pay for 1000 impressions.<br /><small className="text-secondary">For example, $5 CPM means that no more than $0.005 will be charged per impression</small></p>
                    <div className="text-center d-block">
                    <TextInput
                        id="bid"
                        floatingLabel="Update your bid"
                        type="number"
                        value={formData?.bid || formData?.bid === "" ? formData.bid : campaign.bid}
                        onChange={handleInput}
                        onBlur={handleInput}
                        description="Minimum bid is $1.5, maximum is $10"
                        required={true}
                        min="1.5"
                        max="10"
                        step="0.01"
                        style={{maxWidth: "350px"}}
                    />
                    </div>
                    <Button type="submit">Update bid</Button>
                </Form>
            </Drawer>

            <Drawer show={drawers?.dailyBudget} onHide={()=>closeDrawer("dailyBudget")} placement="end" headertext="Set Daily budget">
                <Form onSubmit={(e)=>{e.preventDefault(); updateCampaignDailyBudget(campaign.id, formData.dailyBudget || 0).then(r=>{closeDrawer("dailyBudget")}).then(result=>{closeDrawer("bid")})}}>
                    <p>The maximum amount of your are willing to spend within 24 hours</p>

                    <div className="text-center d-block">
                        <div>
                        <TextInput
                            id="dailyBudget"
                            floatingLabel="Daily budget in USD"
                            type="number"
                            value={formData?.dailyBudget || formData?.dailyBudget === "" ? formData.dailyBudget : campaign.dailyBudget}
                            onChange={handleInput}
                            onBlur={handleInput}
                            description="Type an amount or leave empty to disable limitation"
                            min="5"
                            max="10000"
                            step="1"
                            style={{maxWidth: "350px"}}
                        />
                        </div>
                        <Button type="submit" className="mb-2">Update daily budget</Button> <br />
                        <Link className="pt-2" onClick={() => updateCampaignDailyBudget(campaign.id, 0).then(r=>{closeDrawer("dailyBudget")})}>Remove daily budget limit</Link> | 
                        <Link className="pt-2 ms-1" onClick={() => handleResetDailyBudget().then(r=>{closeDrawer("dailyBudget")})}>Reset daily budget</Link>
                    </div>

                </Form>
            </Drawer>
            
            <Drawer show={drawers?.urls} onHide={()=>closeDrawer("urls")} placement="end" headertext="Add URLs" style={{width:"600px !important"}}>
                <Sections.Campaigns.AddMultipleUrlsSection campaignId={campaign.id} handleClose={closeDrawer} />
             </Drawer>

             <Drawer show={drawers?.statusAfterApproval} onHide={()=>closeDrawer("statusAfterApproval")} placement="end" headertext="Status after moderation" style={{width:"600px !important"}}>
                <Sections.Campaigns.EditStatusAfterApprovalSection campaignId={campaign.id} handleClose={closeDrawer} selections={{statusAfterApproval: campaign.statusAfterApproval}}/>
             </Drawer>                  

            <Modal show={modals?.tracking} onHide={()=>closeModal("tracking")} size="lg" header="Tracking pixel">
                <p>A tracking pixel enables your campaign to count conversions and helps to optimize campaign performance.</p>
                <p>Both snippets should be placed before the <b>{`</body>`}</b> HTML tag.</p>
                <p>1. Code to be placed <u>only</u> on your <b>landing page</b></p>

                <p><code>{`<script type="text/javascript" src="https://tiotrk.com/action/land"></script>`}</code></p>
  
                <p>2. Code to be placed <u>only</u> on your <b>thank you page</b></p>
                <p><code>{`<script type="text/javascript" src="https://tiotrk.com/action/conversion"></script>`}</code></p>
						
                <p>For a more detailed explanation, <a target="_blank" href={`${WEBSITE_URL}/blog/post/embed-pixel`}>read the guide</a>.</p>
            </Modal>


            <Modal show={modals?.addUrl} onHide={()=>closeModal("addUrl")} closeButton size="lg" header="Add a new URL">
                <Sections.Campaigns.AddUrlSection campaignId={campaign.id} handleClose={closeModal} />
            </Modal>

            <Modal show={modals?.archive} onHide={()=>closeModal("archive")} size="lg" header="Archive campaign" showFooter button={<Button onClick={()=>handleArchive()}>Yes, Archive my campaign</Button>}>
                <p>Are you sure that you wish to move your campaign to archive?</p>
            </Modal>

            <Modal show={modals?.clone} onHide={()=>closeModal("clone")} size="lg" header="Clone campaign" showFooter button={<Button onClick={()=>handleClone()}>Yes, Clone my campaign</Button>}>
                <p>Would you like to clone this campaign, and have a new campaign created with all the same properties?</p>
            </Modal>            
        </Container>
        : ""} 
        </>
        
    );
}

export default CampaignViewPage;