import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer-class"></footer>
    )
}

export default Footer;