import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Loader, SelectList, TextInput, Icon } from '../../../components/components';
import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
import * as Utils from '../../../utils/common.js';
import { editCampaign } from '../../../api/actions';

const EditStatusAfterApprovalSection = ({children, ...props}) => {
    const {register, handleSubmit, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });

    const formOptions = {
        statusAfterApproval: {required:"Required"}
    };
    
    const handleFormData = async (data) => {
        try {
            await editCampaign({id:props.campaignId, statusAfterApproval: data.statusAfterApproval.value});
        } catch (error) {

        }

        props.handleClose("statusAfterApproval")
    }

    const handleError = (errors) => {console.log("Errors in form", errors)};

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true)
	},[]);

    return (  
        <>
        {loaded ?
            <>
            <p>Automatically toggle campaign status once moderation is completed.</p>
            <form onSubmit={handleSubmit(handleFormData, handleError)}>
                <Container fluid className="m-0 p-0">
                    <Row>
                        <Col>
                            <Controller
                                name="statusAfterApproval"
                                control={control}
                                rules={formOptions.statusAfterApproval}
                                render={({field}) => (
                                    <SelectList 
                                        id="statusAfterApproval"
                                        description="Choose status after approval"
                                        label="Status after approval"
                                        options={[{value: "enabled", label: "Enable campaign"},{value: "disabled", label: "Disable campaign"}]} 
                                        searchable
                                        preselected={[props?.selections?.statusAfterApproval]}
                                        errorMessage={errors?.statusAfterApproval && errors.statusAfterApproval.message}
                                        isValid={!!errors?.statusAfterApproval}
                                        {...field}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                </Container>
                <Button type="submit">Update Status After Approval</Button>
            </form>
        </>
        : <Loader />}
    </>
    )
}

export default EditStatusAfterApprovalSection;