import { useEffect } from "react";
import BarChart from "./BarChart";
import LineChart from "./LineChart";

import dayjs from 'dayjs';

const TrafficByTimeChart = ({data, style, ...props}) => {
    const utcOffset = dayjs().utcOffset();

    const timeGroup = props?.chartOptions?.timeGroup;
    let timeFormat;
    
    switch(timeGroup) {
        case "minute": timeFormat = "D/M H:mm"; break;
        case "hour": timeFormat = "D/M H:00"; break;
        case "day": timeFormat = "DD/MM/YYYY"; break;
        case "month": timeFormat = "MM/YYYY"; break;
        default: timeFormat = "YYYY-MM-DD HH:mm";
    }

    return (
        <BarChart 
            chartData={{
                labels: data?.rows ? data.rows.map(r=>dayjs(r[data.columns.indexOf("t")]).add(utcOffset, "minute").format(timeFormat)) : [""],
                datasets: data?.rows ? [
                    {
                        label: "views", 
                        data: data.rows.map(r=>r[data.columns.indexOf("views")]),
                        backgroundColor: function(context) {
                            var chart = context.chart;
                            var ctx = chart.ctx;
                            var gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
                            gradient.addColorStop(1, 'rgba(255, 108, 0, 0)');
                            gradient.addColorStop(0, '#ff6c00');
                            return gradient;
                        }
                    }
                ] : [0],
            }}                                
            options={{
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    point:{
                        radius: 0,
                    },
                    line: {
                        borderJoinStyle: 'round'
                    }
                },
                maxBarThickness: 8,
                plugins: {
                    legend: {
                        position: 'bottom',
                        display: false,
                    },
                    tooltip: {
                        enabled: true,
                        mode: 'index', 
                        intersect: false, 
                        position: 'nearest',
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        border: {
                            // display: false,
                        },
                        ticks: {
                            maxTicksLimit: 15,
                            autoSkip: true,
                            maxRotation: 0,
                            minRotation: 0,
                            callback: function(value, index, ticks) {
                                return data?.rows ? dayjs(data.rows[value][0]).add(utcOffset, "minute").format(timeFormat).split(" ") : "";
                            }
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                        border: {
                            display: false,
                        },
                        ticks: {
                            display: true,
                            precision: 0,
                        },
                        position: "right",
                    }
                },

                hover: {
                    mode: 'index',
                    intersect: false
                }
            }}
            // style={style ? style : {maxHeight: "200px", width: "100%"}}
        />        
    )
}

export default TrafficByTimeChart;