import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Loader, SelectList, TextInput, Icon } from '../../../components/components.jsx';
import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
import * as Utils from '../../../utils/common.js';
import validator from 'validator';
import { getAd, editAd } from '../../../api/actions.js';

const EditAdSection = ({children, ...props}) => {

    const [url, setUrl] = useState(false);

    const {register, handleSubmit, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });

    const formOptions = {
        url: {
            required: "URL is required",
            validate: (v)=>(validator.isURL(v) || "URL is invalid"),
        },
    };
    
    const handleFormData = (data) => {
        editAd(props.adId, data);
    }

    const handleError = (errors) => {console.log("Errors in form", errors)};

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        getAd(props.adId).then((data)=>{
            setUrl(data.url)
            setLoaded(true)
        });
        
	},[]);

    return (  
        <>
        {loaded ?
            <>
            <form onSubmit={handleSubmit(handleFormData, handleError)}>
                <Container fluid className="m-0 p-0">
                    <Row>
                        <Col md={12}>
                            <Controller
                                    name="url"
                                    control={control}
                                    rules={formOptions.url}
                                    defaultValue={url}
                                    render={({field}) => (
                                        <TextInput 
                                            id="url"
                                            type="text"
                                            floatingLabel="Target URL"
                                            description="e.g. https://my-website.com"
                                            errorMessage={errors?.url && errors.url.message}
                                            {...field}

                                        />
                                    )}
                                />
                        </Col>
                    </Row>
                </Container>
                <Button type="submit">Save</Button>
            </form>
        </>
        : <Loader />}
    </>
    )
}

export default EditAdSection;