import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../auth/AuthProvider';
import { useStateContext } from '../../../contexts/ContextProvider.js';

import { Loader, Icon, DatePicker, TrafficByTimeChart, ChartPlaceholder, Box } from '../../../components/components';
import QuickActions from '../../../containers/QuickActions/QuickActions';
import { Container, Row, Col, OverlayTrigger, Popover} from 'react-bootstrap';
import * as Utils from '../../../utils/common.js';

import { getCampaigns } from '../../../api/actions';

import PagePreferences from '../../../utils/pagePreferences.js';
import Sections from '../../../sections/sections';

const ArchivedCampaignsPage = () => {

    const {profile} = useAuth();

    const [campaigns, setCampaigns] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState();

    const { addNotification } = useStateContext();

    const loadArchivedCampaigns = async () => {
        try {
            const data = await getCampaigns(profile.advertiserId, {archived:1});

            if(data.total > 0) {
                setCampaigns(data);
            } else if (data.total === 0) {
                setCampaigns({rows:[],columns:[]});
            } 

            setIsLoaded(true);
        
        } catch (error) {
            console.log(error)
        }
    }

	useEffect(()=>{
        loadArchivedCampaigns();
	},[]);

    const editRow = (campaignId, field, value) => {
        const campaignIndex = campaigns.rows.findIndex((campaign)=>(campaign[campaigns.columns.indexOf("id")] === campaignId))
        setCampaigns(prev=>{
            prev.rows[campaignIndex][campaigns.columns.indexOf(field)] = value;
            return prev;
        })
    }

    return (
        <>  
            <Container fluid className="p-0">
                <Row className="gx-lg-3">
                    <Col lg={12}>
                        <div className="my-2 mt-md-1" style={{display: "flex", flexWrap: "wrap"}}>
                            <QuickActions className="my-2 my-lg-0 me-auto">
                                    <Link to="/campaigns"><Icon iconName="ArrowLeft" size="22" /> Back to campaigns</Link>
                                </QuickActions> 

                            </div>
                        <div>
                            {campaigns?.rows ? <Sections.Campaigns.ArchivedCampaignsTable data={campaigns} search={searchTerm} editRow={editRow}/> : <Loader  label="Loading archived campaigns" />}
                        </div>                       
                    </Col>
                </Row>
            </Container>
        </>
        
    );
}

export default ArchivedCampaignsPage;