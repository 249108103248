import axios from 'axios';
import { BACKEND_URL } from '../config.js';

const apiConfig = {
    baseURL: BACKEND_URL,
    withCredentials: true,
    timeout: 10000,
};

const api = axios.create(apiConfig);

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authtoken');
        if(token) {
            config.headers.authorization = token;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if(error?.code === "ERR_NETWORK") {
            return {error: "Connection error"}
        }
        
        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const response = await axios.post('/auth/refresh', {}, apiConfig);

                const token = response.headers.authorization;
                localStorage.setItem('authtoken', token);

                // Retry the original request with the new token
                originalRequest.headers.authorization = token;
                
                return axios(originalRequest);
            } catch (error) {
                if(error?.response?.status === 401) { // If unauthorized to refresh token
                    localStorage.clear();
                }

                console.log(error?.response);
            }
        }

        return Promise.reject(error);
    }
);

export default api;