import {useState, useEffect, useRef, useLayoutEffect} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Topbar from '../containers/Topbar/Topbar';
import Footer from '../containers/Footer/Footer';
import Notifications from '../containers/Notifications/Notifications';
import Sidebar from '../containers/Sidebar/Sidebar';

import { addPageView } from '../api/actions.js';

import './layout.css';

const PlatformLayout = ({ children }) => {
    const currentLocation = useLocation();
    const [topbarLoaded, setTopBarLoaded] = useState();
    const [title, setTitle] = useState("Traffics.io Platform");

	useEffect(()=>{
        addPageView({url: window.location.href, referrer: document.referrer});
        if(checkIfMobile) setIsSidebarOpen(false);
        document.title = title;
        window.scrollTo(0, 0);
	}, [currentLocation]);

    const checkIfMobile = useMediaQuery({ query: '(max-width: 992px)' }) // monitor if mobile

    const savedIsSidebarOpen = sessionStorage.getItem("sidebar");
    const [isSidebarOpen, setIsSidebarOpen] = useState((!checkIfMobile && (!savedIsSidebarOpen || savedIsSidebarOpen === "true")));

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
        sessionStorage.setItem("sidebar",!isSidebarOpen)
	}

    const topbarRef = useRef(null);
    const mainPageContentRef = useRef(null);
    
    const adjustContentMargin = () => {
        if (topbarRef.current && mainPageContentRef.current) {
            const topbarHeight = topbarRef.current.offsetHeight;
            mainPageContentRef.current.style.marginTop = `${topbarHeight}px`;
        }
    };    
    
    useEffect(() => {
        // Adjust the margin on component mount
        adjustContentMargin();
        window.addEventListener('resize', adjustContentMargin);
        return () => {
            window.removeEventListener('resize', adjustContentMargin);
        };
    }, [topbarLoaded]);

    useEffect(()=>{
        
    })
    return (
        <div style={{minHeight: "100vh"}}>
            <Topbar sidebarToggler={toggleSidebar} isSidebarOpen={isSidebarOpen} ref={topbarRef} setLoaded={setTopBarLoaded}/>
            <Sidebar sidebarToggler={toggleSidebar} show={isSidebarOpen}/>
            <div id="page-content-wrapper" className={`${isSidebarOpen ? "sidebar-offset" : ""}`}>
                <Container fluid ref={mainPageContentRef}>
                    <Outlet />
                </Container>
                <Footer />
            </div>

            <Notifications />

            {children}

        </div>
    )
}
  
  export default PlatformLayout;