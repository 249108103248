import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { useStateContext } from '../../../../contexts/ContextProvider.js';
import { useAuth } from '../../../../auth/AuthProvider.js';
import { TextInput, SelectList, FlagIcon, Modal, Loader } from '../../../../components/components';
import { QuickActions } from '../../../../containers/containers';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getUserProfile, updateUserProfile } from '../../../../api/actions.js';
import UpdateUserPasswordForm from '../../../../sections/Profile/UpdateUserPasswordForm/UpdateUserPasswordForm.jsx';

import { timezones } from '../../../../utils/timezones.js';

import validator from 'validator';

import * as Utils from '../../../../utils/common.js';

const ProfilePage = () => {

    const [userProfile, setUserProfile] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modals, setModals] = useState({});  
    const [drawers, setDrawers] = useState({});  
    const [selectedSubscriptionLevel, setSelectedSubscriptionLevel] = useState();  

    const profile = useAuth();
    const { addNotification } = useStateContext();

    const {register, handleSubmit, getValues, watch, reset, formState: { errors }, control} = useForm({
        mode: "onBlur",
        defaultValues: () => loadUserProfile()
    });

    const formOptions = {
        name: {
            required: "Name is required"
        },
        email: {
            required: "Email is required",
            validate: (v) => validator.isEmail(v) || "Email is invalid"
        },
        country: {
            required: "Country is required"
        },
        timezone: {
            required: "Timezone is required"
        }
    };

    const loadUserProfile = async () => {
        try {
            const data = await getUserProfile();

            const formData = {
                name: data.firstName,
                email: data.email,
                phone: data.phone,
                organization: data.organization,
                country: data.country,
                timezone: data.timezone,
                subscriptionLevel: data.subscriptionLevel
            }

            setUserProfile(formData);
            setSelectedSubscriptionLevel(data.subscriptionLevel);
            setIsLoaded(true);
            return formData;
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        // populateUserProfile();
    }, [isLoaded])

    const handleError = (errors) => {console.log("Errors in form", errors)};

    const handleSubmission = async () => {
      
        const formData = getValues();
        const submissionData = {...formData};

        if(typeof submissionData.country === "object") submissionData.country = formData.country.value;
        if(typeof submissionData.timezone === "object") submissionData.timezone = formData.timezone.value;

        try {
            const submitForm = await updateUserProfile(submissionData);
            addNotification("Updated profile")
        } catch (error) {
            addNotification("Error updating profile")
        }
    }

    const handleUpdateSubscriptionLevel = () => {
        updateUserProfile({subscriptionLevel: selectedSubscriptionLevel, subscribed: (selectedSubscriptionLevel !== 'none')})
            .then(result=>{
                closeModal("subscription"); 
                addNotification("Updated subscription preferences")})
    }

    const handleDrawer = (id, action) => {
        setDrawers((prevDrawers) => ({
            ...prevDrawers,
            [id]: (action === "open")
        }));
    }

    const handleModal = (id, action) => {
        setModals((prevModals) => ({
            ...prevModals,
            [id]: (action === "open")
        }));
    }

    const openDrawer = (id) => handleDrawer(id, "open")
    const closeDrawer = (id) => {handleDrawer(id, "close");};

    const openModal = (id) => handleModal(id, "open")
    const closeModal = (id) => {handleModal(id, "close");};

    return (
        <>
        {isLoaded ?
        <Container fluid className="p-0">
            <Row className="justify-content-md-center">
                <Col lg={12}>
                    <div className="my-2 mt-md-1" style={{display: "flex", flexWrap: "wrap"}}>
                        <div style={{maxWidth: "100%"}}>
                            <QuickActions className="me-auto mb-2 my-lg-0">
                                <Link onClick={()=>openModal("changePassword")}>Change password</Link>
                                <Link onClick={()=>openModal("subscription")}>Subscription</Link>
                            </QuickActions> 
                        </div>
                    </div>  
                </Col>
            </Row>


            <form onSubmit={handleSubmit(handleSubmission, handleError)}>
                <Row className="justify-content-md-center">
                    <Col  style={{width:"100%", maxWidth: "500px"}}>
                        <h5 className="text-center">Contact information</h5>
                        <Container fluid className="p-0 m-0 text-start">
                                <Row>
                                    <Col>
                                        <Controller
                                            name="name"
                                            control={control}
                                            rules={formOptions.name}
                                            render={({field}) => (
                                                <TextInput 
                                                    id="name"
                                                    floatingLabel={<>Name</>}
                                                    errorMessage={errors?.name?.message}
                                                    {...field} 
                                                />
                                                )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={formOptions.email}
                                            render={({field}) => (
                                                <TextInput 
                                                    id="email"
                                                    floatingLabel={<>Email</>}
                                                    errorMessage={errors?.email?.message}
                                                    readOnly={true}
                                                    {...field}
                                                />
                                                )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            rules={formOptions.phone}
                                            render={({field}) => (
                                                <TextInput 
                                                    id="phone"
                                                    floatingLabel={<>Phone</>}
                                                    errorMessage={errors?.phone?.message}
                                                    {...field}
                                                />
                                                )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Controller
                                            name="organization"
                                            control={control}
                                            rules={formOptions.organization}
                                            render={({field}) => (
                                                <TextInput 
                                                    id="phone"
                                                    floatingLabel={<>Organization</>}
                                                    errorMessage={errors?.organization?.message}
                                                    {...field}
                                                />
                                                )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Controller
                                            name="country"
                                            control={control}
                                            rules={formOptions.country}
                                            render={({field}) => (
                                                <SelectList 
                                                    id="country"
                                                    options={Utils.getCountriesList().map(c=>({label: c.name, value: c.iso}))} 
                                                    placeholder="Country of residence"
                                                    searchable
                                                    preselected={[userProfile.country]}
                                                    errorMessage={errors?.country?.message}
                                                    {...field}
                                                />
                                            )} 
                                        />                      
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Controller
                                            name="timezone"
                                            control={control}
                                            rules={formOptions.timezone}
                                            render={({field}) => (
                                                <SelectList 
                                                    id="timezone"
                                                    options={timezones.map(tz=>({label: tz.utc_offset + " " + tz.timezone_name, value: tz.timezone_name}))} 
                                                    placeholder="Timezone"
                                                    searchable
                                                    preselected={[userProfile.timezone]}
                                                    errorMessage={errors?.timezone?.message}
                                                    {...field}
                                                />
                                            )} 
                                        />        

                                                
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center mt-3">
                                        <Button type="submit" style={{width:"100%", maxWidth: "300px"}}>Save</Button>
                                    </Col>
                                </Row>                            
                            </Container> 
                    </Col>

                </Row>
                <Row>
                    <Col className="text-start mt-4">
                        
                    </Col>
                </Row>
            </form>
        
        </Container>
        : <Loader label="Loading profile" position="absolute" />
        }

        <Modal show={modals?.subscription} onHide={()=>closeModal("subscription")} size="lg" header="Subscription preferences">
            <div className="text-start mb-3">
                {[
                    {value: "promo", label: "Receive promotional marketing"},
                    {value: "basic", label: "Receive only important system updates"},
                    {value: "none", label: "Unsubscribed from messages"},
                ].map(option=>(
                    <>
                    <label onClick={()=>setSelectedSubscriptionLevel(option.value)}>
                        <input name="sub" type="radio" value={option.value} checked={(selectedSubscriptionLevel === option.value)}/> {option.label}
                    </label><br />
                    </>
                    ))
                }

                <p className="mt-3 mb-0"><small>Transactional notifications will be sent regardless of subscription settings.</small></p>
            </div>
            <div className="w-100 text-center">
                <Button onClick={()=>handleUpdateSubscriptionLevel()}>Update subscription preference</Button>
            </div>
        </Modal>
        <Modal show={modals?.changePassword} onHide={()=>closeModal("changePassword")} size="md" header="Change your password">
            <UpdateUserPasswordForm handleClose={closeModal}/>
        </Modal>
        </>
    );
}

export default ProfilePage;