import {useState} from 'react';
import './ProgressBar.css';
import {default as BSProgressBar} from 'react-bootstrap/ProgressBar';

const ProgressBar = (props) => {
    return (
        <BSProgressBar className="progress-bar-class" style={{background: "#aaa"}} now={props.percent} label={`${props.label ? props.label : ""} ${props.percent}%`} />
    );
}

export default ProgressBar;