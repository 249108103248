import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ExpandableBox, Icon, Loader } from '../../../../components/components';
import { QuickActions } from '../../../../containers/containers';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { generatePaymentLink, getUserPayments } from '../../../../api/actions';
import { formatNumber } from '../../../../utils/common';
import dayjs from 'dayjs';

const PaymentsPage = () => {

    const [data, setData] = useState([]);
    const [groupedData, setGroupedData] = useState({});
    const [message, setMessage] = useState(<Loader label="Loading payments"/>);
    const [dataType, setDataType] = useState("datatable");

    useEffect(()=>{
        getUserPayments().then(p=>{
            setData(p);

            if(p.length === 0) {
                setMessage(<>No payments were made in this account yet. <br/><br /><Link to="/account/top-up"><Button>Add funds</Button></Link></>)
            } else {
                setMessage(false);
            }
        })
    },[]);

    useEffect(()=>{
        if(data?.rows?.length > 0) {

            const grouped = {};

            for(let row of data.rows) {
                const month = dayjs(row[col("time")]).format("MMMM YYYY");
                if(!grouped[month]) grouped[month] = {payments:[], total:0};

                grouped[month].payments.push(row);
                grouped[month].total += row[col("amountUsd")];
            }

            setGroupedData(grouped);
        }
    }, [data]);

    const col = (columnName) => dataType === "datatable" ? data.columns.indexOf(columnName) : columnName;

    return (
        <Container fluid className="p-0">
            <Row className="justify-content-md-center">
                <Col lg={12}>
                    <div className="my-2 mt-md-1" style={{display: "flex", flexWrap: "wrap"}}>
                        <div style={{maxWidth: "100%"}}>
                            <QuickActions className="my-2 my-lg-0 me-auto">
                                <span className="text-nowrap" ><Link to="/account/top-up"><Icon iconName="PlusLg" size="1rem" /> Add funds</Link></span>
                            </QuickActions> 
                        </div>
                    </div>  
                    <div className="text-start mb-3">
                        <h4 className="mb-3">Payments history</h4>
                        {data && data?.rows && 
                            Object.keys(groupedData).map((month)=>(
                                <ExpandableBox header={<table className="w-100"><tr><td><b className="me-2">{month}</b></td> <td className="text-end"><span className="text-secondary me-2">{groupedData[month].payments.length} Payments, ${formatNumber(groupedData[month].total,1)}</span></td></tr></table>} className="mb-3">
                                    <div style={{overflow: "auto"}}>
                                    <table className="table text-nowrap" style={{fontSize: "0.8rem"}}>
                                        <thead>
                                            <tr><th>Time</th><th>Amount</th><th>Status</th><th>Reference</th></tr>
                                        </thead>
                                        <tbody>
                                        {groupedData[month].payments.map((payment, index)=>(
                                            <tr>
                                                <td>{dayjs(payment[col("time")]).format("D/M/YYYY")}</td>
                                                <td><b>${payment[col("amountUsd")]}</b></td> 
                                                <td>{payment[col("status")]} via {payment[col("method")]}</td>
                                                <td>{payment[col("reference")]}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    </div>
                                </ExpandableBox>
                            ))
                        } 
                        {message &&
                        <div className="p-2 my-2 text-center">
                            {message}
                        </div>
                        }    

                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default PaymentsPage;