import Icon from '../../components/Icon/Icon';

const NotFoundPage = () => {
    return (
        <>
            <p>Page not found</p>
            <Icon iconName="Link" size="120" style={{color: "blue"}}/>
        </>
    );
}

export default NotFoundPage;