export const countriesObj = [
	{
		iso: "AC",
		iso3: "ASC",
		name: "Ascension",
		continent: "",
		timezone: "0",
		capital: "Georgetown",
		currency: "SHP",
		phoneCode: "247"
	},
	{
		iso: "AD",
		iso3: "AND",
		name: "Andorra",
		continent: "Europe",
		timezone: "1",
		capital: "Andorra la Vella",
		currency: "EUR",
		phoneCode: "376"
	},
	{
		iso: "AE",
		iso3: "ARE",
		name: "United Arab Emirates",
		continent: "Middle East",
		timezone: "4",
		capital: "Abu Dhabi",
		currency: "AED",
		phoneCode: "971"
	},
	{
		iso: "AF",
		iso3: "AFG",
		name: "Afghanistan",
		continent: "Asia",
		timezone: "4.5",
		capital: "Kabul",
		currency: "AFN",
		phoneCode: "93"
	},
	{
		iso: "AG",
		iso3: "ATG",
		name: "Antigua and Barbuda",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Saint Johns",
		currency: "XCD",
		phoneCode: "1267"
	},
	{
		iso: "AI",
		iso3: "AIA",
		name: "Anguilla",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "The Valley",
		currency: "XCD",
		phoneCode: "1263"
	},
	{
		iso: "AL",
		iso3: "ALB",
		name: "Albania",
		continent: "Europe",
		timezone: "1",
		capital: "Tirana",
		currency: "ALL",
		phoneCode: "355"
	},
	{
		iso: "AM",
		iso3: "ARM",
		name: "Armenia",
		continent: "Asia",
		timezone: "4",
		capital: "Yerevan",
		currency: "AMD",
		phoneCode: "374"
	},
	{
		iso: "AN",
		iso3: "ANT",
		name: "Netherlands Antilles",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Willemstad",
		currency: "ANG",
		phoneCode: "599"
	},
	{
		iso: "AO",
		iso3: "AGO",
		name: "Angola",
		continent: "Africa",
		timezone: "1",
		capital: "Luanda",
		currency: "AOA",
		phoneCode: "244"
	},
	{
		iso: "AR",
		iso3: "ARG",
		name: "Argentina",
		continent: "South America",
		timezone: "-3",
		capital: "Buenos Aires",
		currency: "ARS",
		phoneCode: "54"
	},
	{
		iso: "AS",
		iso3: "ASM",
		name: "American Samoa",
		continent: "Oceania",
		timezone: "-11",
		capital: "Pago Pago",
		currency: "USD",
		phoneCode: "1683"
	},
	{
		iso: "AT",
		iso3: "AUT",
		name: "Austria",
		continent: "Europe",
		timezone: "1",
		capital: "Vienna",
		currency: "EUR",
		phoneCode: "43"
	},
	{
		iso: "AU",
		iso3: "AUS",
		name: "Australia",
		continent: "Oceania",
		timezone: "10",
		capital: "Canberra",
		currency: "AUD",
		phoneCode: "61"
	},
	{
		iso: "AW",
		iso3: "ABW",
		name: "Aruba",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Oranjestad",
		currency: "AWG",
		phoneCode: "297"
	},
	{
		iso: "AX",
		iso3: "ALA",
		name: "Aland",
		continent: "",
		timezone: "2",
		capital: "Mariehamn",
		currency: "EUR",
		phoneCode: "340"
	},
	{
		iso: "AZ",
		iso3: "AZE",
		name: "Azerbaijan",
		continent: "Asia",
		timezone: "4",
		capital: "Baku",
		currency: "AZN",
		phoneCode: "994"
	},
	{
		iso: "BA",
		iso3: "BIH",
		name: "Bosnia and Herzegovina",
		continent: "Europe",
		timezone: "1",
		capital: "Sarajevo",
		currency: "BAM",
		phoneCode: "387"
	},
	{
		iso: "BB",
		iso3: "BRB",
		name: "Barbados",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Bridgetown",
		currency: "BBD",
		phoneCode: "1245"
	},
	{
		iso: "BD",
		iso3: "BGD",
		name: "Bangladesh",
		continent: "Asia",
		timezone: "6",
		capital: "Dhaka",
		currency: "BDT",
		phoneCode: "880"
	},
	{
		iso: "BE",
		iso3: "BEL",
		name: "Belgium",
		continent: "Europe",
		timezone: "1",
		capital: "Brussels",
		currency: "EUR",
		phoneCode: "32"
	},
	{
		iso: "BF",
		iso3: "BFA",
		name: "Burkina Faso",
		continent: "Africa",
		timezone: "0",
		capital: "Ouagadougou",
		currency: "XOF",
		phoneCode: "226"
	},
	{
		iso: "BG",
		iso3: "BGR",
		name: "Bulgaria",
		continent: "Europe",
		timezone: "2",
		capital: "Sofia",
		currency: "BGN",
		phoneCode: "359"
	},
	{
		iso: "BH",
		iso3: "BHR",
		name: "Bahrain",
		continent: "Middle East",
		timezone: "3",
		capital: "Manama",
		currency: "BHD",
		phoneCode: "973"
	},
	{
		iso: "BI",
		iso3: "BDI",
		name: "Burundi",
		continent: "Africa",
		timezone: "3",
		capital: "Bujumbura",
		currency: "BIF",
		phoneCode: "257"
	},
	{
		iso: "BJ",
		iso3: "BEN",
		name: "Benin",
		continent: "Africa",
		timezone: "1",
		capital: "Porto-Novo",
		currency: "XOF",
		phoneCode: "229"
	},
	{
		iso: "BM",
		iso3: "BMU",
		name: "Bermuda",
		continent: "North America",
		timezone: "-4",
		capital: "Hamilton",
		currency: "BMD",
		phoneCode: "1440"
	},
	{
		iso: "BN",
		iso3: "BRN",
		name: "Brunei Darussalam",
		continent: "Southeast Asia",
		timezone: "8",
		capital: "Bandar Seri Begawan",
		currency: "BND",
		phoneCode: "673"
	},
	{
		iso: "BO",
		iso3: "BOL",
		name: "Bolivia",
		continent: "South America",
		timezone: "-4",
		capital: "La Paz",
		currency: "BOB",
		phoneCode: "591"
	},
	{
		iso: "BR",
		iso3: "BRA",
		name: "Brazil",
		continent: "South America",
		timezone: "-2",
		capital: "Brasilia",
		currency: "BRL",
		phoneCode: "55"
	},
	{
		iso: "BS",
		iso3: "BHS",
		name: "Bahamas",
		continent: "Central America and the Caribbean",
		timezone: "-5",
		capital: "Nassau",
		currency: "BSD",
		phoneCode: "1241"
	},
	{
		iso: "BT",
		iso3: "BTN",
		name: "Bhutan",
		continent: "Asia",
		timezone: "6",
		capital: "Thimphu",
		currency: "BTN",
		phoneCode: "975"
	},
	{
		iso: "BW",
		iso3: "BWA",
		name: "Botswana",
		continent: "Africa",
		timezone: "2",
		capital: "Gaborone",
		currency: "BWP",
		phoneCode: "267"
	},
	{
		iso: "BY",
		iso3: "BLR",
		name: "Belarus",
		continent: "Europe",
		timezone: "2",
		capital: "Minsk",
		currency: "BYR",
		phoneCode: "375"
	},
	{
		iso: "BZ",
		iso3: "BLZ",
		name: "Belize",
		continent: "Central America and the Caribbean",
		timezone: "-6",
		capital: "Belmopan",
		currency: "BZD",
		phoneCode: "501"
	},
	{
		iso: "CA",
		iso3: "CAN",
		name: "Canada",
		continent: "North America",
		timezone: "-3.5",
		capital: "Ottawa",
		currency: "CAD",
		phoneCode: "1"
	},
	{
		iso: "CC",
		iso3: "CCK",
		name: "Cocos (Keeling) Islands",
		continent: "",
		timezone: "6.5",
		capital: "West Island",
		currency: "AUD",
		phoneCode: "61"
	},
	{
		iso: "CD",
		iso3: "COD",
		name: "Democratic Republic of the Congo",
		continent: "Africa",
		timezone: "1",
		capital: "Kinshasa",
		currency: "CDF",
		phoneCode: "243"
	},
	{
		iso: "CF",
		iso3: "CAF",
		name: "Central African Republic",
		continent: "Africa",
		timezone: "1",
		capital: "Bangui",
		currency: "XAF",
		phoneCode: "236"
	},
	{
		iso: "CG",
		iso3: "COG",
		name: "Republic of the Congo",
		continent: "Africa",
		timezone: "1",
		capital: "Brazzaville",
		currency: "XAF",
		phoneCode: "242"
	},
	{
		iso: "CH",
		iso3: "CHE",
		name: "Switzerland",
		continent: "Europe",
		timezone: "1",
		capital: "Bern",
		currency: "CHF",
		phoneCode: "41"
	},
	{
		iso: "CI",
		iso3: "CIV",
		name: "Cote dIvoire (Ivory Coast)",
		continent: "Africa",
		timezone: "0",
		capital: "Yamoussoukro",
		currency: "XOF",
		phoneCode: "225"
	},
	{
		iso: "CK",
		iso3: "COK",
		name: "Cook Islands",
		continent: "Oceania",
		timezone: "-10",
		capital: "Avarua",
		currency: "NZD",
		phoneCode: "682"
	},
	{
		iso: "CL",
		iso3: "CHL",
		name: "Chile",
		continent: "South America",
		timezone: "-4",
		capital: "Santiago",
		currency: "CLP",
		phoneCode: "56"
	},
	{
		iso: "CM",
		iso3: "CMR",
		name: "Cameroon",
		continent: "Africa",
		timezone: "1",
		capital: "Yaounde",
		currency: "XAF",
		phoneCode: "237"
	},
	{
		iso: "CN",
		iso3: "CHN",
		name: "China",
		continent: "Asia",
		timezone: "8",
		capital: "Beijing",
		currency: "CNY",
		phoneCode: "86"
	},
	{
		iso: "CO",
		iso3: "COL",
		name: "Colombia",
		continent: "South America",
		timezone: "-5",
		capital: "Bogota",
		currency: "COP",
		phoneCode: "57"
	},
	{
		iso: "CR",
		iso3: "CRI",
		name: "Costa Rica",
		continent: "Central America and the Caribbean",
		timezone: "-6",
		capital: "San Jose",
		currency: "CRC",
		phoneCode: "506"
	},
	{
		iso: "CU",
		iso3: "CUB",
		name: "Cuba",
		continent: "Central America and the Caribbean",
		timezone: "-5",
		capital: "Havana",
		currency: "CUP",
		phoneCode: "53"
	},
	{
		iso: "CV",
		iso3: "CPV",
		name: "Cape Verde",
		continent: "Africa",
		timezone: "-1",
		capital: "Praia",
		currency: "CVE",
		phoneCode: "238"
	},
	{
		iso: "CX",
		iso3: "CXR",
		name: "Christmas Island",
		continent: "",
		timezone: "14",
		capital: "The Settlement",
		currency: "AUD",
		phoneCode: "61"
	},
	{
		iso: "CY",
		iso3: "CYP",
		name: "Cyprus",
		continent: "Middle East",
		timezone: "2",
		capital: "Nicosia",
		currency: "CYP",
		phoneCode: "357"
	},
	{
		iso: "CZ",
		iso3: "CZE",
		name: "Czech Republic",
		continent: "Europe",
		timezone: "1",
		capital: "Prague",
		currency: "CZK",
		phoneCode: "420"
	},
	{
		iso: "DE",
		iso3: "DEU",
		name: "Germany",
		continent: "Europe",
		timezone: "1",
		capital: "Berlin",
		currency: "EUR",
		phoneCode: "49"
	},
	{
		iso: "DJ",
		iso3: "DJI",
		name: "Djibouti",
		continent: "Africa",
		timezone: "3",
		capital: "Djibouti",
		currency: "DJF",
		phoneCode: "253"
	},
	{
		iso: "DK",
		iso3: "DNK",
		name: "Denmark",
		continent: "Europe",
		timezone: "1",
		capital: "Copenhagen",
		currency: "DKK",
		phoneCode: "45"
	},
	{
		iso: "DM",
		iso3: "DMA",
		name: "Dominica",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Roseau",
		currency: "XCD",
		phoneCode: "1766"
	},
	{
		iso: "DO",
		iso3: "DOM",
		name: "Dominican Republic",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Santo Domingo",
		currency: "DOP",
		phoneCode: "1809"
	},
	{
		iso: "DZ",
		iso3: "DZA",
		name: "Algeria",
		continent: "Africa",
		timezone: "1",
		capital: "Algiers",
		currency: "DZD",
		phoneCode: "213"
	},
	{
		iso: "EC",
		iso3: "ECU",
		name: "Ecuador",
		continent: "South America",
		timezone: "-5",
		capital: "Quito",
		currency: "USD",
		phoneCode: "593"
	},
	{
		iso: "EE",
		iso3: "EST",
		name: "Estonia",
		continent: "Europe",
		timezone: "2",
		capital: "Tallinn",
		currency: "EEK",
		phoneCode: "372"
	},
	{
		iso: "EG",
		iso3: "EGY",
		name: "Egypt",
		continent: "Africa",
		timezone: "2",
		capital: "Cairo",
		currency: "EGP",
		phoneCode: "20"
	},
	{
		iso: "ER",
		iso3: "ERI",
		name: "Eritrea",
		continent: "Africa",
		timezone: "3",
		capital: "Asmara",
		currency: "ERN",
		phoneCode: "291"
	},
	{
		iso: "ES",
		iso3: "ESP",
		name: "Spain",
		continent: "Europe",
		timezone: "1",
		capital: "Madrid",
		currency: "EUR",
		phoneCode: "34"
	},
	{
		iso: "ET",
		iso3: "ETH",
		name: "Ethiopia",
		continent: "Africa",
		timezone: "3",
		capital: "Addis Ababa",
		currency: "ETB",
		phoneCode: "251"
	},
	{
		iso: "FI",
		iso3: "FIN",
		name: "Finland",
		continent: "Europe",
		timezone: "2",
		capital: "Helsinki",
		currency: "EUR",
		phoneCode: "358"
	},
	{
		iso: "FJ",
		iso3: "FJI",
		name: "Fiji",
		continent: "Oceania",
		timezone: "12",
		capital: "Suva",
		currency: "FJD",
		phoneCode: "679"
	},
	{
		iso: "FK",
		iso3: "FLK",
		name: "Falkland Islands (Islas Malvinas)",
		continent: "South America",
		timezone: "-3",
		capital: "Stanley",
		currency: "FKP",
		phoneCode: "500"
	},
	{
		iso: "FM",
		iso3: "FSM",
		name: "Micronesia",
		continent: "",
		timezone: "11",
		capital: "Palikir",
		currency: "USD",
		phoneCode: "691"
	},
	{
		iso: "FO",
		iso3: "FRO",
		name: "Faroe Islands",
		continent: "Europe",
		timezone: "0",
		capital: "Torshavn",
		currency: "DKK",
		phoneCode: "298"
	},
	{
		iso: "FR",
		iso3: "FRA",
		name: "France",
		continent: "Europe",
		timezone: "1",
		capital: "Paris",
		currency: "EUR",
		phoneCode: "33"
	},
	{
		iso: "GA",
		iso3: "GAB",
		name: "Gabon",
		continent: "Africa",
		timezone: "1",
		capital: "Libreville",
		currency: "XAF",
		phoneCode: "241"
	},
	{
		iso: "GB",
		iso3: "GBR",
		name: "United Kingdom",
		continent: "Europe",
		timezone: "0",
		capital: "London",
		currency: "GBP",
		phoneCode: "44"
	},
	{
		iso: "GD",
		iso3: "GRD",
		name: "Grenada",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Saint Georges",
		currency: "XCD",
		phoneCode: "1472"
	},
	{
		iso: "GE",
		iso3: "GEO",
		name: "Georgia",
		continent: "Asia",
		timezone: "4",
		capital: "Tbilisi",
		currency: "GEL",
		phoneCode: "995"
	},
	{
		iso: "GF",
		iso3: "GUF",
		name: "French Guiana",
		continent: "South America",
		timezone: "-3",
		capital: "Cayenne",
		currency: "EUR",
		phoneCode: "594"
	},
	{
		iso: "GG",
		iso3: "GGY",
		name: "Guernsey",
		continent: "Europe",
		timezone: "0",
		capital: "Saint Peter Port",
		currency: "GGP",
		phoneCode: "44"
	},
	{
		iso: "GH",
		iso3: "GHA",
		name: "Ghana",
		continent: "Africa",
		timezone: "0",
		capital: "Accra",
		currency: "GHC",
		phoneCode: "233"
	},
	{
		iso: "GI",
		iso3: "GIB",
		name: "Gibraltar",
		continent: "Europe",
		timezone: "1",
		capital: "Gibraltar",
		currency: "GIP",
		phoneCode: "350"
	},
	{
		iso: "GL",
		iso3: "GRL",
		name: "Greenland",
		continent: "Arctic Region",
		timezone: "-3",
		capital: "Nuuk",
		currency: "DKK",
		phoneCode: "299"
	},
	{
		iso: "GM",
		iso3: "GMB",
		name: "Gambia",
		continent: "Africa",
		timezone: "0",
		capital: "Banjul",
		currency: "GMD",
		phoneCode: "220"
	},
	{
		iso: "GN",
		iso3: "GIN",
		name: "Guinea",
		continent: "Africa",
		timezone: "0",
		capital: "Conakry",
		currency: "GNF",
		phoneCode: "224"
	},
	{
		iso: "GP",
		iso3: "GLP",
		name: "Guadeloupe",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Basse-Terre",
		currency: "EUR",
		phoneCode: "590"
	},
	{
		iso: "GP",
		iso3: "GLP",
		name: "Saint Martin",
		continent: "",
		timezone: "-4",
		capital: "Marigot",
		currency: "EUR",
		phoneCode: "590"
	},
	{
		iso: "GQ",
		iso3: "GNQ",
		name: "Equatorial Guinea",
		continent: "Africa",
		timezone: "1",
		capital: "Malabo",
		currency: "XAF",
		phoneCode: "240"
	},
	{
		iso: "GR",
		iso3: "GRC",
		name: "Greece",
		continent: "Europe",
		timezone: "2",
		capital: "Athens",
		currency: "EUR",
		phoneCode: "30"
	},
	{
		iso: "GT",
		iso3: "GTM",
		name: "Guatemala",
		continent: "Central America and the Caribbean",
		timezone: "-6",
		capital: "Guatemala",
		currency: "GTQ",
		phoneCode: "502"
	},
	{
		iso: "GU",
		iso3: "GUM",
		name: "Guam",
		continent: "Oceania",
		timezone: "10",
		capital: "Hagatna",
		currency: "USD",
		phoneCode: "1670"
	},
	{
		iso: "GW",
		iso3: "GNB",
		name: "Guinea-Bissau",
		continent: "Africa",
		timezone: "0",
		capital: "Bissau",
		currency: "XOF",
		phoneCode: "245"
	},
	{
		iso: "GY",
		iso3: "GUY",
		name: "Guyana",
		continent: "South America",
		timezone: "-3",
		capital: "Georgetown",
		currency: "GYD",
		phoneCode: "592"
	},
	{
		iso: "HK",
		iso3: "HKG",
		name: "Hong Kong",
		continent: "Southeast Asia",
		timezone: "8",
		capital: "Hong Kong",
		currency: "HKD",
		phoneCode: "852"
	},
	{
		iso: "HN",
		iso3: "HND",
		name: "Honduras",
		continent: "Central America and the Caribbean",
		timezone: "-6",
		capital: "Tegucigalpa",
		currency: "HNL",
		phoneCode: "504"
	},
	{
		iso: "HR",
		iso3: "HRV",
		name: "Croatia",
		continent: "Europe",
		timezone: "1",
		capital: "Zagreb",
		currency: "HRK",
		phoneCode: "385"
	},
	{
		iso: "HT",
		iso3: "HTI",
		name: "Haiti",
		continent: "Central America and the Caribbean",
		timezone: "-5",
		capital: "Port-au-Prince",
		currency: "HTG",
		phoneCode: "509"
	},
	{
		iso: "HU",
		iso3: "HUN",
		name: "Hungary",
		continent: "Europe",
		timezone: "1",
		capital: "Budapest",
		currency: "HUF",
		phoneCode: "36"
	},
	{
		iso: "ID",
		iso3: "IDN",
		name: "Indonesia",
		continent: "Southeast Asia",
		timezone: "7",
		capital: "Jakarta",
		currency: "IDR",
		phoneCode: "62"
	},
	{
		iso: "IE",
		iso3: "IRL",
		name: "Ireland",
		continent: "Europe",
		timezone: "0",
		capital: "Dublin",
		currency: "EUR",
		phoneCode: "353"
	},
	{
		iso: "IL",
		iso3: "ISR",
		name: "Israel",
		continent: "Middle East",
		timezone: "2",
		capital: "Jerusalem",
		currency: "ILS",
		phoneCode: "972"
	},
	{
		iso: "IM",
		iso3: "IMN",
		name: "Isle of Man",
		continent: "Europe",
		timezone: "0",
		capital: "Douglas",
		currency: "IMP",
		phoneCode: "44"
	},
	{
		iso: "IN",
		iso3: "IND",
		name: "India",
		continent: "Asia",
		timezone: "5.5",
		capital: "New Delhi",
		currency: "INR",
		phoneCode: "91"
	},
	{
		iso: "IQ",
		iso3: "IRQ",
		name: "Iraq",
		continent: "Middle East",
		timezone: "3",
		capital: "Baghdad",
		currency: "IQD",
		phoneCode: "964"
	},
	{
		iso: "IR",
		iso3: "IRN",
		name: "Iran",
		continent: "Middle East",
		timezone: "3.5",
		capital: "Tehran",
		currency: "IRR",
		phoneCode: "98"
	},
	{
		iso: "IS",
		iso3: "ISL",
		name: "Iceland",
		continent: "Arctic Region",
		timezone: "0",
		capital: "Reykjavik",
		currency: "ISK",
		phoneCode: "354"
	},
	{
		iso: "IT",
		iso3: "ITA",
		name: "Italy",
		continent: "Europe",
		timezone: "1",
		capital: "Rome",
		currency: "EUR",
		phoneCode: "39"
	},
	{
		iso: "JE",
		iso3: "JEY",
		name: "Jersey",
		continent: "Europe",
		timezone: "0",
		capital: "Saint Helier",
		currency: "JEP",
		phoneCode: "44"
	},
	{
		iso: "JM",
		iso3: "JAM",
		name: "Jamaica",
		continent: "Central America and the Caribbean",
		timezone: "-5",
		capital: "Kingston",
		currency: "JMD",
		phoneCode: "1875"
	},
	{
		iso: "JO",
		iso3: "JOR",
		name: "Jordan",
		continent: "Middle East",
		timezone: "2",
		capital: "Amman",
		currency: "JOD",
		phoneCode: "962"
	},
	{
		iso: "JP",
		iso3: "JPN",
		name: "Japan",
		continent: "Asia",
		timezone: "9",
		capital: "Tokyo",
		currency: "JPY",
		phoneCode: "81"
	},
	{
		iso: "KE",
		iso3: "KEN",
		name: "Kenya",
		continent: "Africa",
		timezone: "3",
		capital: "Nairobi",
		currency: "KES",
		phoneCode: "254"
	},
	{
		iso: "KG",
		iso3: "KGZ",
		name: "Kyrgyzstan",
		continent: "Asia",
		timezone: "5",
		capital: "Bishkek",
		currency: "KGS",
		phoneCode: "996"
	},
	{
		iso: "KH",
		iso3: "KHM",
		name: "Cambodia",
		continent: "Southeast Asia",
		timezone: "7",
		capital: "Phnom Penh",
		currency: "KHR",
		phoneCode: "855"
	},
	{
		iso: "KI",
		iso3: "KIR",
		name: "Kiribati",
		continent: "Oceania",
		timezone: "12",
		capital: "Tarawa",
		currency: "AUD",
		phoneCode: "686"
	},
	{
		iso: "KM",
		iso3: "COM",
		name: "Comoros",
		continent: "Africa",
		timezone: "3",
		capital: "Moroni",
		currency: "KMF",
		phoneCode: "269"
	},
	{
		iso: "KN",
		iso3: "KNA",
		name: "Saint Kitts and Nevis",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Basseterre",
		currency: "XCD",
		phoneCode: "1868"
	},
	{
		iso: "KP",
		iso3: "PRK",
		name: "North Korea",
		continent: "Asia",
		timezone: "9",
		capital: "Pyongyang",
		currency: "KPW",
		phoneCode: "850"
	},
	{
		iso: "KR",
		iso3: "KOR",
		name: "South Korea",
		continent: "Asia",
		timezone: "9",
		capital: "Seoul",
		currency: "KRW",
		phoneCode: "82"
	},
	{
		iso: "KW",
		iso3: "KWT",
		name: "Kuwait",
		continent: "Middle East",
		timezone: "3",
		capital: "Kuwait",
		currency: "KWD",
		phoneCode: "965"
	},
	{
		iso: "KY",
		iso3: "CYM",
		name: "Cayman Islands",
		continent: "Central America and the Caribbean",
		timezone: "-5",
		capital: "George Town",
		currency: "KYD",
		phoneCode: "1344"
	},
	{
		iso: "KZ",
		iso3: "KAZ",
		name: "Kazakhstan",
		continent: "Asia",
		timezone: "4",
		capital: "Astana",
		currency: "KZT",
		phoneCode: "7"
	},
	{
		iso: "LA",
		iso3: "LAO",
		name: "Laos",
		continent: "Southeast Asia",
		timezone: "7",
		capital: "Vientiane",
		currency: "LAK",
		phoneCode: "856"
	},
	{
		iso: "LB",
		iso3: "LBN",
		name: "Lebanon",
		continent: "Middle East",
		timezone: "2",
		capital: "Beirut",
		currency: "LBP",
		phoneCode: "961"
	},
	{
		iso: "LC",
		iso3: "LCA",
		name: "Saint Lucia",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Castries",
		currency: "XCD",
		phoneCode: "1757"
	},
	{
		iso: "LI",
		iso3: "LIE",
		name: "Liechtenstein",
		continent: "Europe",
		timezone: "1",
		capital: "Vaduz",
		currency: "CHF",
		phoneCode: "423"
	},
	{
		iso: "LK",
		iso3: "LKA",
		name: "Sri Lanka",
		continent: "Asia",
		timezone: "5.5",
		capital: "Colombo",
		currency: "LKR",
		phoneCode: "94"
	},
	{
		iso: "LR",
		iso3: "LBR",
		name: "Liberia",
		continent: "Africa",
		timezone: "0",
		capital: "Monrovia",
		currency: "LRD",
		phoneCode: "231"
	},
	{
		iso: "LS",
		iso3: "LSO",
		name: "Lesotho",
		continent: "Africa",
		timezone: "3",
		capital: "Maseru",
		currency: "LSL",
		phoneCode: "266"
	},
	{
		iso: "LT",
		iso3: "LTU",
		name: "Lithuania",
		continent: "Europe",
		timezone: "2",
		capital: "Vilnius",
		currency: "LTL",
		phoneCode: "370"
	},
	{
		iso: "LU",
		iso3: "LUX",
		name: "Luxembourg",
		continent: "Europe",
		timezone: "1",
		capital: "Luxembourg",
		currency: "EUR",
		phoneCode: "352"
	},
	{
		iso: "LV",
		iso3: "LVA",
		name: "Latvia",
		continent: "Europe",
		timezone: "2",
		capital: "Riga",
		currency: "LVL",
		phoneCode: "371"
	},
	{
		iso: "LY",
		iso3: "LBY",
		name: "Libya",
		continent: "Africa",
		timezone: "2",
		capital: "Tripoli",
		currency: "LYD",
		phoneCode: "218"
	},
	{
		iso: "MA",
		iso3: "MAR",
		name: "Morocco",
		continent: "Africa",
		timezone: "0",
		capital: "Rabat",
		currency: "MAD",
		phoneCode: "212"
	},
	{
		iso: "MC",
		iso3: "MCO",
		name: "Monaco",
		continent: "Europe",
		timezone: "1",
		capital: "Monaco",
		currency: "EUR",
		phoneCode: "377"
	},
	{
		iso: "MD",
		iso3: "MDA",
		name: "Moldova",
		continent: "Europe",
		timezone: "2",
		capital: "Chisinau",
		currency: "MDL",
		phoneCode: "373"
	},
	{
		iso: "ME",
		iso3: "MNE",
		name: "Montenegro",
		continent: "Europe",
		timezone: "1",
		capital: "Podgorica",
		currency: "EUR",
		phoneCode: "382"
	},
	{
		iso: "MG",
		iso3: "MDG",
		name: "Madagascar",
		continent: "Africa",
		timezone: "3",
		capital: "Antananarivo",
		currency: "MGA",
		phoneCode: "261"
	},
	{
		iso: "MH",
		iso3: "MHL",
		name: "Marshall Islands",
		continent: "Oceania",
		timezone: "12",
		capital: "Majuro",
		currency: "USD",
		phoneCode: "692"
	},
	{
		iso: "MK",
		iso3: "MKD",
		name: "Macedonia",
		continent: "Europe",
		timezone: "1",
		capital: "Skopje",
		currency: "MKD",
		phoneCode: "389"
	},
	{
		iso: "ML",
		iso3: "MLI",
		name: "Mali",
		continent: "Africa",
		timezone: "0",
		capital: "Bamako",
		currency: "XOF",
		phoneCode: "223"
	},
	{
		iso: "MM",
		iso3: "MMR",
		name: "Myanmar (Burma)",
		continent: "Southeast Asia",
		timezone: "6.5",
		capital: "Naypyidaw",
		currency: "MMK",
		phoneCode: "95"
	},
	{
		iso: "MN",
		iso3: "MNG",
		name: "Mongolia",
		continent: "Asia",
		timezone: "8",
		capital: "Ulaanbaatar",
		currency: "MNT",
		phoneCode: "976"
	},
	{
		iso: "MO",
		iso3: "MAC",
		name: "Macau",
		continent: "Southeast Asia",
		timezone: "8",
		capital: "Macau",
		currency: "MOP",
		phoneCode: "853"
	},
	{
		iso: "MP",
		iso3: "MNP",
		name: "Northern Mariana Islands",
		continent: "Oceania",
		timezone: "10",
		capital: "Saipan",
		currency: "USD",
		phoneCode: "1669"
	},
	{
		iso: "MQ",
		iso3: "MTQ",
		name: "Martinique",
		continent: "",
		timezone: "-4",
		capital: "Fort-de-France",
		currency: "EUR",
		phoneCode: "596"
	},
	{
		iso: "MR",
		iso3: "MRT",
		name: "Mauritania",
		continent: "Africa",
		timezone: "0",
		capital: "Nouakchott",
		currency: "MRO",
		phoneCode: "222"
	},
	{
		iso: "MS",
		iso3: "MSR",
		name: "Montserrat",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Plymouth",
		currency: "XCD",
		phoneCode: "1663"
	},
	{
		iso: "MT",
		iso3: "MLT",
		name: "Malta",
		continent: "Europe",
		timezone: "1",
		capital: "Valletta",
		currency: "MTL",
		phoneCode: "356"
	},
	{
		iso: "MU",
		iso3: "MUS",
		name: "Mauritius",
		continent: "Africa",
		timezone: "4",
		capital: "Port Louis",
		currency: "MUR",
		phoneCode: "230"
	},
	{
		iso: "MV",
		iso3: "MDV",
		name: "Maldives",
		continent: "Asia",
		timezone: "5",
		capital: "Male",
		currency: "MVR",
		phoneCode: "960"
	},
	{
		iso: "MW",
		iso3: "MWI",
		name: "Malawi",
		continent: "Africa",
		timezone: "2",
		capital: "Lilongwe",
		currency: "MWK",
		phoneCode: "265"
	},
	{
		iso: "MX",
		iso3: "MEX",
		name: "Mexico",
		continent: "North America",
		timezone: "-6",
		capital: "Mexico",
		currency: "MXN",
		phoneCode: "52"
	},
	{
		iso: "MY",
		iso3: "MYS",
		name: "Malaysia",
		continent: "Southeast Asia",
		timezone: "8",
		capital: "Kuala Lumpur",
		currency: "MYR",
		phoneCode: "60"
	},
	{
		iso: "MZ",
		iso3: "MOZ",
		name: "Mozambique",
		continent: "Africa",
		timezone: "2",
		capital: "Maputo",
		currency: "MZM",
		phoneCode: "258"
	},
	{
		iso: "NA",
		iso3: "NAM",
		name: "Namibia",
		continent: "Africa",
		timezone: "1",
		capital: "Windhoek",
		currency: "NAD",
		phoneCode: "264"
	},
	{
		iso: "NC",
		iso3: "NCL",
		name: "New Caledonia",
		continent: "Oceania",
		timezone: "11",
		capital: "Noumea",
		currency: "XPF",
		phoneCode: "687"
	},
	{
		iso: "NE",
		iso3: "NER",
		name: "Niger",
		continent: "Africa",
		timezone: "1",
		capital: "Niamey",
		currency: "XOF",
		phoneCode: "227"
	},
	{
		iso: "NF",
		iso3: "NFK",
		name: "Norfolk Island",
		continent: "Oceania",
		timezone: "11.5",
		capital: "Kingston",
		currency: "AUD",
		phoneCode: "672"
	},
	{
		iso: "NG",
		iso3: "NGA",
		name: "Nigeria",
		continent: "Africa",
		timezone: "1",
		capital: "Abuja",
		currency: "NGN",
		phoneCode: "234"
	},
	{
		iso: "NI",
		iso3: "NIC",
		name: "Nicaragua",
		continent: "Central America and the Caribbean",
		timezone: "-6",
		capital: "Managua",
		currency: "NIO",
		phoneCode: "505"
	},
	{
		iso: "NL",
		iso3: "NLD",
		name: "Netherlands",
		continent: "Europe",
		timezone: "1",
		capital: "Amsterdam",
		currency: "EUR",
		phoneCode: "31"
	},
	{
		iso: "NO",
		iso3: "NOR",
		name: "Norway",
		continent: "Europe",
		timezone: "1",
		capital: "Oslo",
		currency: "NOK",
		phoneCode: "47"
	},
	{
		iso: "NP",
		iso3: "NPL",
		name: "Nepal",
		continent: "Asia",
		timezone: "5.75",
		capital: "Kathmandu",
		currency: "NPR",
		phoneCode: "977"
	},
	{
		iso: "NR",
		iso3: "NRU",
		name: "Nauru",
		continent: "Oceania",
		timezone: "12",
		capital: "Yaren",
		currency: "AUD",
		phoneCode: "674"
	},
	{
		iso: "NU",
		iso3: "NIU",
		name: "Niue",
		continent: "Oceania",
		timezone: "-11",
		capital: "Alofi",
		currency: "NZD",
		phoneCode: "683"
	},
	{
		iso: "NZ",
		iso3: "NZL",
		name: "New Zealand",
		continent: "Oceania",
		timezone: "12",
		capital: "Wellington",
		currency: "NZD",
		phoneCode: "64"
	},
	{
		iso: "OM",
		iso3: "OMN",
		name: "Oman",
		continent: "Middle East",
		timezone: "4",
		capital: "Muscat",
		currency: "OMR",
		phoneCode: "968"
	},
	{
		iso: "PA",
		iso3: "PAN",
		name: "Panama",
		continent: "Central America and the Caribbean",
		timezone: "-5",
		capital: "Panama",
		currency: "PAB",
		phoneCode: "507"
	},
	{
		iso: "PE",
		iso3: "PER",
		name: "Peru",
		continent: "South America",
		timezone: "-5",
		capital: "Lima",
		currency: "PEN",
		phoneCode: "51"
	},
	{
		iso: "PF",
		iso3: "PYF",
		name: "French Polynesia",
		continent: "Oceania",
		timezone: "-9",
		capital: "Papeete",
		currency: "XPF",
		phoneCode: "689"
	},
	{
		iso: "PG",
		iso3: "PNG",
		name: "Papua New Guinea",
		continent: "Oceania",
		timezone: "10",
		capital: "Port Moresby",
		currency: "PGK",
		phoneCode: "675"
	},
	{
		iso: "PH",
		iso3: "PHL",
		name: "Philippines",
		continent: "Southeast Asia",
		timezone: "8",
		capital: "Manila",
		currency: "PHP",
		phoneCode: "63"
	},
	{
		iso: "PK",
		iso3: "PAK",
		name: "Pakistan",
		continent: "Asia",
		timezone: "5",
		capital: "Islamabad",
		currency: "PKR",
		phoneCode: "92"
	},
	{
		iso: "PL",
		iso3: "POL",
		name: "Poland",
		continent: "Europe",
		timezone: "1",
		capital: "Warsaw",
		currency: "PLN",
		phoneCode: "48"
	},
	{
		iso: "PM",
		iso3: "SPM",
		name: "Saint Pierre and Miquelon",
		continent: "North America",
		timezone: "-3",
		capital: "Saint-Pierre",
		currency: "EUR",
		phoneCode: "508"
	},
	{
		iso: "PN",
		iso3: "PCN",
		name: "Pitcairn Islands",
		continent: "Oceania",
		timezone: "-8",
		capital: "Adamstown",
		currency: "NZD",
		phoneCode: "0"
	},
	{
		iso: "PR",
		iso3: "PRI",
		name: "Puerto Rico",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "San Juan",
		currency: "USD",
		phoneCode: "1787"
	},
	{
		iso: "PS",
		iso3: "PSE",
		name: "Palestinian territories",
		continent: "",
		timezone: "2",
		capital: "Ramallah",
		currency: "ILS",
		phoneCode: "972"
	},
	{
		iso: "PT",
		iso3: "PRT",
		name: "Portugal",
		continent: "Europe",
		timezone: "0",
		capital: "Lisbon",
		currency: "EUR",
		phoneCode: "351"
	},
	{
		iso: "PW",
		iso3: "PLW",
		name: "Palau",
		continent: "Oceania",
		timezone: "9",
		capital: "Melekeok",
		currency: "USD",
		phoneCode: "680"
	},
	{
		iso: "PY",
		iso3: "PRY",
		name: "Paraguay",
		continent: "South America",
		timezone: "-4",
		capital: "Asuncion",
		currency: "PYG",
		phoneCode: "595"
	},
	{
		iso: "QA",
		iso3: "QAT",
		name: "Qatar",
		continent: "Middle East",
		timezone: "3",
		capital: "Doha",
		currency: "QAR",
		phoneCode: "974"
	},
	{
		iso: "RE",
		iso3: "REU",
		name: "Reunion",
		continent: "World",
		timezone: "4",
		capital: "Saint-Denis",
		currency: "EUR",
		phoneCode: "262"
	},
	{
		iso: "RO",
		iso3: "ROU",
		name: "Romania",
		continent: "Europe",
		timezone: "2",
		capital: "Bucharest",
		currency: "RON",
		phoneCode: "40"
	},
	{
		iso: "RS",
		iso3: "SRB",
		name: "Serbia",
		continent: "Europe",
		timezone: "1",
		capital: "Belgrade",
		currency: "RSD",
		phoneCode: "381"
	},
	{
		iso: "RU",
		iso3: "RUS",
		name: "Russia",
		continent: "Asia",
		timezone: "2",
		capital: "Moscow",
		currency: "RUB",
		phoneCode: "7"
	},
	{
		iso: "RW",
		iso3: "RWA",
		name: "Rwanda",
		continent: "Africa",
		timezone: "2",
		capital: "Kigali",
		currency: "RWF",
		phoneCode: "250"
	},
	{
		iso: "SA",
		iso3: "SAU",
		name: "Saudi Arabia",
		continent: "Middle East",
		timezone: "3",
		capital: "Riyadh",
		currency: "SAR",
		phoneCode: "966"
	},
	{
		iso: "SB",
		iso3: "SLB",
		name: "Solomon Islands",
		continent: "Oceania",
		timezone: "11",
		capital: "Honiara",
		currency: "SBD",
		phoneCode: "677"
	},
	{
		iso: "SC",
		iso3: "SYC",
		name: "Seychelles",
		continent: "Africa",
		timezone: "4",
		capital: "Victoria",
		currency: "SCR",
		phoneCode: "248"
	},
	{
		iso: "SD",
		iso3: "SDN",
		name: "Sudan",
		continent: "Africa",
		timezone: "3",
		capital: "Khartoum",
		currency: "SDD",
		phoneCode: "249"
	},
	{
		iso: "SE",
		iso3: "SWE",
		name: "Sweden",
		continent: "Europe",
		timezone: "1",
		capital: "Stockholm",
		currency: "SEK",
		phoneCode: "46"
	},
	{
		iso: "SG",
		iso3: "SGP",
		name: "Singapore",
		continent: "Southeast Asia",
		timezone: "8",
		capital: "Singapore",
		currency: "SGD",
		phoneCode: "65"
	},
	{
		iso: "SH",
		iso3: "SHN",
		name: "Saint Helena",
		continent: "Africa",
		timezone: "0",
		capital: "Jamestown",
		currency: "SHP",
		phoneCode: "290"
	},
	{
		iso: "SI",
		iso3: "SVN",
		name: "Slovenia",
		continent: "Europe",
		timezone: "1",
		capital: "Ljubljana",
		currency: "EUR",
		phoneCode: "386"
	},
	{
		iso: "SJ",
		iso3: "SJM",
		name: "Svalbard",
		continent: "Arctic Region",
		timezone: "1",
		capital: "Longyearbyen",
		currency: "NOK",
		phoneCode: "47"
	},
	{
		iso: "SK",
		iso3: "SVK",
		name: "Slovakia",
		continent: "Europe",
		timezone: "1",
		capital: "Bratislava",
		currency: "SKK",
		phoneCode: "421"
	},
	{
		iso: "SL",
		iso3: "SLE",
		name: "Sierra Leone",
		continent: "Africa",
		timezone: "0",
		capital: "Freetown",
		currency: "SLL",
		phoneCode: "232"
	},
	{
		iso: "SM",
		iso3: "SMR",
		name: "San Marino",
		continent: "Europe",
		timezone: "1",
		capital: "San Marino",
		currency: "EUR",
		phoneCode: "378"
	},
	{
		iso: "SN",
		iso3: "SEN",
		name: "Senegal",
		continent: "Africa",
		timezone: "0",
		capital: "Dakar",
		currency: "XOF",
		phoneCode: "221"
	},
	{
		iso: "SO",
		iso3: "SOM",
		name: "Somalia",
		continent: "Africa",
		timezone: "3",
		capital: "Hargeisa",
		currency: "USD",
		phoneCode: "252"
	},
	{
		iso: "SR",
		iso3: "SUR",
		name: "Suriname",
		continent: "South America",
		timezone: "-3",
		capital: "Paramaribo",
		currency: "SRD",
		phoneCode: "597"
	},
	{
		iso: "ST",
		iso3: "STP",
		name: "Sao Tome and Principe",
		continent: "Africa",
		timezone: "0",
		capital: "Sao Tome",
		currency: "STD",
		phoneCode: "239"
	},
	{
		iso: "SV",
		iso3: "SLV",
		name: "El Salvador",
		continent: "Central America and the Caribbean",
		timezone: "-6",
		capital: "San Salvador",
		currency: "USD",
		phoneCode: "503"
	},
	{
		iso: "SY",
		iso3: "SYR",
		name: "Syria",
		continent: "Middle East",
		timezone: "2",
		capital: "Damascus",
		currency: "SYP",
		phoneCode: "963"
	},
	{
		iso: "SZ",
		iso3: "SWZ",
		name: "Swaziland",
		continent: "Africa",
		timezone: "2",
		capital: "Mbabane",
		currency: "SZL",
		phoneCode: "268"
	},
	{
		iso: "TA",
		iso3: "TAA",
		name: "Tristan da Cunha",
		continent: "",
		timezone: "0",
		capital: "Edinburgh",
		currency: "SHP",
		phoneCode: "290"
	},
	{
		iso: "TC",
		iso3: "TCA",
		name: "Turks and Caicos Islands",
		continent: "Central America and the Caribbean",
		timezone: "-5",
		capital: "Grand Turk",
		currency: "USD",
		phoneCode: "1648"
	},
	{
		iso: "TD",
		iso3: "TCD",
		name: "Chad",
		continent: "Africa",
		timezone: "1",
		capital: "NDjamena",
		currency: "XAF",
		phoneCode: "235"
	},
	{
		iso: "TG",
		iso3: "TGO",
		name: "Togo",
		continent: "Africa",
		timezone: "0",
		capital: "Lome",
		currency: "XOF",
		phoneCode: "228"
	},
	{
		iso: "TH",
		iso3: "THA",
		name: "Thailand",
		continent: "Southeast Asia",
		timezone: "7",
		capital: "Bangkok",
		currency: "THB",
		phoneCode: "66"
	},
	{
		iso: "TJ",
		iso3: "TJK",
		name: "Tajikistan",
		continent: "Asia",
		timezone: "5",
		capital: "Dushanbe",
		currency: "TJS",
		phoneCode: "992"
	},
	{
		iso: "TK",
		iso3: "TKL",
		name: "Tokelau",
		continent: "Oceania",
		timezone: "13",
		capital: "",
		currency: "NZD",
		phoneCode: "690"
	},
	{
		iso: "TL",
		iso3: "TLS",
		name: "Timor-Leste (East Timor)",
		continent: "Southeast Asia",
		timezone: "9",
		capital: "Dili",
		currency: "USD",
		phoneCode: "670"
	},
	{
		iso: "TM",
		iso3: "TKM",
		name: "Turkmenistan",
		continent: "Asia",
		timezone: "5",
		capital: "Ashgabat",
		currency: "TMM",
		phoneCode: "993"
	},
	{
		iso: "TN",
		iso3: "TUN",
		name: "Tunisia",
		continent: "Africa",
		timezone: "1",
		capital: "Tunis",
		currency: "TND",
		phoneCode: "216"
	},
	{
		iso: "TO",
		iso3: "TON",
		name: "Tonga",
		continent: "Oceania",
		timezone: "13",
		capital: "Nukualofa",
		currency: "TOP",
		phoneCode: "676"
	},
	{
		iso: "TR",
		iso3: "TUR",
		name: "Turkey",
		continent: "Middle East",
		timezone: "2",
		capital: "Ankara",
		currency: "TRY",
		phoneCode: "90"
	},
	{
		iso: "TT",
		iso3: "TTO",
		name: "Trinidad and Tobago",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Port-of-Spain",
		currency: "TTD",
		phoneCode: "1867"
	},
	{
		iso: "TV",
		iso3: "TUV",
		name: "Tuvalu",
		continent: "Oceania",
		timezone: "12",
		capital: "Funafuti",
		currency: "AUD",
		phoneCode: "688"
	},
	{
		iso: "TW",
		iso3: "TWN",
		name: "Taiwan",
		continent: "Southeast Asia",
		timezone: "8",
		capital: "Taipei",
		currency: "TWD",
		phoneCode: "886"
	},
	{
		iso: "TZ",
		iso3: "TZA",
		name: "Tanzania",
		continent: "Africa",
		timezone: "3",
		capital: "Dar es Salaam",
		currency: "TZS",
		phoneCode: "255"
	},
	{
		iso: "UA",
		iso3: "UKR",
		name: "Ukraine",
		continent: "Europe",
		timezone: "2",
		capital: "Kiev",
		currency: "UAH",
		phoneCode: "380"
	},
	{
		iso: "UG",
		iso3: "UGA",
		name: "Uganda",
		continent: "Africa",
		timezone: "3",
		capital: "Kampala",
		currency: "UGX",
		phoneCode: "256"
	},
	{
		iso: "US",
		iso3: "USA",
		name: "United States",
		continent: "North America",
		timezone: "-5",
		capital: "Washington",
		currency: "USD",
		phoneCode: "1"
	},
	{
		iso: "UY",
		iso3: "URY",
		name: "Uruguay",
		continent: "South America",
		timezone: "-3",
		capital: "Montevideo",
		currency: "UYU",
		phoneCode: "598"
	},
	{
		iso: "UZ",
		iso3: "UZB",
		name: "Uzbekistan",
		continent: "Asia",
		timezone: "5",
		capital: "Tashkent",
		currency: "UZS",
		phoneCode: "998"
	},
	{
		iso: "VA",
		iso3: "VAT",
		name: "Vatican City",
		continent: "",
		timezone: "1",
		capital: "Vatican City",
		currency: "EUR",
		phoneCode: "379"
	},
	{
		iso: "VC",
		iso3: "VCT",
		name: "Saint Vincent and the Grenadines",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Kingstown",
		currency: "XCD",
		phoneCode: "1783"
	},
	{
		iso: "VE",
		iso3: "VEN",
		name: "Venezuela",
		continent: "South America",
		timezone: "-4.5",
		capital: "Caracas",
		currency: "VEB",
		phoneCode: "58"
	},
	{
		iso: "VG",
		iso3: "VGB",
		name: "British Virgin Islands",
		continent: "Central America and the Caribbean",
		timezone: "-4",
		capital: "Road Town",
		currency: "USD",
		phoneCode: "1283"
	},
	{
		iso: "VI",
		iso3: "VIR",
		name: "U.S. Virgin Islands",
		continent: "",
		timezone: "-4",
		capital: "Charlotte Amalie",
		currency: "USD",
		phoneCode: "1339"
	},
	{
		iso: "VN",
		iso3: "VNM",
		name: "Vietnam",
		continent: "Southeast Asia",
		timezone: "7",
		capital: "Hanoi",
		currency: "VND",
		phoneCode: "84"
	},
	{
		iso: "VU",
		iso3: "VUT",
		name: "Vanuatu",
		continent: "Oceania",
		timezone: "11",
		capital: "Port-Vila",
		currency: "VUV",
		phoneCode: "678"
	},
	{
		iso: "WF",
		iso3: "WLF",
		name: "Wallis and Futuna",
		continent: "Oceania",
		timezone: "12",
		capital: "Matautu",
		currency: "XPF",
		phoneCode: "681"
	},
	{
		iso: "WS",
		iso3: "WSM",
		name: "Samoa",
		continent: "Oceania",
		timezone: "14",
		capital: "Apia",
		currency: "WST",
		phoneCode: "685"
	},
	{
		iso: "WW",
		iso3: "WWW",
		name: "World Wide",
		continent: "",
		timezone: "0",
		capital: "",
		currency: "",
		phoneCode: "0"
	},
	{
		iso: "YE",
		iso3: "YEM",
		name: "Yemen",
		continent: "Middle East",
		timezone: "3",
		capital: "Sanaa",
		currency: "YER",
		phoneCode: "967"
	},
	{
		iso: "YT",
		iso3: "MYT",
		name: "Mayotte",
		continent: "Africa",
		timezone: "3",
		capital: "Mamoudzou",
		currency: "EUR",
		phoneCode: "262"
	},
	{
		iso: "ZA",
		iso3: "ZAF",
		name: "South Africa",
		continent: "Africa",
		timezone: "2",
		capital: "Pretoria",
		currency: "ZAR",
		phoneCode: "27"
	},
	{
		iso: "ZM",
		iso3: "ZMB",
		name: "Zambia",
		continent: "Africa",
		timezone: "2",
		capital: "Lusaka",
		currency: "ZMK",
		phoneCode: "260"
	},
	{
		iso: "ZW",
		iso3: "ZWE",
		name: "Zimbabwe",
		continent: "Africa",
		timezone: "2",
		capital: "Harare",
		currency: "ZWD",
		phoneCode: "263"
	}
]