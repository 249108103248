import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Loader, SelectList, TextInput, Icon } from '../../../components/components';
import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
import * as Utils from '../../../utils/common.js';
import { rateLimitOptions } from '../../../utils/options';
import { extractRatelimitDataFromForm } from '../../../utils/formHelpers';
import { updateCampaignRateLimit } from '../../../api/actions';

const EditCampaignRateLimitSection = ({children, ...props}) => {
    const {register, handleSubmit, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });

    const formOptions = {
        rateLimitPeriod: {},
        rateLimitAmount: { 
            max: {
                value: 100000,
                message: "Amount is too high"
            }
        }
    };
    
    const handleFormData = async (data) => {

        let rateLimitData;

        if(data?.rateLimitPeriod?.value && data?.rateLimitAmount) {
            rateLimitData = {
                period: data.rateLimitPeriod.value,
                amount: data.rateLimitAmount
            }
        } else {
            rateLimitData = {
                period: null,
                amount: null,
            };
        }

        await updateCampaignRateLimit(props.campaignId, rateLimitData.period, rateLimitData.amount);
        props.handleClose('rateLimit');
    }

    const handleError = (errors) => {console.log("Errors in form", errors)};

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true)
	},[]);

    return (  
        <>
        {loaded ?
            <>
            <p>Traffic will only be sent in selected timeframes.<br/> Leave empty to receive the traffic all at once.</p>
            <form onSubmit={handleSubmit(handleFormData, handleError)}>
                <Container fluid className="m-0 p-0">
                    <Row>
                        <Col md={6}>
                            <Controller
                                name="rateLimitPeriod"
                                control={control}
                                rules={formOptions.rateLimitPeriod}
                                render={({field}) => (
                                    <SelectList 
                                        id="rateLimitPeriod"
                                        description="Choose period"
                                        label="Time period"
                                        options={rateLimitOptions.period} 
                                        clearable
                                        searchable
                                        preselected={[props?.selections?.period]}
                                        errorMessage={errors?.rateLimitPeriod && errors.rateLimitPeriod.message}
                                        isValid={!!errors?.rateLimitPeriod}
                                        {...field}
                                    />
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <Controller
                                name="rateLimitAmount"
                                control={control}
                                rules={formOptions.rateLimitAmount}
                                defaultValue={props?.selections?.amount}
                                render={({field}) => (
                                    <TextInput 
                                        id="rateLimitAmount"
                                        type="number"
                                        floatingLabel="Type an amount"
                                        label="Amount limit"
                                        min="10"
                                        required={false}
                                        description="Set your amount limit within the selected period"
                                        errorMessage={errors?.rateLimitAmount && errors.rateLimitAmount.message}
                                        {...field}

                                    />
                                )}
                            />
                        </Col>                        
                    </Row>
                </Container>
                <div className="d-block text-center">
                    <Button type="submit">Update Rate limiter</Button>
                    <p className="pt-2">
                        <span className="text-primary pointer link" onClick={() => handleFormData()}>Remove rate limit</span> | 
                        <span className="text-primary pointer link ms-1" onClick={() => props.handleReset().then(r=>{props.handleClose("rateLimit")})}>Reset rate limiter</span>
                    </p>
                </div>
            </form>
        </>
        : <Loader label="Loading rate limiter" position="center" />
        }
    </>
    )
}

export default EditCampaignRateLimitSection;