import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { Loader, SelectList, Icon, Box } from '../../../components/components.jsx';
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as Utils from '../../../utils/common.js';
import { scheduleOptions } from '../../../utils/options.js';
import { updateCampaignSchedule } from '../../../api/actions.js';

const EditCampaignScheduleSection = ({children, ...props}) => {
    const {register, handleSubmit, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });

    const formOptions = {

    };

    const handleFormData = async (data) => {
        let scheduleData = {};

        if(data?.scheduleDays?.length > 0) {
            scheduleData.days = data.scheduleDays.map(item=>(item.value));
        }

        if(data?.scheduleHourStart && data?.scheduleHourEnd) {
            scheduleData.hours = {
                start: data.scheduleHourStart.value,
                end: data.scheduleHourEnd.value
            }
        }

        if(data.campaignStartTime) scheduleData.startTime = data.campaignStartTime + " 00:00:00";
        if(data.campaignEndTime) scheduleData.endTime = data.campaignEndTime + " 23:59:59";

        if(Object.keys(scheduleData).length === 0) scheduleData = null; 

        const result = await updateCampaignSchedule(props.campaignId, scheduleData);
        props.handleClose('schedule');
    }

    const handleError = (errors) => {console.log("Errors in form", errors)};    

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
	},[]);

    return (  
        <>
        {loaded ?
            <>
            <p>Traffic will only be sent in selected timeframes.<br/> Leave empty to receive traffic 24/7.</p>
            <p>Timezone: {props.timezone}<br /><small><Link to="/account">Change time zone in profile</Link></small></p>
            <form onSubmit={handleSubmit(handleFormData, handleError)}>
            <Container fluid className="m-0 p-0">
                <Row>
                    <Col>
                        <Box>
                        <Container fluid>
                            <Row className="g-2">
                                <Col md={6}>
                                    <p className="mb-3 text-secondary">Start Date</p>
                                    <Controller
                                        name="campaignStartTime"
                                        control={control}
                                        rules={formOptions.campaignStartTime}
                                        defaultValue={props?.selections?.startTime ? props?.selections?.startTime.split(" ")[0] : null}
                                        render={({field}) => (
                                            <input 
                                                type="date"
                                                style={{border: 0}}
                                                placeholder="dd-mm-yyyy"
                                                {...field}
                                                
                                            />
                                        )}
                                    />                                       
                                </Col>
                                <Col md={6}>
                                <p className="mb-3 text-secondary">End Date</p>
                                    <Controller
                                        name="campaignEndTime"
                                        control={control}
                                        rules={formOptions.campaignEndTime}
                                        defaultValue={props?.selections?.endTime ? props?.selections?.endTime.split(" ")[0] : null}
                                        render={({field}) => (
                                            <input 
                                                type="date"
                                                style={{border: 0}}
                                                min={dayjs(watch("campaignStartTime")).format("YYYY-MM-DD")}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>    
                        </Container> 
                        </Box>                       
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Box className="my-3">
                            <Container fluid>
                                <Row className="g-2">
                                    <Col md={6}>
                                        <p className="mb-1 text-secondary">Active days</p>
                                        <Controller
                                            name="scheduleDays"
                                            control={control}
                                            rules={formOptions.scheduleDays}
                                            render={({field}) => (
                                                <SelectList 
                                                    id="scheduleDays"
                                                    options={scheduleOptions.days} 
                                                    clearable
                                                    searchable
                                                    placeholder="All days"
                                                    preselected={props?.selections?.days}
                                                    multi
                                                    {...field}
                                                /> 
                                            )}
                                        />                                        
                                    </Col>
                                    <Col md={6}>
                                        <p className="mb-1 text-secondary">Active hours</p>
                                        <Controller
                                            name="scheduleHourStart"
                                            control={control}
                                            rules={formOptions.scheduleHourStart}
                                            render={({field}) => (                   
                                                <SelectList 
                                                    id="scheduleHourStart"
                                                    options={scheduleOptions.hours.map((option=>({...option, label: `From ${option.label}`})))} 
                                                    clearable
                                                    searchable
                                                    placeholder="From hour"
                                                    preselected={[props?.selections?.hours?.start]}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="scheduleHourEnd"
                                            control={control}
                                            rules={formOptions.scheduleHourEnd}
                                            render={({field}) => ( 
                                                <SelectList 
                                                    id="scheduleHourEnd"
                                                    options={scheduleOptions.hours.map((option=>({...option, label: `To ${option.label}`})))} 
                                                    clearable
                                                    searchable
                                                    placeholder="To hour"
                                                    preselected={[props?.selections?.hours?.end]}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Box>
                    </Col>
                </Row>
            </Container>
            <div className="text-center">
                {/* <p>Set as {scheduleToText(extractScheduleDataFromForm(formData))}</p> */}
                <Button type="submit">Update Schedule</Button><br />
                <p className="text-primary pointer link pt-2" onClick={() => handleFormData({})}>Remove Schedule</p>
            </div>
            </form>
        </>
        : <Loader />}
    </>
    )
}

export default EditCampaignScheduleSection;