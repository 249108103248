import { useStateContext } from '../../contexts/ContextProvider';
import { ToastContainer } from 'react-bootstrap';
import NotificationToast from '../../components/NotificationToast/NotificationToast';

const Notifications = () => {

    const { notifications } = useStateContext();

    return (
        <div
        aria-live="polite"
        aria-atomic="true"
        // style={{ minHeight: '240px' }}
    >
        <ToastContainer position="bottom-end" className="p-2 position-fixed" style={{ zIndex: 1 }}>
            {notifications.length > 0 && notifications.map((notification, index) => <NotificationToast key={"noti"+index} data={{...notification}} />)}
        </ToastContainer>
    </div>    
    )
}

export default Notifications;