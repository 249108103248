import { useState, useEffect, forwardRef } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './Drawer.css';

const Drawer = forwardRef(({children, ...props}, ref) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
       // setShow(props.show)
    })

    return (
        <div className="drawer-class" ref={ref} >
            {props?.label &&
            <span className="link-primary" onClick={handleShow}>
                {props?.label ? props.label : "Open"}
            </span>
            }
            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{props?.headertext ? props.headertext : "no title"}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{...(props?.padding) && {padding: props.padding}}}>
                    {children}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
})

export default Drawer;