import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Col, Row, Container } from "react-bootstrap";
import { useAuth } from '../../../auth/AuthProvider';
import Sections from "../../../sections/sections";
import { getToken, applyToken } from '../../../api/actions';

const LoginPage = () => {
    const auth = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    const {token} = useStateContext();

    const [searchParams, setSearchParams] = useSearchParams();
    const [returnUrl, setReturnUrl] = useState();
    const [error, setError] = useState();

    const checkOneTimeLoginToken = async () => {
        const returnTo = location?.state?.returnUrl || searchParams.get("return_url") || "/";
 
        if(token) {
            const tokenInfo = await getToken(token);

            if(tokenInfo?.token) {
                const loginAttempt = await auth.login({entity: "user", token: tokenInfo.token});
                if(loginAttempt?.error) {
                    // setError(loginAttempt.error + " " + returnUrl + " " + token)
                    console.log(loginAttempt.error);
                } else {
                    applyToken(tokenInfo.token)
                    navigate(returnTo);
                }
            }
        }
    }

    useEffect(()=>{
        if(location?.state?.returnUrl) {
            setReturnUrl(location.state.returnUrl)
        } else {
            setReturnUrl(searchParams.get("return_url"));
        }
        checkOneTimeLoginToken();
    },[]);

    return (
        <Container fluid>
            <Row className="vh-100 d-flex justify-content-center">
                <Col md={6} lg={6} xs={12} className="d-flex" style={{alignItems: "center"}}>
                    <Sections.Auth.LoginForm returnUrl={returnUrl} error={error}/>
                </Col>
                <Col md={6} lg={6} className="d-none d-md-flex text-center" style={{alignItems: "center", background: "#f6f6f6"}}>
                    <img src="/images/tio-map.png" style={{maxWidth: "90%", maxHeight: "600px", margin: "0 auto"}} />
                </Col>   
            </Row>
        </Container>
    );
}

export default LoginPage;