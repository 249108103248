import {countriesObj} from './countries';
import dayjs from 'dayjs';

export const capitalizeString = str => typeof str === 'string' ? str.charAt(0).toUpperCase() + str.substring(1) : false;

export const getCountryByIso = (iso2) => (countriesObj.find(item=>item.iso === iso2));

export const getCountriesList = () => countriesObj.map(c=>({iso: c.iso, name: c.name}));

export const timeAgo = (date) => {
    if(!date) {
        return "";
    }
    if (typeof date !== 'object') {
        date = new Date(date);
    }

    var seconds = Math.floor((new Date() - date) / 1000);
    var intervalType;

    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        intervalType = 'year';
    } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            intervalType = 'month';
        } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                intervalType = 'day';
            } else {
                interval = Math.floor(seconds / 3600);
                if (interval >= 1) {
                    intervalType = "hour";
                } else {
                    interval = Math.floor(seconds / 60);
                    if (interval >= 1) {
                        intervalType = "minute";
                    } else {
                        interval = "Seconds";
                        intervalType = "";
                    }
                }
            }
        }
    }

    if (interval > 1 || interval === 0) {
        intervalType += 's';
    }

    return interval + ' ' + intervalType;
};

export const blurApp = () => document.getElementById('root').classList.add("make-blur-anim");
export const unblurApp = () => document.getElementById('root').classList.remove("make-blur-anim");
export const desaturateApp = () => document.getElementById('root').classList.add("desaturate");
export const saturateApp = () => document.getElementById('root').classList.remove("desaturate");

export const ddmmyyyy = (dateObject) => {
    var y = dateObject.getFullYear().toString();
    var m = (dateObject.getMonth() + 1).toString();
    var d = dateObject.getDate().toString();
    (d.length === 1) && (d = '0' + d);
    (m.length === 1) && (m = '0' + m);
    return d +"." + m + "." + y;
}

export const decimal = (value, precision = 2) => Number(Number(value).toFixed(precision));

export const formatNumber = (value, precision = 4) => (value ? value.toLocaleString('en-US',{maximumFractionDigits:precision}) : 0)

export const toCamelCase = (e) => e.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
  
export const toSnakeCase = (e) => e.replace(/[A-Z]/g, (letter, index) => (index === 0 ? letter.toLowerCase() : '_'+ letter.toLowerCase()));

export const toSentenceCase = (e) =>
    e.replace (/^[-_]*(.)/, (_, c) => c.toUpperCase())
     .replace (/[-_]+(.)/g, (_, c) => ' ' + c);

export const objToSnakeCase = (obj) => {
    const newObj = {};
    for(const prop in obj) {
        newObj[toSnakeCase(prop)] = obj[prop];
    }
    return newObj;
}

export const objToCamelCase = (obj) => {
    const newObj = {};
    for(const prop in obj) {
        newObj[toCamelCase(prop)] = obj[prop];
    }
    return newObj;
}

export const objectToURI = (obj) => {
    return Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
}

export const dateTimeString = (dateObject) => {
    const now = dateObject || new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const offsetHours = String(Math.abs(Math.floor(now.getTimezoneOffset() / 60))).padStart(2, '0');
    const offsetMinutes = String(Math.abs(now.getTimezoneOffset() % 60)).padStart(2, '0');
    const offsetSign = now.getTimezoneOffset() > 0 ? '-' : '+';
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
}

export const isValidUrl = (url) => {
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
        url = 'https://' + url;
    }

    try {
        const urlObj = new URL(url);
        return url;
    } catch (error) {
        console.log(error);
    }

    return false;
}
  
export const localizeDateTime = (dt) => new Date(dt + "Z").toLocaleString('en-US',{hour12:false})

export const objectsArrayToDataTable = (objects) => {
    
    let result;

    try {
        result = {
            columns: Object.keys(objects[0]), 
            rows: objects.map(obj=>Object.values(obj))
        }
    } catch (error) {
        result = {}
    }

    return result;
}

export const getDateRange = (timeframe) => {
    const today = dayjs();

    let startDate;
    let endDate;

    timeframe = timeframe.toLowerCase();

    switch (timeframe) {
        case 'today':
            startDate = today.startOf('day');
            endDate = today.endOf('day');
            break;
        case 'yesterday':
            startDate = today.subtract(1, 'day').startOf('day');
            endDate = startDate.endOf('day');
            break;
        case 'this week':
            startDate = today.startOf('week');
            endDate = today.endOf('day');
            break;
        case 'last week':
            startDate = today.subtract(1, 'week').startOf('week');
            endDate = today.subtract(1, 'week').endOf('week');
            break;
        case 'this month':
            startDate = today.startOf('month');
            endDate = today.endOf('day');
            break;
        case 'last month':
            startDate = today.subtract(1, 'month').startOf('month');
            endDate = today.subtract(1, 'month').endOf('month');
            break;
        case 'this year':
            startDate = today.startOf('year');
            endDate = today.endOf('day');
            break;
        case 'last year':
            startDate = today.subtract(1, 'year').startOf('year');
            endDate = today.subtract(1, 'year').endOf('year');
            break;
        case 'last 7 days':
            startDate = today.subtract(7, 'day').startOf('day');
            endDate = today.endOf('day');
            break;
        default:
            timeframe = "All time";
            startDate = today.subtract(20, 'year').startOf('year');
            endDate = today.endOf('day');   
    }

    return { 
        label: timeframe,
        from: startDate.toDate(), 
        to: endDate.toDate() 
    };
};
    
export const timer = ms => new Promise(res => setTimeout(res, ms));

export const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(param);
};

export const removeQueryParam = (param) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    params.delete(param);

    url.search = params.toString();
    window.history.replaceState({}, '', url);
};