import { Outlet, Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { useStateContext } from '../contexts/ContextProvider';

const ProtectedRoute = () => {

    const { isAuthorized } = useAuth();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const { token } = useStateContext();

    return(
        isAuthorized ? <Outlet/> : <Navigate to="/login" state={{returnUrl: location.pathname, token}} />
    )
}

export default ProtectedRoute;