import api from './api';
import * as Utils from '../utils/common.js';

export const getUserProfile = async () => {
    try {
        const result = await api.get('user/profile');
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }
}

export const updateUserProfile = async (data) => {
    try {
        const result = await api.post('user/profile', data);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }
}

export const getToken = async (token) => {
    try {
        const result = await api.post('tokens/get', {token});
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const applyToken = async (token) => {
    try {
        const result = await api.post('tokens/use', {token});
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const forgotPassword = async (data) => {
    try {
        const result = await api.post('forgot-password', data);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }
}

export const requestOneTimeLoginLink = async (data) => {
    try {
        const result = await api.post('one-time-login-link', data);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }
}

export const submitPasswordUpdate = async (data) => {
    try {
        const result = await api.post('user/updatePassword', data);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }
}

export const submitPasswordUpdateWithToken = async (data) => {
    try {
        const result = await api.post('update-password-with-token', data);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }
}

export const updateCampaignDailyBudget = async (campaignId, budget) => {
    try {
        const result = await api.post('/campaign/'+campaignId+'/updateDailyBudget', {dailyBudget: budget});
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }
}

export const updateCampaignBid = async (campaignId, bid, bidType) => {
    try {
        const result = await api.post('/campaign/'+campaignId+'/updateBid', {bid, bidType});
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }
}

export const getUserPayments = async () => {
    try {
        const result = await api.get('user/payments');
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }
}


export const getCampaigns = async (advertiserId, options = {}) => {
    try {
        const result = await api.get('advertiser/'+advertiserId+'/campaigns', {params:options});
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }
}

export const getCampaignById = async (id) => {
    try {
        const result = await api.get('campaign/'+id);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }
}

export const getCampaignChart = async (id, options) => {
    try {
        const result = await api.get(`campaign/${id}/chartData?${Utils.objectToURI(options)}`);
        return result.data;
    } catch (error) {
        console.log(error);
        if(error) return error;
        return error;
    }
}

export const updateCampaignSchedule = async (id, schedule) => {
    try {
        const result = await api.post(`campaign/${id}/updateSchedule`, {schedule});
        return result.data;
    } catch (error) {
        console.log(error);
        if(error) return error;
        return error;
    }
}

export const updateCampaignRateLimit = async (id, period, amount) => {
    try {
        const result = await api.post(`campaign/${id}/updateRateLimit`, {period, amount});
        return result.data;
    } catch (error) {
        console.log(error);
        if(error) return error;
        return error;
    }
}
export const resetCampaignRateLimit = async (id) => {
    try {
        const result = await api.post(`campaign/${id}/resetRateLimit`);
        return result.data;
    } catch (error) {
        console.log(error);
        if(error) return error;
        return error;
    }
}

export const getCampaignStats = async (id, options) => {
    try {
        const result = await api.get(`campaign/${id}/statsData?${Utils.objectToURI(options)}`);
        return result.data;
    } catch (error) {
        console.log(error);
        if(error) return error;
        return error;
    }
}

export const getCampaignsChartByAdvertiserId = async (advertiserId, options) => {
    try {
        const result = await api.get(`advertiser/${advertiserId}/campaigns/chartData?${Utils.objectToURI(options)}`);
        return result.data;
    } catch (error) {
        console.log(error);
        if(error) return error;
        return error;
    }
}

export const getCampaignsStatsByAdvertiserId = async (advertiserId, options) => {
    try {
        const result = await api.get(`advertiser/${advertiserId}/campaigns/statsData?${Utils.objectToURI(options)}`);
        return result.data;
    } catch (error) {
        console.log(error);
        if(error) return error;
        return error;
    }
}

export const generatePaymentLink = async (data) => {
    try {
        const result = await api.post('payments/generatePaymentLink', data);
        return result.data;
    } catch (error) {
        console.log(error)
        if(error) return error;
        return error;
    }
}

export const newPaymentRequest = async (data) => {
    try {
        const result = await api.post('payments/new-request', data);
        return result.data;
    } catch (error) {
        console.log(error)
        if(error) return error;
        return error;
    }
}

export const getActiveAdFormats = async () => {
    try {
        const result = await api.get(`formats/activeAdFormats`);
        return result.data;
    } catch (error) {
        return error;
    }
}

export const getAdFormat = async (name) => {
    try {
        const result = await api.get(`formats/${name}`);
        return result.data;
    } catch (error) {
        return error;
    }    
}

export const getTargetingOptionsByFormat = async (format, category) => {
    try {
        const result = await api.get(`targeting/options/${format}`, {params: category});
        return result.data;
    } catch (error) {
        return error;
    }
}

export const getTargetingOptionsByFormatAndCategory = async (format) => {
    try {
        const result = await api.get(`targeting/options/${format}`);
        return result.data;
    } catch (error) {
        return error;
    }
}

export const updateCampaignTargeting = async (campaignId, targetingData) => {
    try {
        const result = await api.post(`campaign/${campaignId}/updateTargeting`, targetingData);
        return result.data;
    } catch (error) {
        return error;
    }
}

export const createNewCampaign = async (data) => {
    try {
        const result = await api.post('campaign/create', data);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const editCampaign = async (data) => {
    try {
        const result = await api.put('campaign/edit', data);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const enableCampaign = async (campaignId) => {
    try {
        const result = await api.post(`/campaign/${campaignId}/enable`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const archiveCampaign = async (campaignId) => {
    try {
        const result = await api.post(`/campaign/${campaignId}/archive`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const cloneCampaign = async (campaignId) => {
    try {
        const result = await api.post(`/campaign/${campaignId}/clone`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const disableCampaign = async (campaignId) => {
    try {
        const result = await api.post(`/campaign/${campaignId}/disable`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const resetCampaignDailyBudget = async (campaignId) => {
    try {
        const result = await api.post(`/campaign/${campaignId}/resetDailyBudget`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const getAd = async (adId) => {
    try {
        const result = await api.get(`/advertiser/ad/${adId}`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const getCampaignUpdates = async (campaignId) => {
    try {
        const result = await api.get(`/campaign/${campaignId}/updates`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const enableAd = async (adId) => {
    try {
        const result = await api.post(`/advertiser/ad/${adId}/enable`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const disableAd = async (adId) => {
    try {
        const result = await api.post(`/advertiser/ad/${adId}/disable`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const archiveAd = async (adId) => {
    try {
        const result = await api.post(`/advertiser/ad/${adId}/archive`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const editAd = async (adId, data) => {
    try {
        const result = await api.put(`/advertiser/ad/${adId}/edit`, data);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }     
}

export const addUrlsToCampaign = async (campaignId, urls) => {
    try {
        const result = await api.post(`/campaign/${campaignId}/addUrls`, urls);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const submitContactUsForm = async (data) => {
    try {
        const result = await api.post(`/support/newMessage`, data);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const addPageView = async (data) => {
    try {
        const result = await api.post(`/analytics/add/page-view`, data);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const getUserReferralsSummary = async (data) => {
    try {
        const result = await api.get(`/user/referrals/summary`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const getUserReferralsBalanceHistory = async (data) => {
    try {
        const result = await api.get(`/user/referrals/balanceHistory`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const getUserDashboardData = async (data) => {
    try {
        const result = await api.get(`/user/getDashboardData`);
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }    
}

export const unsubscribeUser = async (email) => {
    try {
        const result = await api.post(`/user/unsubscribe`, {email});
        return result.data;
    } catch (error) {
        if(error) return error;
        return error;
    }  
}