import CampaignsTable from './Campaigns/CampaignsTable/CampaignsTable';
import ArchivedCampaignsTable from './Campaigns/ArchivedCampaignsTable/ArchivedCampaignsTable';
import EditCampaignTargetingSection from './Campaigns/EditCampaignTargetingSection/EditCampaignTargetingSection';
import EditCampaignScheduleSection from './Campaigns/EditCampaignScheduleSection/EditCampaignScheduleSection';
import EditCampaignRateLimiterSection from './Campaigns/EditCampaignRateLimiterSection/EditCampaignRateLimiterSection';
import AddMultipleUrlsSection from './Campaigns/AddMultipleUrlsSection/AddMultipleUrlsSection';
import AddUrlSection from './Campaigns/AddMultipleUrlsSection/AddUrlSection';
import EditAdSection from './Campaigns/EditAdSection/EditAdSection';

import CampaignAdsStatsTable from './Campaigns/CampaignDataTabs/CampaignAdsStatsTable';
import CampaignTargetingStatsTable from './Campaigns/CampaignDataTabs/CampaignTargetingStatsTable';

import RegisterForm from './Auth/RegisterForm/RegisterForm';
import LoginForm from './Auth/LoginForm/LoginForm';
import ResetPasswordForm from './Auth/ResetPasswordForm/ResetPasswordForm';
import CampaignUpdatesSection from './Campaigns/CampaignUpdatesSection/CampaignUpdatesSection';
import EditStatusAfterApprovalSection from './Campaigns/EditStatusAfterApprovalSection/EditStatusAfterApprovalSection';

const sections = {
    Campaigns: {
        CampaignsTable,
        ArchivedCampaignsTable,
        CampaignTargetingStatsTable,
        CampaignAdsStatsTable,
        EditCampaignTargetingSection,
        EditCampaignScheduleSection,
        EditCampaignRateLimiterSection,
        EditStatusAfterApprovalSection,
        AddMultipleUrlsSection,
        AddUrlSection,
        EditAdSection,
        CampaignUpdatesSection
    },
    Auth: {
        RegisterForm,
        LoginForm,
        ResetPasswordForm,
    }
}

export default sections;