import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Loader, SelectList, TextInput, Icon } from '../../../components/components';
import { Container, Row, Col, Stack, Button, Form } from 'react-bootstrap';
import { useStateContext } from '../../../contexts/ContextProvider.js';
import * as Utils from '../../../utils/common.js';
import { addUrlsToCampaign } from '../../../api/actions';

import validator from 'validator';
import axios from 'axios';

const AddUrlSection = ({children, ...props}) => {
    const {register, handleSubmit, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });

    const [loaded, setLoaded] = useState(false);
    const [step, setStep] = useState(1);
    const [text, setText] = useState("");
    const [urls, setUrls] = useState([]);

    const { addNotification } = useStateContext();

    const formOptions = {
        url: {
            required: "URL is required",
            validate: (v)=>(validator.isURL(v, {require_protocol: true, protocols: ['https','http']}) || "A valid URL must start with https:// and lead to a valid destination"),
        },
    };

    const handleText = (e) => {
        setText(e.value);
    }
    
    const handleFormData = (data) => {
        // let rawUrls = data.urlsText.trim();
        setText(data.url);

        let rawUrls = data.url
            .trim()
            .split("\n") // Break lines
            .map((line)=>(line.replace(/(\r\n|\n|\r|\t)/gm,""))) // Remove invalid characters from each line
            .filter((url)=>(validator.isURL(url)))


        if(rawUrls.length > 0) {
            setUrls(rawUrls);
            setLoaded(false);
            setStep(2);
            setLoaded(true);
        }
    }

    const handleSubmitVerifiedUrls = async () => {
        const result = await addUrlsToCampaign(props.campaignId, urls);

        if(result[0]?.result?.id) addNotification("Added URL");
        else if (result[0]?.result?.error) addNotification(result[0]?.result?.error)

        props.handleClose("addUrl")
    }

    const verifyUrls = async () => {

        const urlsCopy = [...urls];

        const promises = urlsCopy.map(async (url)=>{
            try {
                const checkUrl = await axios.get(url.url);
                return {...url, data: checkUrl}
            } catch (error) {
                return {...url, error: error.message, code: error.code, filtered: Object.keys(error).filter(key=>(key!=="stack")).map(filteredKey=>(error[filteredKey]))}
            }
        })

        const result = await Promise.all(promises);
        return result;

    }

    const handleError = (errors) => {console.log("Errors in form", errors)};

    useEffect(()=>{
        if(step === 2) {
            verifyUrls();
        }
    }, [step])

    useEffect(() => {
        setLoaded(true)
	},[]);

    return (  
        <>
        {loaded ?
            <>
            {step === 1 &&
                <form onSubmit={handleSubmit(handleFormData, handleError)}>
                    <Container fluid className="m-0 p-0">
                        <Row>
                            <Col md={12}>
                                <Controller
                                    name="url"
                                    control={control}
                                    rules={formOptions.url}
                                    render={({field}) => (                               
                                        <TextInput 
                                            id="url"
                                            floatingLabel={<><Icon iconName="Link" size="28px" color="#c4c4c4" /> Destination URL</>}
                                            description={<p>Web address of destination website or landing page.<br /> e.g. https://my-website.com</p>}
                                            errorMessage={errors?.url?.message}
                                            {...field}
                                        />
                                        )}
                                    />
                            </Col>
                        </Row>
                    </Container>
                    <Button type="submit">Submit new URL</Button>
                </form>
            }
            {step === 2 &&
                <div>
                    <p>Double check your URL before submission.</p>
                    {urls && urls.map((url, index)=>(
                        <p style={{color: "#349334", wordBreak: "break-word"}}>{url}</p>
                    ))}                           
                    <p>URL will be added to rotation and will receive a proportionate share of the traffic.</p>
                    <Container>
                        <Row className="g-3">
                            <Col style={{flexGrow: 0, whiteSpace: "nowrap"}}>
                                <Button className="text-primary" variant="outlined" onClick={()=>setStep(1)}><Icon iconName="ArrowLeft" size="24" /> Edit URL</Button>
                            </Col>
                            <Col>
                                <Button type="submit" onClick={handleSubmitVerifiedUrls}>Add URL</Button>
                            </Col>
                        </Row>
                        
                    </Container>

                </div>
            }
        </>
        : <Loader />}
    </>
    )
}

export default AddUrlSection;