const getAllPreferences = () => {
    let preferences = localStorage.getItem('preferences') || "{}";
    preferences = JSON.parse(preferences);   
    return preferences; 
}

const setPagePreferences = (obj) => {
    let preferences = getAllPreferences();
    preferences = {...preferences, [window.location.pathname] : {...preferences[window.location.pathname], ...obj}}
    localStorage.setItem('preferences', JSON.stringify(preferences));
    // console.log("saved to localStorage ", window.location.pathname, obj)
}

const getPagePreferences = () => {
    let preferences = getAllPreferences();
    let pagePreferences = preferences[window.location.pathname] || {};

    return pagePreferences;
}

const setGeneralPreferences = (component, obj) => {
    let preferences = getAllPreferences();
    preferences = {...preferences, [component] : {...preferences[component], ...obj}}
    localStorage.setItem('preferences', JSON.stringify(preferences));
    // console.log("saved to localStorage ", window.location.pathname, obj)
}

const getGeneralPreferences = (component) => {
    let preferences = getAllPreferences();
    let pagePreferences = preferences[component] || {};

    return pagePreferences;
}

export default {
    setPagePreferences,
    getPagePreferences,
    setGeneralPreferences,
    getGeneralPreferences
}