export const getPageHistory = (module) => {
    const savedPageHistory = localStorage.getItem(module + "-history");
    const pageHistory = JSON.parse(savedPageHistory);
    return pageHistory;
}

export const clearPageHistory = (module) => {
    try {
        localStorage.removeItem(module + "-history");
    } catch (error) {
        console.log(error);
    }
    
}

export const addPageHistory = (module, page) => {
    const savedPageHistory = localStorage.getItem(module + "-history");
    let pageHistory = JSON.parse(savedPageHistory);
    if(!pageHistory) pageHistory = [];
    
    pageHistory = pageHistory.filter((v,i)=>(v.url !== page.url));
    pageHistory.unshift({...page, time: Date.now()});

    if(pageHistory.length > 5) {
        pageHistory = pageHistory.slice(0,5);
    }

    const save = localStorage.setItem(module + "-history", JSON.stringify(pageHistory));
}