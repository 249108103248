import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Loader, SelectList, Icon, ExpandableBox } from '../../../components/components';
import { Container, Row, Col, Stack, Badge, ListGroup, Button } from 'react-bootstrap';
import * as Utils from '../../../utils/common.js';
import { extractTargetingDataFromForm } from '../../../utils/formHelpers';
import { getTargetingOptionsByFormat, editCampaign, updateCampaignTargeting } from '../../../api/actions.js';

const EditCampaignTargetingSection = ({children, ...props}) => {
    const {register, handleSubmit, watch, formState: { errors }, control} = useForm({
        mode: "onBlur"
    });

    const formOptions = {

    };

    const handleFormData = async (data) => {

        const targetingData = extractTargetingDataFromForm(data);

        targetingData.category = props.targetingCategory;

        await updateCampaignTargeting(props.campaignId, targetingData);

        props.handleClose("targeting")
    }

    const handleError = (errors) => {console.log("Errors in form", errors)};

    const [targetingOptions, setTargetingOptions] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Please fill out the form correctly.");
    const [loaded, setLoaded] = useState(false);

    const getDataForPage = async () => {
        try {
            const targetingOptionsByFormat = await getTargetingOptionsByFormat(props.format);
            setTargetingOptions(targetingOptionsByFormat);
        } catch (error) {
            console.log(error);
        }
        setLoaded(true);
    }

    useEffect(() => {
        getDataForPage();
	},[]);

    return (  
        <>
        {loaded ?
            <>
            <p>Leave unselected if you do not wish to filter by certain parameters.</p>
            <form onSubmit={handleSubmit(handleFormData, handleError)}>
            {targetingOptions.vertical &&  
            <ExpandableBox 
                className="mb-2"
                header={
                <>
                    <Icon iconName="Bullseye" size="28px" color="#c4c4c4" /> <b className="mx-2">Vertical</b> 
                    <div>
                        {watch("targetingVertical")?.length ? <Badge className="me-1">Selected {watch("targetingVertical")?.length}</Badge> : ""} 
                        {watch("targetingVertical")?.value ? <Badge className="me-1">{watch("targetingVertical")?.label}</Badge> : ""} 
                    </div>
                </>
                }
                >
                <Controller
                    name="targetingVertical"
                    control={control}
                    rules={formOptions.targetingVertical}
                    render={({field}) => (
                        <SelectList 
                            id="targetingVertical"
                            options={targetingOptions?.vertical ? targetingOptions.vertical.filter(v=>(!v?.parentCategory || v.parentCategory === props.targetingCategory[0])) : []} 
                            description="Type of content your audience is interested in"
                            placeholder="All verticals"
                            clearable
                            searchable
                            // multi
                            preselected={props?.selections?.vertical}
                            {...field}
                        />
                    )}
                />
            </ExpandableBox>
            }
            {targetingOptions.country &&    
            <ExpandableBox 
                className="mb-2"
                header={
                <>
                    <Icon iconName="Bullseye" size="28px" color="#c4c4c4" /> <b className="mx-2">Countries</b> 
                    <div>
                        {watch("targetingCountry")?.length ? <Badge className="me-1">Selected {watch("targetingCountry")?.length}</Badge> : ""} 
                    </div>
                </>
                }
                >
                <Controller
                    name="targetingCountry"
                    control={control}
                    rules={formOptions.targetingCountry}
                    render={({field}) => (
                        <SelectList 
                            id="targetingCountry"
                            options={targetingOptions?.country ? targetingOptions.country : []} 
                            description="Countries that you wish to receive traffic from."
                            placeholder="All countries"
                            clearable
                            searchable
                            multi
                            preselected={props?.selections?.country}
                            {...field}
                        />
                    )} 
                />      
            </ExpandableBox>
            }
            {targetingOptions.device &&  
            <ExpandableBox 
                className="mb-2"
                header={
                <>
                    <Icon iconName="Bullseye" size="28px" color="#c4c4c4" /> <b className="mx-2">Devices</b> 
                    <div>
                        {watch("targetingDevice")?.length ? <Badge className="me-1">Selected {watch("targetingDevice")?.length}</Badge> : ""} 
                    </div>
                </>
                }
                >
                <Controller
                    name="targetingDevice"
                    control={control}
                    rules={formOptions.targetingDevice}
                    render={({field}) => (                    
                        <SelectList 
                            id="targetingDevice"
                            options={targetingOptions?.device ? targetingOptions.device : []}
                            placeholder="All devices"
                            description="Type of device used by your audience"
                            clearable
                            searchable
                            multi
                            preselected={props?.selections?.device}
                            {...field}
                        />
                    )}
                />
            </ExpandableBox>
            }
            <p className="pt-2">Some changes in targeting may require additional campaign moderatation.</p>
            <Button type="submit">Update Targeting</Button>
            </form>
        </>
        : <Loader label="Loading targeting options" />}
    </>
    )
}

export default EditCampaignTargetingSection;