import { useEffect, useState } from 'react';
import './Value.css'; // Import the CSS file for styles

const Value = ({ type, symbol, value, decimals, ...props }) => {

    const [displayValue, setDisplayValue] = useState(value);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (value !== undefined) {
            setIsLoading(false);
            setDisplayValue(value);
        }
    }, [value]);

    const formatMonetary = (value) => {
        const numberValue = parseFloat(value);
        const formattedValue = numberValue.toFixed(decimals);
        return formattedValue.includes('.') && Number(formattedValue.split('.')[1]) !== 0 
          ? `${symbol}${formatNumber(formattedValue)}` 
          : `${symbol}${formatNumber(Math.round(numberValue))}`; // Show rounded value without decimals
    };

    const formatNumber = (value) => {
        const numberValue = parseFloat(value);
        return numberValue.toLocaleString();
    };

    const getFormattedValue = () => {
        if (isLoading) {
            return <span className="value-placeholder">Loading...</span>;
        }

        switch (type) {
            case 'monetary':
            return (
                <div className="value-container">
                    <span className="value-big-font">{formatMonetary(displayValue).split('.')[0]}</span>
                    {formatMonetary(displayValue).includes(".") && <span className="value-small-font">.{formatMonetary(displayValue).split('.')[1]}</span>}
                </div>
            );
            case 'number':
            return (
                <div className="value-container">
                <span className="value-big-font">{formatNumber(displayValue)}</span>
                </div>
            );
            case 'text':
            return <div className="value-container">{displayValue}</div>;
            default:
            return null;
        }
    };

    return (
        <div className={`value-component ${isLoading ? 'value-loading' : 'value-loaded'} ${props.className ? props.className : ""}`}>
            {getFormattedValue()}
        </div>
    );
};

export default Value;