import './StatWidget.css';
import { Stack } from 'react-bootstrap';

const StatWidget = ({children, ...props}) => {
    return (
        <div className={`stat-widget disable-text-selection ${props.highlighted ? "stat-widget-highlighted" : ""}`} onClick={props.onClick}>
            <Stack>
                <span className="stat-widget-title">{props.title} {props?.link ? <span className="stat-widget-link">{props.link}</span> : ""}</span>
                <p className="stat-value" style={{color: "#ff6c00"}}>{props.value || ""} <span style={{color: "#272727", fontSize: "smaller"}}>{props.valueText}</span></p>
                {props.text ? <p className="text-muted small mb-0">{props.text || ""}</p> : ""}
                {children}
            </Stack>
        </div>
    )
}

export default StatWidget;